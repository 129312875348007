import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions_PreviewMode from '../../../../../actions/mode_preview';
import {pickTextColorBasedOnBgColorAdvanced} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Background from '../general/block_background';
import Block_Button from '../general/block_button';
import Block_Settings from '../general/block_settings';

class Block_Table extends React.Component {

    constructor(props) {
        super(props);   
    }

    componentDidMount(){

       
    }

    renderRows(rows){

        return rows.map((row, index) =>
  
            <div className="block-table-row" key={index}>

                  {row.map((item, index2)=> 
                  
                    <div key={index2}>{item}</div>
                  
                  )}
  
            </div>
        )
  
    }

    render(){


        var {currentblock} = this.props;
        var {title, description, title_html, description_html, layout, bg_color, bg_image, table_columns, table_rows} = currentblock;
        
        var columns_array = table_columns ? table_columns : [];
        var rows_array = table_rows ? table_rows : [];

        var columns_items = columns_array ? columns_array.map((item,index) => <div key={index}>{item}</div>) : []
        var rows_items = rows_array ? this.renderRows(rows_array) : [];       
       
        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');

        var block_btn = currentblock.block_btn || {}; 
        var block_class = Block_Settings(currentblock);

        return(

            <div className={block_class} style={{background: bg_color, color: text_color}}>
               

                <div className="gallery-title-wrapper">
                  <div className="gallery-title" style={{paddingTop: '0', maxWidth: '1100px'}}>
                        <Block_Textbox type="block_title" content={title} content_html={title_html} />  
                        <Block_Textbox type="block_description" content={description} content_html={description_html} />
                        <Block_Button type='block' block_btn={block_btn} /> 
                    </div>
                </div>

               <div className={"design-block-table cols-4 "+layout}>

                    {columns_items.length >0 &&

                          <>
                            
                            
                            <div className="block-table-row thead">
                               
                               {columns_items}

                            </div>

                            {rows_items}

                           
                          </>
                
                    }

                    {columns_items.length == 0 && (
                        <div className="custom-html-placeholder">
                            <h2>Your Table is empty</h2>
                        </div>
                    )}


                </div>  

                {bg_image && <Block_Background bg_image={bg_image} />}
                
            </div>

        )
    }
};


const mapStateToProps = ({ mode_preview }) => {

    return {
        basic_info: mode_preview.basic_info,
        style: mode_preview.style,
        navigation: mode_preview.navigation,
        socialmedia: mode_preview.socialmedia,
        contact: mode_preview.contact,
        blocks: mode_preview.blocks
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        ...Actions_PreviewMode
  
    }, dispatch)
  }
export default connect(mapStateToProps, mapDispatchToProps)(Block_Table)

