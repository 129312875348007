import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {pickTextColorBasedOnBgColorAdvanced} from '../../../../helpers/GeneralHelper';
import { isMobile, isTablet } from 'react-device-detect';

class Footer extends React.Component {

    constructor(props) {
        super(props);
    
    }
    
    render(){

     
        var {navigation, socialmedia, footer, contact, isPro, web_id, web_lang} = this.props;
 
        //Footer Styling
        var footer_text = footer.footer_text || '';
        var footer_text_color = footer.footer_text_color || '#000000';
        var footer_background_color = footer.footer_color || '#ffffff';

        var footer_sitemap = footer.footer_sitemap || false;
        var footer_contact = footer.footer_contact || false;
        var footer_socialmedia = footer.footer_socialmedia || false;

        var footer_styles = {
            background: footer_background_color,
            color: footer_text_color
        }

        //Contact Info
        var contact_item = contact || {};
        var contact_email = contact_item.email || '';
        var contact_phone = contact_item.phone || '';
        var contact_location = contact_item.location || '';
        
        //Social Media
        var socialmedia_array = socialmedia ? getSocialMediaArray(socialmedia) : [];
        var socialmedia_items = socialmedia_array ? socialmedia_array.map((d,index) => {
            return (
                <a href={d.url} key={index} target='__blank'>
                    <i className={"socicon socicon-"+d.type}></i>
                </a>
            )
        }) : [];

        //Navigation
        var navigation_filtred = navigation.filter((d) => d.active !== 'false') || [];
        var navigation_items = navigation_filtred ? navigation_filtred.map((d,index) => {
            return (
                <NavbarItem payload={d} index={index} key={index} />
            )
        }) : []; 
 
        
        //Legal
        var footer_terms = footer.footer_terms || false;
        var footer_privacy = footer.footer_privacy || false;
        var footer_cookies = footer.footer_cookies || false;
        var footer_legal = (footer_terms || footer_privacy || footer_cookies);
        var legal_redirect_url = '/dashboard/websites/'+web_id+'/settings/legal';


        //Promo
        var clicklogo_color = pickTextColorBasedOnBgColorAdvanced(footer_background_color, '#FFFFFF', '#000000');
        var clicklogo_image = clicklogo_color === '#FFFFFF' ? '/assets/img/footer/click_white.png' : '/assets/img/footer/click_black.png';
        
        return (
    
                <>
                    <footer className="site-footer" style={footer_styles}>
                        
                        <Link to="/" className="site-footer-section copyright-footer-section">
                            {footer_text}
                        </Link>
                       
                        
                            
                            {footer_sitemap && (
                                 <div className="site-footer-section site-map-footer-section">
                                    <h4>Site map</h4>
                                    {navigation_items}
                                </div>
                            )}
                            
                            {footer_contact &&
                                <div className="site-footer-section contact-footer-section">
                                    <h4>Contact information</h4>
                                    <span>{contact_email}</span>
                                    <span>{contact_phone}</span>
                                    <span>{contact_location}</span>
                                </div>
                            }

                    

                        {footer_socialmedia && (
                            <div className="site-footer-section social-media-footer-section">
                                <h4>Social media</h4>
                                {socialmedia_items}
                            </div>
                        )} 

                        {footer_legal && (
                            <div className="site-footer-legal">
                                {footer_terms   && <a onClick={()=>this.props.HandleBodyLegal('terms')}>{getTranslatedFooter('terms', web_lang)}</a>}
                                {footer_privacy && <a onClick={()=>this.props.HandleBodyLegal('privacy')}>{getTranslatedFooter('privacy', web_lang)}</a>}
                                {footer_cookies && <a onClick={()=>this.props.HandleBodyLegal('cookies')}>{getTranslatedFooter('cookies', web_lang)}</a>}
                            </div>
                        )}
                        
                    </footer>

                    {/* <SubFooter isPro={isPro} footer_styles={footer_styles} clicklogo_image={clicklogo_image} /> */}
                
                </>
        )
        
    }

}

const SubFooter = ({isPro, footer_styles, clicklogo_image }) =>{

    if(isMobile || isTablet){

        return (
            <>
                {isPro ? (
                    <div className="click-ai-footer" style={{...footer_styles, flexDirection: 'column', justifyContent: 'flex-start', height:'70px', border: 'none'}}>
                        <span className="click-creation" style={{fontSize: '10px', display: 'flex', alignItems: 'center'}}>
                            Powered by <a href="https://www.clickai.co">
                                        <img src={clicklogo_image} style={{height: '22px'}} />
                                    </a>
                        </span>
                    </div>

                ) : (

                    <div className="click-ai-footer" style={{...footer_styles, flexDirection: 'column-reverse', height: '140px'}}>
                        <div className="left">
                            <span style={{display: 'flex', alignItems: 'center', margin: '10px'}}>Powered By  
                                <a href="https://www.clickai.co" target="_blank">
                                    <img src={clicklogo_image} style={{height: '29px'}} />
                                </a>
                            </span>
                        </div>
                        <div className="right">
                            <a className="publish-btn" href="https://www.clickai.co">Build your free website now</a>
                        </div>
                    </div>

                )}

            </>
        )

    }
    else{

        return (

            <>
                {isPro ? (
                    <div className="click-ai-footer" style={{...footer_styles, flexDirection: 'column', justifyContent: 'flex-start', height:'70px', border: 'none'}}>
                        <span className="click-creation" style={{fontSize: '10px', display: 'flex', alignItems: 'center'}}>
                            Powered by <a href="https://www.clickai.co">
                                        <img src={clicklogo_image} style={{height: '22px'}} />
                                    </a>
                        </span>
                    </div>

                ) : (

                    <div className="click-ai-footer" style={{...footer_styles, justifyContent: 'space-between'}}>
                        <div className="left">
                            <span style={{display: 'flex', alignItems: 'center'}}>Powered By  
                                <a href="https://www.clickai.co" target="_blank">
                                    <img src={clicklogo_image} style={{height: '29px'}} />
                                </a>
                            </span>
                        </div>
                        <div className="right">
                            <a className="publish-btn" href="https://www.clickai.co">Build your free website now</a>
                        </div>
                    </div>

                )}

            </>
        )
    }
}

const NavbarItem = ({payload, index}) =>{

    var {active, link, title} = payload;        

    //Check if Link is External

    if(!active){
        return (
            <a href={link} target='__blank' key={index}>
                <strong>{title}</strong>
            </a> 
        )
    }
   
    //Generate URI
    var final_uri = link;
   
    return (

        <Link to={final_uri}> 
            <strong>{title}</strong>
        </Link>
        
    )
}


const getSocialMediaArray = (socialmedia) =>{

    var contact_types = Object.keys(socialmedia);

    var socialmedia_array = [];

    for(var i= 0; i<contact_types.length; i++){

        var contact_type = contact_types[i];
        var contact_value = socialmedia[contact_type];

        if(contact_value.length>0){

            if(contact_type === 'whatsapp'){

                var phone_number = contact_value.replace(/\D/g,'');
                socialmedia_array.push({
                    type: contact_type,
                    url:'https://wa.me/'+phone_number
                });
            }
            else{
                socialmedia_array.push({
                    type: contact_type,
                    url: contact_value
                });
            }
           
        }

        if(i==contact_types.length-1){
            return socialmedia_array;
        }

    }

}

//Translate
const getTranslatedFooter = (type, lang) => {
    
    var tr = '';

    if(type === 'terms'){
        switch (lang) {
            case 'fr':
                tr = 'Termes et Conditions'
                break;
            case 'it':
                tr = 'Termini & Condizioni'
                break;
            case 'es':
                tr = 'Términos y Condiciones'
                break;
            default:
                tr = 'Terms & Conditions'
                break;
        }
    }
    else if(type === 'privacy'){
        switch (lang) {
            case 'fr':
                tr = 'Privacy Policy'
                break;
            case 'it':
                tr = 'Privacy Policy'
                break;
            case 'es':
                tr = 'Privacy Policy'
                break;
            default:
                tr = 'Privacy Policy'
                break;
        }
    }
    else if(type === 'cookies'){
        switch (lang) {
            case 'fr':
                tr = 'Cookies Policy'
                break;
            case 'it':
                tr = 'Cookies Policy'
                break;
            case 'es':
                tr = 'Cookies Policy'
                break;
            default:
                tr = 'Cookies Policy'
                break;
        }
    }
    else if(type === 'socialmedia'){
        switch (lang) {
            case 'fr':
                tr = 'Réseaux Sociaux'
                break;
            case 'it':
                tr = 'Reti Sociali'
                break;
            case 'es':
                tr = 'Redes Sociales'
                break;
            default:
                tr = 'Social Media'
                break;
        }
    }
    else if(type === 'contact'){
        switch (lang) {
            case 'fr':
                tr = 'Contacts'
                break;
            case 'it':
                tr = 'Contatti'
                break;
            case 'es':
                tr = 'Contactos'
                break;
            default:
                tr = 'Contact Information'
                break;
        }
    }
    else if(type === 'sitemap'){
        switch (lang) {
            case 'fr':
                tr = 'Plan du site'
                break;
            case 'it':
                tr = 'Mappa del sito'
                break;
            case 'es':
                tr = 'Mapa del sitio'
                break;
            default:
                tr = 'Site Map'
                break;
        }
    }
    

    return tr

}

const mapStateToProps = ({ mode_preview }) => {
  
    return {
      web_id: mode_preview.basic_info.id,
      web_lang: mode_preview.basic_info.language,
      navigation: mode_preview.navigation,
      socialmedia: mode_preview.socialmedia,
      contact : mode_preview.contact,
      footer: mode_preview.footer
  
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
  
    }, dispatch)
  }

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
