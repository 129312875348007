

module.exports = {

    isHTML: function(str) {
        if(!str){
            return false;
        }
        return /<\/?[^>]*>/.test(str);
    },
    validateEmail: function (email) {

        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      
    },
    getFirstWord: function(str) {
        if(!str){
            return str;
        }
        let spacePosition = str.indexOf(' ');
        if (spacePosition === -1)
            return str;
        else
            return str.substr(0, spacePosition);
    },
    forceSSL: function(url){
        console.log("URL = ====", url);
        if(!url || url === undefined){
            return '';
        }
        else{

            if(url.startsWith('http://')){
                return url.replace('http://','https://');
            }
            else if(url.startsWith('//')){
                return url.replace('//','https://');
            }
            else if(url.startsWith('/assets')){
                return 'https://clickai.herokuapp.com'+url;
            }
            else{
                return url.replace('http://','https://')
            }
            
        }
    },
    forceMagicSSL: function(url){
        if(url == undefined){
            return '';
        }
        else{
            if(url.startsWith('//')){
                return 'https:'+url
            }
            return url.replace('http://','https://')
        }
    },
    isURL: function(str){

        if(!str){
            return false;
        }
    
        str.trim();

        console.log("STR = ", str);

        if(str.startsWith('http://') || str.startsWith('https://') || str.startsWith('mailto:')){
            return true;
        }


        if(str.startsWith('www.')){
            var hostname = str.replace('www.','');
            if(hostname.length < 6){
                console.log("MIKE = 0");
                return false;
            }
            console.log("MIKE = 1");
            return true;
        }
        else{
    
            var subdomain_array = str.split('.');
            
            if(subdomain_array.length < 3){
                console.log("MIKE = 2");
                return false;
            }
    
            var sub = subdomain_array[0] || '';
            var domain = subdomain_array[1] || '';
            var extension = subdomain_array[2] || '';
    
            if(sub.length < 3){console.log("MIKE = 3"); return false };
            if(domain.length < 3){console.log("MIKE = 4"); return false};
            if(extension.length < 2){console.log("MIKE = 5"); return false};
           
            return true;
    
        }
    
    },
    isEmpty: function(obj) {
        if(obj === undefined){return true}
        return Object.keys(obj).length === 0;
    },
    createMarkup: function(html) {
        return {__html: html};
    },
    pickTextColorBasedOnBgColorAdvanced: function(bgColor, lightColor, darkColor) {

        if(!bgColor) return darkColor;
        var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        var uicolors = [r / 255, g / 255, b / 255];
        var c = uicolors.map((col) => {
          if (col <= 0.03928) {
            return col / 12.92;
          }
          return Math.pow((col + 0.055) / 1.055, 2.4);
        });
        var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
        
        var newColor = color.toUpperCase();
        if(newColor === 'FFF' || newColor === 'FFFFFF' ||  newColor === 'white'){
            return darkColor;
        }
    
        if(newColor === '87AAB9' || newColor === 'FF8883' || newColor === '87aab9' ||  newColor === 'black'){
            return lightColor;
        }
    
        return (L > 0.179) ? darkColor : lightColor;
    },
    colourNameToHex: function(colour){
        var colours = {"aliceblue":"#f0f8ff","antiquewhite":"#faebd7","aqua":"#00ffff","aquamarine":"#7fffd4","azure":"#f0ffff",
        "beige":"#f5f5dc","bisque":"#ffe4c4","black":"#000000","blanchedalmond":"#ffebcd","blue":"#0000ff","blueviolet":"#8a2be2","brown":"#a52a2a","burlywood":"#deb887",
        "cadetblue":"#5f9ea0","chartreuse":"#7fff00","chocolate":"#d2691e","coral":"#ff7f50","cornflowerblue":"#6495ed","cornsilk":"#fff8dc","crimson":"#dc143c","cyan":"#00ffff",
        "darkblue":"#00008b","darkcyan":"#008b8b","darkgoldenrod":"#b8860b","darkgray":"#a9a9a9","darkgreen":"#006400","darkkhaki":"#bdb76b","darkmagenta":"#8b008b","darkolivegreen":"#556b2f",
        "darkorange":"#ff8c00","darkorchid":"#9932cc","darkred":"#8b0000","darksalmon":"#e9967a","darkseagreen":"#8fbc8f","darkslateblue":"#483d8b","darkslategray":"#2f4f4f","darkturquoise":"#00ced1",
        "darkviolet":"#9400d3","deeppink":"#ff1493","deepskyblue":"#00bfff","dimgray":"#696969","dodgerblue":"#1e90ff",
        "firebrick":"#b22222","floralwhite":"#fffaf0","forestgreen":"#228b22","fuchsia":"#ff00ff",
        "gainsboro":"#dcdcdc","ghostwhite":"#f8f8ff","gold":"#ffd700","goldenrod":"#daa520","gray":"#808080","green":"#008000","greenyellow":"#adff2f",
        "honeydew":"#f0fff0","hotpink":"#ff69b4",
        "indianred ":"#cd5c5c","indigo":"#4b0082","ivory":"#fffff0","khaki":"#f0e68c",
        "lavender":"#e6e6fa","lavenderblush":"#fff0f5","lawngreen":"#7cfc00","lemonchiffon":"#fffacd","lightblue":"#add8e6","lightcoral":"#f08080","lightcyan":"#e0ffff","lightgoldenrodyellow":"#fafad2",
        "lightgrey":"#d3d3d3","lightgreen":"#90ee90","lightpink":"#ffb6c1","lightsalmon":"#ffa07a","lightseagreen":"#20b2aa","lightskyblue":"#87cefa","lightslategray":"#778899","lightsteelblue":"#b0c4de",
        "lightyellow":"#ffffe0","lime":"#00ff00","limegreen":"#32cd32","linen":"#faf0e6",
        "magenta":"#ff00ff","maroon":"#800000","mediumaquamarine":"#66cdaa","mediumblue":"#0000cd","mediumorchid":"#ba55d3","mediumpurple":"#9370d8","mediumseagreen":"#3cb371","mediumslateblue":"#7b68ee",
        "mediumspringgreen":"#00fa9a","mediumturquoise":"#48d1cc","mediumvioletred":"#c71585","midnightblue":"#191970","mintcream":"#f5fffa","mistyrose":"#ffe4e1","moccasin":"#ffe4b5",
        "navajowhite":"#ffdead","navy":"#000080",
        "oldlace":"#fdf5e6","olive":"#808000","olivedrab":"#6b8e23","orange":"#ffa500","orangered":"#ff4500","orchid":"#da70d6",
        "palegoldenrod":"#eee8aa","palegreen":"#98fb98","paleturquoise":"#afeeee","palevioletred":"#d87093","papayawhip":"#ffefd5","peachpuff":"#ffdab9","peru":"#cd853f","pink":"#ffc0cb","plum":"#dda0dd","powderblue":"#b0e0e6","purple":"#800080",
        "rebeccapurple":"#663399","red":"#ff0000","rosybrown":"#bc8f8f","royalblue":"#4169e1",
        "saddlebrown":"#8b4513","salmon":"#fa8072","sandybrown":"#f4a460","seagreen":"#2e8b57","seashell":"#fff5ee","sienna":"#a0522d","silver":"#c0c0c0","skyblue":"#87ceeb","slateblue":"#6a5acd","slategray":"#708090","snow":"#fffafa","springgreen":"#00ff7f","steelblue":"#4682b4",
        "tan":"#d2b48c","teal":"#008080","thistle":"#d8bfd8","tomato":"#ff6347","turquoise":"#40e0d0",
        "violet":"#ee82ee",
        "wheat":"#f5deb3","white":"#ffffff","whitesmoke":"#f5f5f5",
        "yellow":"#ffff00","yellowgreen":"#9acd32"};

        if (typeof colours[colour.toLowerCase()] != 'undefined')
            return colours[colour.toLowerCase()];

        return false;
    },
    getSocialMediaArray: function(socialmedia){

        var contact_types = Object.keys(socialmedia);
    
        var socialmedia_array = [];
    
        for(var i= 0; i<contact_types.length; i++){
    
            var contact_type = contact_types[i];
            var contact_value = socialmedia[contact_type];

            var dic = {};

            if(contact_type === 'whatsapp'){

                var phone_number = contact_value.replace(/\D/g,'');
                var dic = {
                
                    type:contact_type,
                    url:'https://wa.me/'+phone_number,
                    value: contact_value
        
                }
            }
            else{

                var dic = {
                
                    type:contact_type,
                    url:'https://'+contact_type+'.com/'+contact_value,
                    value: contact_value
        
                }

            }
    
    
          
    
            if(contact_value.length>0){
                    
                socialmedia_array.push(dic);
            }
    
            if(i==contact_types.length-1){
    
                return socialmedia_array;
            }
    
        }
    
    },
    getBadgesArray: function(badges_array){

        var contact_types = Object.keys(badges_array);
    
        var finalbadges_array = [];
    
        for(var i= 0; i<contact_types.length; i++){
    
            var contact_type = contact_types[i];
            var contact_value = badges_array[contact_type];

    
            var dic = {
                
                type:contact_type,
                url:contact_value

            }
    
            if(contact_value.length>0){
                    
                finalbadges_array.push(dic);
            }
    
            if(i==contact_types.length-1){
    
                return finalbadges_array;
            }
    
        }
    
    },
    readingTime: function(text, options) {
        var words = 0, start = 0, end = text.length - 1, wordBound, i
      
        options = options || {}
      
        // use default values if necessary
        options.wordsPerMinute = options.wordsPerMinute || 200
      
        // use provided function if available
        wordBound = options.wordBound || ansiWordBound
      
        // fetch bounds
        while (wordBound(text[start])) start++
        while (wordBound(text[end])) end--
      
        // calculate the number of words
        for (i = start; i <= end;) {
          for (; i <= end && !wordBound(text[i]); i++) ;
          words++
          for (; i <= end && wordBound(text[i]); i++) ;
        }
      
        // reading time stats
        var minutes = words / options.wordsPerMinute
        var time = minutes * 60 * 1000
        var displayed = Math.ceil(minutes.toFixed(2))

    
        return {
          text: displayed + ' min read',
          minutes: minutes,
          time: time,
          words: words,
          counter: module.exports.ConvertSecondsToTime(time/1000)
        }
    },
    ConvertSecondsToTime(secs){
        var t = new Date(1970,0,1);
        t.setSeconds(secs);
        var s = t.toTimeString().substr(0,8);
        if(secs > 86399)
            s = Math.floor((t - Date.parse("1/1/70")) / 3600000) + s.substr(2);
        return s;
    },
    getFormSearchParams(data){
        const ret = [];
        for (let d in data)
          ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        return ret.join('&');
    }
    
}

function ansiWordBound(c) {
    return (
      (' ' === c) ||
      ('\n' === c) ||
      ('\r' === c) ||
      ('\t' === c)
    )
  }