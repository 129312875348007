import React from 'react';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
} from 'react-share';


var ClickShareButton = ({type, payload, style}) =>{

    var {url, quote, title, media} = payload;

    if(type === 'facebook_share'){

        return (
            <FacebookShareButton
                url={url}
                quote={quote}
                style={style}
                className="Demo__some-network__share-button">
                <i className="socicon socicon-facebook facebook-color" /> <span>Facebook</span>
            </FacebookShareButton>
        )
    }
    else if(type === 'twitter_share'){

        return (
            <TwitterShareButton
                url={url}
                title={title}
                style={style}
                className="Demo__some-network__share-button">
                <i className="socicon socicon-twitter twitter-color" /> <span>Twitter</span>
            </TwitterShareButton>
        )
    }
    else if(type === 'whatsapp_share'){
        return(
            <WhatsappShareButton
                url={url}
                title={title}
                style={style}
                separator=":: "
                className="Demo__some-network__share-button">
               <i className="socicon socicon-whatsapp whatsapp-color" /> <span>Whatsapp</span>
            </WhatsappShareButton>
        )
    }
    else if(type === 'linkedin_share'){
        return(
            <LinkedinShareButton
                url={url}
                windowWidth={1000}
                windowHeight={730}
                style={style}
                className="Demo__some-network__share-button">
               <i className="socicon socicon-linkedin linkedin-color" /> <span>Linkedin</span>
            </LinkedinShareButton>
        )
    }
    else if(type === 'email_share'){
        return(
            <EmailShareButton
                url={url}
                style={style}
                className="Demo__some-network__share-button">
               <i className="fa fa fa-envelope" /> <span>Email</span>
            </EmailShareButton>
        )
    }


}

export default ClickShareButton