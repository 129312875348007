import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {pickTextColorBasedOnBgColorAdvanced} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Background from '../general/block_background';
import Block_Settings from '../general/block_settings';

import {Image_Frame} from '../general/page_media';

class Block_Faq extends React.Component {

    constructor(props) {
        super(props); 
       

    }

   
    render(){
        var currentblock = this.props.currentblock || {};
        var {title, description, title_html, description_html, layout,  image, bg_color, bg_image, slider} = currentblock;
        
        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');
        var block_class = Block_Settings(currentblock);

        var block_settings = currentblock.settings || {};

        var faq_items = slider.map((item, index)=> {
            return (
                <Card_Faq key={index} payload={item} index={index} block_settings={block_settings} />
            )
        })


        return( 

           
                <div className={block_class + ' '+layout} style={{background: bg_color, color: text_color}}>    
        
                    <div className="faq-edit-wrapper" >  

                        <div className="faq-image">
                            <Image_Frame image_url={image} settings={block_settings} className="mySlides" />
                        </div>
                                        
                        <div id="faqContent">   
                            <div className="faq-title">
                                <Block_Textbox type="block_title" content={title} content_html={title_html} />   
                                <Block_Textbox type="block_description" content={description} content_html={description_html} />         
                            </div>
                            <div className="faq-container accordion" id="accordion">
                                {faq_items}
                            </div>
                        </div>      

                        {bg_image && <Block_Background bg_image={bg_image} />}

                    </div>
                </div>
           
              
        )
    }
};


class Card_Faq extends React.Component {

    constructor(props) {
        super(props);   
        this.state = {
            isActive: false
        }
    }
     
    render(){
 
        var {payload, block_settings, index} = this.props;
        var {title, description} = payload;
        var {isActive} = this.state;
 
        var block_title_size = block_settings.title_size || 20;
        var block_description_size = block_settings.description_size || 18;
        var block_title_color = block_settings.title_color || '#000000';
        var block_description_color = block_settings.description_color || '#000000';
        
        return (
            <>
                <div className={(isActive ? 'active' : '')}>
                    <h3 className="accordion-toggle" onClick={()=>this.setState({isActive: !isActive})}  style={{fontSize: block_title_size+'px', color: block_title_color, fontWeight: 'bold'}}>
                        {title}
                    </h3>
                    <div className="accordion-content" style={{fontSize: block_description_size+'px', color: block_description_color}}>
                        {description}
                    </div>
                </div>
            </>
        )

    }
   
}


const mapStateToProps = ({ mode_edit }) => {
  
  return {
  
  }
}
  
const mapDispatchToProps = dispatch => {
  return bindActionCreators({
  
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Block_Faq)

