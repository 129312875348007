import React from 'react'
import {isHTML} from '../../../../../helpers/GeneralHelper';
import ParseHTML from 'html-react-parser';

class TextBox extends React.Component{

    render(){

            var {type, content, content_html} = this.props;

            if(type === 'block_title'){
                return ( 
                    <>
                        {isHTML(content_html) ? (
                            <HtmlParser content={content_html} />
                        ) : (
                            <h1>{content}</h1>
                        )}
                    </>
                )
            }
            else if(type === 'block_description'){
                return ( 
                    <>
                        {isHTML(content_html) ? (
                            <HtmlParser content={content_html} />
                        ) : (
                            <p>{content}</p>
                        )}
                    </>
                )
            }
            else if(type === 'page_title'){
                return ( 
                    <>
                        {isHTML(content_html) ? (
                            <HtmlParser content={content_html} />
                        ) : (
                            <h1>{content}</h1>
                        )}
                    </>
                )
            }
            else if(type === 'page_subtitle'){
                return ( 
                    <>
                        {isHTML(content_html) ? (
                            <HtmlParser content={content_html} />
                        ) : (
                            <h4>{content}</h4>
                        )}
                    </>
                )
            }
            else if(type === 'page_description'){
                return ( 
                    <>
                        {isHTML(content_html) ? (
                            <HtmlParser content={content_html} />
                        ) : (
                            <p>{content}</p>
                        )}
                    </>
                )
            }

    }

}

const HtmlParser = ({content}) =>{

    var string_content = ''+content;
    
    string_content = string_content.replace(/<h1><\/h1>/g, '<h1><br/></h1>');
    string_content = string_content.replace(/<h2><\/h2>/g, '<h2><br/></h2>');
    string_content = string_content.replace(/<h3><\/h3>/g, '<h3><br/></h3>');
    string_content = string_content.replace(/<h4><\/h4>/g, '<h4><br/></h4>');
    string_content = string_content.replace(/<h5><\/h5>/g, '<h5><br/></h5>');
    string_content = string_content.replace(/<h6><\/h6>/g, '<h6><br/></h6>');

    string_content = string_content.replace(/<p><\/p>/g, '<p><br/></p>');

    console.log("NEW CONTENT ===> ", string_content);

    return ParseHTML(string_content);
   
}

  
export default TextBox