import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Browser_LiveMode from './components/App/Browser/mode_live/bj_container';
import Error_Page from './components/App/General/Error_Page';


console.log= function(){}

const App = () => {

    if (typeof window === "undefined") {
        console.log("Oops, `window` is not defined")
        global.window = {}
    }

    const initialData = window.__PRELOADED_STATE__ || {};

    console.log('PROPS ===> ', initialData);

    if(!window.__PRELOADED_STATE__){
        return (
            <div className="master-container"  id="website-container">
              <div className="flex-container" id="loadingFlex">
                <i className="fa fa-spinner fa-spin" />
              </div>
            </div>
        )
    }


    return (
        <Switch>

            <Route exact path="/" render={(props) => <Browser_LiveMode {...props}  initialData={initialData}/>} /> 
            <Route exact path="/:id" render={(props) => <Browser_LiveMode {...props}  initialData={initialData}/>} />
            <Route path="/:id/:subpath" render={(props) => <Browser_LiveMode {...props}  initialData={initialData}/>} />

            <Route component={Error_Page} />

        </Switch>
    )
}

export default App