import { 
    
  GET_FULLWEBSITE_BASICINFO,
  GET_FULLWEBSITE_STYLE ,
  GET_FULLWEBSITE_NAVIGATION , 
  GET_FULLWEBSITE_PAGES, 
  GET_FULLWEBSITE_CONTACT, 
  GET_FULLWEBSITE_SOCIALMEDIA,
  GET_FULLWEBSITE_BLOCKS,
  GET_FULLWEBSITE_FOOTER,
  GET_FULLWEBSITE_HEADER,
  
  GET_FULLWEBSITE_STORE,
  GET_FULLWEBSITE_BLOG,
  GET_FULLWEBSITE_STOREINFO,
  GET_FULLWEBSITE_PRODUCT,
  GET_FULLWEBSITE_ARTICLE,

  HANDLE_FULLWEBSITE_CART,
  HANDLE_FULLWEBSITE_BLOG,
  HANDLE_FULLWEBSITE_MAINTENANCE,

  HANDLE_CART_ADD,
  HANDLE_CART_REMOVE,
  HANDLE_CART_RESET,
  HANDLE_CART_SETUP,
  HANDLE_CHECKOUT_SHOW,

  HANDLE_MODAL_GALLERY,
  HANDLE_MODAL_VIDEOS,
  
  RESET_APP

} from '../_types'

const initialState = {
    basic_info: {},
    style:{},
    navigation:[],
    pages:[],
    contact:{},
    socialmedia: {},
    blocks:[],
    header:{},
    footer: {},

    store: [],
    blog: [],
    cart: [],

    isCartOpen: false,
    isBlogOpen: false,
    isMaintenance: false,
    isCheckout: false,

    isModalGallery: false,
    isModalVideos: false,

    CurrentBlogPost:{},
    CurrentGallery: [],
    CurrentVideos: [],

    CurrentGalleryIndex: null,
    CurrentVideoIndex: null
}


export default function(state = initialState, action) {

  switch(action.type) {
    
    // GET FULLWEBSITE

    case GET_FULLWEBSITE_BASICINFO:
      return { ...state, basic_info: action.payload }
    
    case GET_FULLWEBSITE_STYLE:
      return { ...state, style: action.payload }

    case GET_FULLWEBSITE_NAVIGATION:
      return { ...state, navigation: action.payload }

    case GET_FULLWEBSITE_PAGES:
      return { ...state, pages: action.payload }

    case GET_FULLWEBSITE_CONTACT:
      return { ...state, contact: action.payload }
    
    case GET_FULLWEBSITE_SOCIALMEDIA:
      return { ...state, socialmedia: action.payload }

    case GET_FULLWEBSITE_BLOCKS:
      return {...state, blocks: action.payload}

    case GET_FULLWEBSITE_HEADER:
        return {...state, header: action.payload}
    
    case GET_FULLWEBSITE_FOOTER:
        return {...state, footer: action.payload}

    case GET_FULLWEBSITE_STORE:
      return {...state, store: action.payload}

    case GET_FULLWEBSITE_STOREINFO:
      return {...state, store_info: action.payload}
    
    case GET_FULLWEBSITE_BLOG:
      return {...state, blog: action.payload}

    case HANDLE_FULLWEBSITE_MAINTENANCE:
      return { ...state, isMaintenance: action.status}

    case HANDLE_CART_ADD:

      var currentCart = state.cart || [];
      var currentProduct = action.product;
      var product_index = currentCart.findIndex((item)=> ((item.id === currentProduct.id) && (item.option === currentProduct.option)));
      var isCartOpen = currentCart.length == 0 ? true : false;

      var newCart = [];

      if(product_index != -1){
        //Product already in store 

        var currentQuantity = currentCart[product_index].quantity || 1;
        var newQuantity = currentProduct.quantity || 1;
        var totalQuantity = currentQuantity + newQuantity;

        console.log("CURRENT QUANTITY ===> ", currentQuantity);
        console.log("CURRENT QUANTITY ===> ", newQuantity);
        console.log("TOTAL QUANTITY ====>", totalQuantity);

        newCart = [
          ...currentCart.slice(0,product_index),
          {...currentCart[product_index], quantity: totalQuantity },
          ...currentCart.slice(product_index+1),
        ]
      }
      else{
         newCart = [...state.cart, action.product];
      }

    
      localStorage.setItem('cart',JSON.stringify(newCart));

      return { ...state, cart: newCart, isCartOpen: true}

    case HANDLE_CART_REMOVE:
      var filtredCart = state.cart.filter((item, index) => index != action.index)
      
      localStorage.setItem('cart', JSON.stringify(filtredCart));

      return { ...state, cart: filtredCart}
    
    case HANDLE_CART_RESET:

      localStorage.setItem('cart',JSON.stringify([]));

      return { ...state, cart: [], isCartOpen: false}

    case HANDLE_CART_SETUP:
      return { ...state, cart: action.cart || []}

    case HANDLE_FULLWEBSITE_CART:
        return {...state, isCartOpen: action.status}

    case HANDLE_CHECKOUT_SHOW:
        return {...state, isCheckout: action.status}
    
    case HANDLE_MODAL_GALLERY:
        return {...state, isModalGallery: action.status, CurrentGallery: action.source, CurrentGalleryIndex: action.index}

    case HANDLE_MODAL_VIDEOS:
        return {...state, isModalVideos: action.status, CurrentVideos: action.source, CurrentVideoIndex: action.index}

    case HANDLE_FULLWEBSITE_BLOG:
      return { ...state, isBlogOpen: action.status , CurrentBlogPost: action.source}

    case RESET_APP:
      return initialState;

  }

  return state
}
