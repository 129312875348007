import { combineReducers } from 'redux'

import mode_preview from './mode_preview'



const rootReducer = (history) => combineReducers({
  mode_preview
})

export default rootReducer
