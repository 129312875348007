import React from 'react';
import { Link } from 'react-router-dom';

class Error_Page extends React.Component {

    render(){

      return(
        <>
            <div className="mastercontainer">
              <div className="left-bg">
                <Link to="/">
                    <img src="/assets/img/dashboard/logo.png" className="logo" />
                </Link>
              </div>
              
              <div className="step-title">
                  <h1>
                  Oops.<span>We can't find this page</span>
                  </h1>
              </div>
        
              <div className="step-content">
                <div className="frame">
                  <strong>404</strong>
                </div>
                <Link className="home-btn" to="/">Go to homepage</Link>
              </div>
            </div>
     
        </>
      )

    }

}

export default Error_Page;
