import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {isURL, validateEmail, pickTextColorBasedOnBgColorAdvanced, getFormSearchParams} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Background from '../general/block_background';
import Block_Button from '../general/block_button';
import Block_Settings from '../general/block_settings';

import {Image_Frame, Video_Frame, Map_Frame} from '../general/page_media';

import DatePickerController from '../general/block_datepicker'

import moment from 'moment';
import axios from 'axios';
import swal from 'sweetalert';


class Block_CustomForms extends React.Component {

    constructor(props) {
        super(props);   

        this.state = {
            form: [],
            isSending: false,
            isMissing: false
        }

        this.onChangeForm = this.onChangeForm.bind(this);
        this.onSendForm = this.onSendForm.bind(this);
        this.onFormRedirect = this.onFormRedirect.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onFormatForm = this.onFormatForm.bind(this);
        
    }

    componentDidMount(){
        var {currentblock} = this.props;
        var form_array = currentblock.slider || [];
       
        this.onFormatForm(form_array);
    }

    onFormatForm(form_array){
        var form_mapped = form_array.map((item, index)=> {

            var form_element = item.element;
            var form_type = item.type;
            var form_value = item.value || '';

            var form_dic = {
                ...item,
                value: form_value,
            }

            if(form_element === 'dropdown' || form_element === 'option'){
                var form_values = item.values || [];
                var first_value = form_values[0] ? form_values[0].label : '';
                form_dic = {...form_dic, value: first_value}
            }
            else if(form_element === 'calendar'){
               if(form_type === 'date_range'){
                    form_dic = {...form_dic, from: new Date(), to: new Date()}
               }
               else{
                    form_dic = {...form_dic, value: new Date()}
               }
            }
           
            return form_dic
        });

        this.setState({form: form_mapped});
    }

    //Changes
    onChangeForm(name_id, payload){

        var form_array = this.state.form || [];
        var form_item_index = form_array.findIndex((item)=> item.name === name_id);

        if(form_item_index == -1) return;

        var form_item_obx = form_array[form_item_index];
        var new_form_item = {...form_item_obx, ...payload};

        var new_form = [
            ...form_array.slice(0,form_item_index),
            new_form_item,
            ...form_array.slice(form_item_index+1),
        ]

        this.setState({form: new_form});
    }

    onSendForm(){
    
        var {web_lang, currentblock} = this.props;

        var custom_form = this.state.form || [];

        //Checking Fields

        var isFormValid = getFormValidStatus(custom_form);
        if(!isFormValid){

            this.setState({isMissing: true});
            swal(
                getTranslatedFormAlerts('missing_fields', web_lang), 
                getTranslatedFormAlerts('missing_general', web_lang), 
                "warning"
            );
            return 
        }

        //Generates new form 
        var form_mapped = getFormMappedParams(custom_form);
    
        //Checking Form Submit type
        var block_btn = currentblock.block_btn || {}; 
        var button_link = block_btn.button_action || '';
        var button_submit = block_btn.button_submit || 'submit';
        var button_success = block_btn.button_success || getTranslatedFormAlerts('form_success', web_lang);
        var button_search = block_btn.button_search || false;

        if(button_submit === 'submit'){
            return this.onFormSubmit(form_mapped, button_success);
        }
        else if(button_submit === 'redirect'){
            return this.onFormRedirect(form_mapped, button_link, button_search);
        }
        else if(button_submit === 'submit_redirect'){
           return this.onFormSubmit(form_mapped, button_success, button_link, true, button_search);
        }
    
    }

    //Actions
    onFormRedirect(form, link, isSearch){

        if(!form) return;
        if(!link) return;

        
        //Get Search Params
        var form_redirect_search = getFormSearchParams(form); 

        var isExternalUrl = isURL(link);
        if(isExternalUrl){
            if(isSearch){
              
               var final_redirect_url = link + '?' + form_redirect_search;
               window.location.href = final_redirect_url;
            }
            else{
                window.location.href = link;
            }
        }
        else{

            if(isSearch){
                this.props.history.push(link + '?' + form_redirect_search);
            }
            else{
                this.props.history.push(link)
            }

        }

    }

    onFormSubmit(form_mapped, message_success, link, isRedirect, isSearch){

        var {currentblock} = this.props;
        var {web_id} = this.props;

        var form_array = this.state.form || [];
        var custom_form = form_array.map((item)=> {

            var form_element = item.element;
            var form_type = item.type;
            var form_value = item.value || '';

            var form_dic = {
                div: form_element,
                type: form_type,
                label: item.label,
                name: item.name,
                value: form_value,
            }

            if(form_element === 'calendar'){
               if(form_type === 'date_range'){
                    form_dic = {...form_dic, from: item.from, to: item.to}
               }
            }
           
            return form_dic
        });

		const url = '/api/v1/builder/websites/forms/send';
		const jwt = localStorage.getItem('jwt_token');

        this.setState({ isSending:true});

        var form = {
            id: web_id,
            custom: custom_form,
            block: currentblock.id
        }


		axios.post(url , form ,{ headers: {authorization: jwt}  })
			.then(response => {

            this.setState({
                isMissing: false,
                isSending: false
            });

            var original_form = currentblock.slider || [];
            this.onFormatForm(original_form);
           
            if(isRedirect){
                return this.onFormRedirect(form_mapped, link, isSearch);
            }
            else{

                return swal(
                    message_success,
                    '',
                    'success'
                );
               
            }

		})
		.catch(error => {
            console.log(error);
            
            this.setState({
                isMissing: false,
                isSending: false
            });

            var original_form = currentblock.slider || [];
            this.onFormatForm(original_form);

		});
    }
   

    render(){

        var {isSending, isMissing} = this.state;
        var {currentblock, web_lang} = this.props;
        var {title, description, title_html, description_html, layout, bg_color, bg_image} = currentblock;
        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');
        
        
        var block_class = Block_Settings(currentblock);

        //Form
        var form = this.state.form || [];
        var form_items = form.map((item, index) => {
            return (
                <div className="custom-form-item-wrapper" key={item.id}>
    
                        <label htmlFor={item.name}>
                            {item.label}
                            {item.required && (
                                <span style={{display: 'inline', color: '#c10000', fontFamily: 'inherit'}}> *</span>
                            )}    
                        </label>
                                        
                        <FormItem payload={item} 
                                 web_lang={web_lang} 
                                 onChangeForm={this.onChangeForm} 
                                 isMissing={isMissing} 
                        />

                        {item.legend && (
                            <legend>{item.legend}</legend>
                        )}

                </div>
            )  
        
        });
         

        var block_btn = currentblock.block_btn || {}; 

        var form_wrapper_style = {
            textAlign: 'left',
            border: (layout === 'layout-2') ? 'solid 0.5px lightgray' : '0'
        }
      
      return(
               
            <div className={block_class} style={{background: bg_color, color: text_color }}>
                    <div className={"design-block-custom-form "+layout}>

                            <div className="design-block-custom-form-info">
                                
                                <Block_Textbox type="block_title" content={title} content_html={title_html} />  
                                <Block_Textbox type="block_description" content={description} content_html={description_html} />
                                
                            </div>

                            <FormMedia {...this.props} />
                            
                            <form className="custom-form-wrapper" style={form_wrapper_style}>

                                {form_items}
                                    
                                <div style={{margin: '10px'}}>
                                    <Block_Button type='page' subtype='form' block_btn={block_btn} isSending={isSending} onSendForm={this.onSendForm}/>
                                </div> 
    
                            </form>
                    </div>

                    {bg_image && <Block_Background bg_image={bg_image} />}
                            
            </div>
                                                                         
        )
    }
};



const FormItem = ({payload, web_lang, onChangeForm, isMissing}) =>{

    var form_object = payload || {};
    var form_element = form_object.element;
    
    var form_required = form_object.required;
    var form_type = form_object.type;
    var form_name = form_object.name;
    var form_placeholder = form_object.placeholder;
    var form_values = form_object.values || [];
    var form_value = form_object.value;
    var form_max = form_object.max;

    var finalStyle = (form_required && isMissing && !form_value) ? {background: '#FFDADA'} : {};
    
    if(form_element === 'input'){

        if(form_type === 'text'){
            return (<input type='text' value={form_value} placeholder={form_placeholder} name={form_name} maxLength={form_max} onChange={(e)=> onChangeForm(form_name, {value: e.target.value})} style={finalStyle} />);
        }
        else if(form_type === 'email'){
            var finalStyle = (form_required && isMissing && !validateEmail(form_value)) ? {background: '#FFDADA'} : {};
            return (<input type='email' value={form_value} placeholder={form_placeholder} name={form_name} onChange={(e)=> onChangeForm(form_name, {value: e.target.value})} style={finalStyle}/>);
        }
        else if(form_type === 'tel'){
            return (<input type='tel' value={form_value} placeholder={form_placeholder} name={form_name} pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" onChange={(e)=> onChangeForm(form_name, {value: e.target.value})} style={finalStyle}/>);
        }
        else if(form_type === 'password'){
            return (<input type='password' value={form_value} placeholder={form_placeholder} name={form_name} onChange={(e)=> onChangeForm(form_name, {value: e.target.value})} style={finalStyle} />);
        }
        else{
            return (<input type='text' value={form_value} placeholder={form_placeholder} name={form_name} maxLength={form_max} onChange={(e)=> onChangeForm(form_name, {value: e.target.value})} style={finalStyle} />);
        }
        
    }
    else if(form_element === 'textarea'){
        var form_row = form_object.rows;
        return (<textarea placeholder={form_placeholder} maxLength={form_max} rows={form_row} value={form_value} onChange={(e)=> onChangeForm(form_name, {value: e.target.value})} style={finalStyle}/>);
    }
    else if(form_element === 'dropdown'){
        return (
            <select name={form_name} id={form_name}>
                {form_placeholder && <option disabled defaultChecked={form_values.length == 0}>{form_placeholder}</option>}
                {form_values.map((item, index)=> <option key={index} defaultValue={item.value} defaultChecked={item.selected}>{item.label}</option> )}
            </select>
        );
    }
    else if(form_element === 'option'){
        return (
            <div onChange={(e)=> onChangeForm(form_name, {value: e.target.value})}>
                {form_values.map((item, index)=> 
                    <div className="custom-form-radio" key={index}>
                        <input type="radio" name={form_name} value={item.label} id={item.value} defaultChecked={form_value === item.label}/>
                        <label htmlFor={item.value}>{item.label}</label>
                    </div>
                )}
            </div>
        )
    }
    else if(form_element === 'checkbox'){
        return (
            <>
                {form_values.map((item, index)=> 
                    <div className="custom-form-checkbox" key={index}>
                        <input type="checkbox" id={item.name} name={item.name} value={item.value} defaultChecked={item.selected}/>
                        <label htmlFor={item.name}>{item.label}</label>
                    </div>
                )}
            </>
        )
    }
    else if(form_element === 'calendar'){

        var form_name = form_object.name;
        var form_min = form_object.min;
        var form_max = form_object.max;

        if(form_type === 'date'){
            return (
                <DatePickerController format="day_month_year" web_lang={web_lang} minDate={form_min} maxDate={form_max} onChangeForm={onChangeForm} form_name={form_name}/>
            )
        }
        else if(form_type === 'date_time'){
            return (
                <DatePickerController format="day_month_time"  web_lang={web_lang} minDate={form_min} maxDate={form_max} onChangeForm={onChangeForm} form_name={form_name}/>
            )
        }
        else if(form_type === 'date_range'){
            return (
                <DatePickerController format="date_range"  web_lang={web_lang} form_object={form_object} onChangeForm={onChangeForm} form_name={form_name}/>
            )
        }
    }
    else if(form_element === 'time'){
        return (
            <DatePickerController format="time" web_lang={web_lang} onChangeForm={onChangeForm} form_name={form_name}/>
        )
    }
    else if(form_element === 'slider'){
        var form_min = form_object.min;
        var form_max = form_object.max;
        return ( <input type="range" id={form_name} name={form_name} min={form_min} max={form_max} /> )
         
    }
    else{

        return <h1>Time...</h1>
    }
}


//Media 
class FormMedia extends React.Component {

    render(){

        var {currentblock} = this.props;
        var {map, image, video} = currentblock;

        var block_settings = currentblock.settings || {};

        return (
            <div className="custom-form-image">
        
                {image && (
                    <Image_Frame image_url={image} settings={block_settings} className="custom-form-image-img" />
                )}
                
                {map && (
                    <Map_Frame map_url={map} settings={block_settings} width="600" height="450" />
                )}
            
                {video && (
                   <Video_Frame video_url={video} settings={block_settings} />
                )}
            </div>
        )

    }
}

const getFormMappedParams = (form_array) => {

    var form_map = {};
    for (var i = 0; i < form_array.length; i++){ 

        var item = form_array[i];

        var form_type = item.type;

        var form_name = item.name;
        var form_value = item.value;
        var form_from = item.from; 
        var form_to = item.to;

        if(form_type === 'date_range'){
            form_map['from'] =  moment(form_from).format('YYYY-MM-DD');
            form_map['to'] =  moment(form_to).format('YYYY-MM-DD');
        }
        else{
            form_map[form_name] = form_value;
        }
        
    }
    
    return form_map;

}

const getFormValidStatus = (form_array) => {
    var isValid = true;

    var req_array = form_array.filter((item)=> item.required == true); 
    console.log("required_form_array === ", req_array);

    for(var i=0; i<req_array.length; i++){

        var item = form_array[i];
        var form_type = item.type;

        var form_name = item.name;
        var form_value = item.value;
        var form_from = item.from; 
        var form_to = item.to;

        if(form_type === 'date_range'){
            isValid = true;
        }
        else if(form_type === 'email'){
            if(!validateEmail(form_value)){
                return false
            }
        }
        else{
            
            if(!form_value){
                return false
            }
        }

        console.log("FORM NAME ===> "+ form_name + ' --- ' + form_value + ' --- ');
        
    }
   

    return isValid;
}


const getTranslatedFormAlerts = (type, lang) => {
    
    var tr = '';

    if(type === 'missing_fields'){
        switch (lang) {
            case 'fr':
                tr = 'Champs Manquants'
                break;
            case 'it':
                tr = 'Campi Mancanti'
                break;
            case 'es':
                tr = 'Campos Faltantes'
                break;
            default:
                tr = 'Missing Fields'
                break;
        }
    }
    else if(type === 'missing_general'){
        switch (lang) {
            case 'fr':
                tr = 'Vous devez remplir tous les champs obligatoires pour soumettre ce formulaire.'
                break;
            case 'it':
                tr = 'È necessario compilare tutti i campi obbligatori per inviare questo modulo.'
                break;
            case 'es':
                tr = 'Debe completar todos los campos obligatorios para enviar este formulario.'
                break;
            default:
                tr = 'You need to fill all the required fields to submit this form.'
                break;
        }
    }
   

    return tr

}


 
const mapStateToProps = ({ mode_preview }) => {
    return {
        web_id: mode_preview.basic_info.id,
        web_lang: mode_preview.basic_info.language,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    
    }, dispatch)
  }

export default connect(mapStateToProps, mapDispatchToProps)(Block_CustomForms)

