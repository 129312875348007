import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {handleFullwebsite_Cart} from '../../../../actions/mode_preview';

import {forceSSL} from '../../../../helpers/GeneralHelper';
import { isMobile } from 'react-device-detect';
 
class NavBar extends React.Component {

    constructor(props) {
        super(props);
    
        this.HandleBurger = this.HandleBurger.bind(this);
    }


    HandleBurger(nav_id){

        document.getElementById('burger').classList.toggle('close-burger-nav');
        document.getElementById('master_navbar').classList.toggle('active');
        document.getElementsByClassName('nav-bar')[0].classList.toggle('active');
        document.getElementsByClassName('right')[0].classList.toggle('active');
 
        if(nav_id){
            this.props.history.push(nav_id);
        }

    }

    
    render(){


        var {currentpage, navigation, socialmedia, header, store_settings} = this.props;
        
        
        //Header Styles
        var header_text = header.header_text || '';
        var header_icon = header.header_icon || '';
        var header_icon_type = header.header_icon_type || '';
        
        var header_home_icon = header.header_home_icon || false;
        var header_numbers = header.header_numbers || false;
        var header_shadow = header.header_shadow || false;
        var header_border = header.header_border || false;
        var header_socialmedia = header.header_socialmedia || false;

        var header_text_status = header.header_text_status || false;
        var header_text_color = header.header_text_color || '#000000';
        var header_background_color = header.header_color || '#ffffff';

        var header_icon_size = header.header_icon_size || 35;
        var font_size_title = header.header_font_title || 18;
        var font_size_link = header.header_font_link || 15;

        //Check Layouts 

        var header_layout = header.header_layout;
        var header_styles_dic = getHeaderStyles(currentpage, header_background_color, header_text_color, header_layout);
        var header_styles = header_styles_dic.header_styles || {};
        var header_transparent = header_styles_dic.header_transparent || false;

        var header_classes = ['nav-bar'];
        header_home_icon && header_classes.push("show-home-icon");
        header_numbers && header_classes.push("show-nav-numbers");
        header_shadow && header_classes.push("show-nav-shadow");
        header_border && header_classes.push("show-nav-border");
        header_transparent && header_classes.push("nav-transparent");
        var header_class = header_classes.join(" ");

     
        //Social Media Icons
        var socialmedia_array = socialmedia ? getSocialMediaArray(socialmedia) : [];
        var socialmedia_items = socialmedia_array ? socialmedia_array.map((d,index) => {
            return (
                <a href={d.url} key={index} target='__blank'>
                    <i className={"socicon socicon-"+d.type}></i>
                </a>
            )
        }) : [];
       
        //Navigation
        var route_params = this.props.match.params;
        var navigation_filtred = navigation.filter((d) => d.active !== 'false') || [];

        var navigation_items = navigation_filtred ? navigation_filtred.map((d,index) => {
            return (
                <NavbarItem payload={d} 
                    index={index} 
                    key={index} 
                    HandleBurger={this.HandleBurger} 
                    route_params={route_params} 
                    link_size= {font_size_link}
                    isHomeIcon={header_home_icon}
                    
                    />
            )
        }) : []; 


        var cart_items = this.props.cart || [];

        var cta_color = store_settings.extra_color || '#38964c';

        return (
    
            <nav className={header_class} style={header_styles}>

                    <div className="left">
                        <div className="fa fa-bars" id="burger" onClick={()=>this.HandleBurger()}/>
                        
                        <Link className="nav-logo-wrapper" to="/">
                            <img className={"nav-logo "+(header_icon_type)} 
                                    src={forceSSL(header_icon)} 
                                    style={{height: header_icon_size}} 
                                    />
                            {header_text_status && (
                                <strong className="nav-title" style={{fontSize: font_size_title}}>
                                    {header_text}
                                </strong>
                            )}
                            
                        </Link>
                                
                        {isMobile && (
                            <>
                                {cart_items.length > 0 && (
                                    <a className="cart-nav-btn hide-desktop" id="cartBtn" onClick={()=>this.props.handleFullwebsite_Cart(true)}>
                                        <i className="fa fa-shopping-cart" />
                                        {cart_items.length > 0 && <span style={{background: cta_color, color: '#ffffff'}}>{cart_items.length}</span>}
                                    </a>
                                )}
                            </>
                        )}
                        

                    </div>

                    <div className="nav-links" id="master_navbar">
                            {navigation_items}
                            {cart_items.length > 0 && (
                                <a className="cart-nav-btn hide-phone" id="cartBtn" onClick={()=>this.props.handleFullwebsite_Cart(true)}>
                                    <i className="fa fa-shopping-cart" />
                                    {cart_items.length > 0 && <span style={{background: cta_color, color: '#ffffff'}}>{cart_items.length}</span>}
                                </a>
                            )}
                    </div>

                    <div className="right">
                        {header_socialmedia && (
                            <div className="profile-stats"> {socialmedia_items} </div>
                        )}
                    </div>
            </nav>
           
    
        )
        
    }

}



const NavbarItem = ({payload, index, HandleBurger, route_params, link_size, isHomeIcon}) =>{

    var nav_index = index+1;
    var nav_text = nav_index <10 ? '0'+ nav_index : nav_index

    var {active, link, title} = payload;        
    var isActive = false;

    //Check if Link is External

    if(!active){
        return (
            <a href={link} target='__blank' key={index} style={{fontSize: link_size}}>
                <span>{nav_text}</span><strong>{title}</strong>
            </a> 
        )
    }
   
    var nav_link = link.replace('/', '');

    //Hightlight Pages 
    if(route_params.id){
        isActive = (route_params.id === nav_link) ? true : false;
    }

    //Highlight Products
    if(payload.product){
        isActive = (payload.product === route_params.subpath) ? true : false;
    }
    //Highlight BlogPosts
    if(payload.article){
        isActive = (payload.article === route_params.subpath) ? true : false;
    }

    //Highlight Others
    if(!route_params.id && !route_params.subpath){
        isActive = (link === '/') ? true : false
    }

    //Generate URI

    var final_uri = link;

    //Generate Dropdown

    var dropdown_array = payload.dropdown || [];
    var dropdown_items = dropdown_array.map((item, index)=> {

        if(!item.active){
            return (
                <a href={item.link} target='__blank' key={index}>
                    {item.title}
                </a> 
            )
        }
       
        var nav_link = item.link;

        return (
            <a onClick={()=>HandleBurger(nav_link)} key={item.id}>
                {item.title}
            </a>
        )
    })

    return (

        <a className={(isActive ? 'active' : '')} style={{fontSize: link_size}}> 

            {(isHomeIcon && link === '/') ? (
                <i className="fa fa-home" onClick={()=>HandleBurger(final_uri)} />
            ) : (
                <>
                    <span>{nav_text}</span>
                    <strong onClick={()=>HandleBurger(final_uri)}>
                        {title}

                        {dropdown_items.length > 0 && (
                            <i className="fa fa-chevron-down" />
                        )}
                    </strong>
                </>
            )}

            {dropdown_items.length > 0 && (
                <div className="nav-links-dropdown">
                    {dropdown_items}
                </div>
            )}
        </a>
        
    )
}

const getHeaderStyles = (currentpage, header_background_color, header_text_color, header_layout) => {

    var header_styles = {
        background: header_background_color,
        color: header_text_color
    }

    var header_transparent = false;

    var page_settings = currentpage.settings || {};
    var block_transparent = page_settings.transparent ? true : false;

    if(currentpage){
        var page_layout = currentpage.page_layout;
        console.log("IS TRANSPARENT ====> ", page_layout, header_layout);

        var available_page_layouts = ['home-4', 'home-5', 'home-7', 'article-3', 'article-8', 'contact-4', 'menu-5', 'cover-4'];
        var exepted_nav_layouts = ['nav-2', 'nav-6'];

        var isAvailablePage = available_page_layouts.includes(page_layout);
        var isExeptedNav = exepted_nav_layouts.includes(header_layout);

        console.log("IS TRANSPARENT ===> ", page_layout, header_layout, block_transparent);

        if(isAvailablePage && !isExeptedNav && block_transparent){
            // header_styles = {
            //     background: 'transparent!important',
            //     color: '#ffffff!important'
            // }
            header_transparent = true;
        }
    }

    return {header_styles, header_transparent };
}

const getSocialMediaArray = (socialmedia) =>{
 
    var contact_types = Object.keys(socialmedia);

    var socialmedia_array = [];

    for(var i= 0; i<contact_types.length; i++){

        var contact_type = contact_types[i];
        var contact_value = socialmedia[contact_type];

        if(contact_value.length>0){

            if(contact_type === 'whatsapp'){

                var phone_number = contact_value.replace(/\D/g,'');
                socialmedia_array.push({
                    type: contact_type,
                    url:'https://wa.me/'+phone_number
                });
            }
            else{
                socialmedia_array.push({
                    type: contact_type,
                    url: contact_value
                });
            }
           
        }

        if(i==contact_types.length-1){
            return socialmedia_array;
        }

    }

}

const mapStateToProps = ({ mode_preview }) => {

    return {
       
        navigation: mode_preview.navigation,
        socialmedia: mode_preview.socialmedia,
        header: mode_preview.header,
        cart: mode_preview.cart,
        store_settings: mode_preview.style.store || {}

    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        handleFullwebsite_Cart
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)

