import React from 'react';
import {Helmet} from "react-helmet";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {forceMagicSSL} from '../../../../helpers/GeneralHelper';



class Header extends React.Component{

    constructor(props) {
        super(props);
    
    }


    render(){

        var {basic_info, currentpage} = this.props;   
        
        //Website Elements
        var web_title = basic_info.title;
        var web_description = basic_info.description;
        var web_icon = forceMagicSSL(basic_info.icon);
        var web_favicon = forceMagicSSL(basic_info.favicon) || forceMagicSSL(basic_info.icon);
		var web_cover = forceMagicSSL(basic_info.cover);
        var web_url =  'https://'+(basic_info.customdomain ? basic_info.customdomain : basic_info.subdomain+'.myclick.me');
        var web_keywords = basic_info.keywords || [];

		//Page Elements
        var page_title = currentpage.title;
        var page_type = currentpage.type;
		var page_route = currentpage.route;
		var page_metadata = currentpage.page_metadata || {};

		//Metadata
		var meta_title = ''
		var meta_description = '';
		var meta_keywords =  '';
		var meta_img = '';
		var meta_url = '';

        if(page_route === '/'){

            var alternative_title = ((page_type !== 'blank' && page_title) ? page_title : web_description);

            meta_title = web_title + ' | ' + alternative_title;
            meta_description = web_description || '';
            meta_keywords = web_keywords.join(',') || '';
            meta_img = web_cover || '';
            meta_url = web_url;
        }
        else {

            var alternative_route = page_route ? page_route.replace('-', ' ') : '';
            var alternative_title = ((page_type !== 'blank' && page_title) ? page_title : capitalizeFirstLetter(alternative_route));
            var alternative_meta_title = (page_metadata.title ? page_metadata.title : alternative_title);
           
            var meta_keywords = page_metadata.keywords || [];
            var alternative_meta_keywords = meta_keywords.join(',') || '';
            var alternative_image = forceMagicSSL(page_metadata.img) || '';

            meta_title = web_title + ' | ' + alternative_meta_title;
            meta_description = page_metadata.description || web_description;
            meta_keywords = alternative_meta_keywords || [];
            meta_img = alternative_image || web_cover;
            meta_url = web_url+'/'+ (page_route !== '/' ? page_route : '');
        }

	
        return (

            <Helmet>
        
                
                <meta charset="utf-8" />
                <meta http-equiv="x-ua-compatible" content="ie=edge" />

                <title>{meta_title}</title>

                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />

                <link rel="canonical" href={meta_url} />
                <link rel="alternate" hreflang="x-default" href={meta_url} />
                
                <meta name="keywords" content={meta_keywords} />
            
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image:alt" content={meta_description} />
                <meta property="og:url" content={meta_url} />
                <meta property="og:type" content="website" />
                <meta property="og:image" itemprop="image" content={meta_img}/>
                <meta property="fb:app_id" content="339720479912263" />
              
                <meta name="twitter:site" content={web_title} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:creator" content={web_title} />
           
                <link rel="apple-touch-icon" sizes="180x180" href={web_icon} />
                <link rel="icon" type="image/png" sizes="32x32" href={web_favicon} />
                <link rel="icon" type="image/png" sizes="16x16" href={web_favicon} />
                <link rel="manifest" href="/assets/img/favicon/site.webmanifest" />
                <link rel="mask-icon" href="/assets/img/favicon/safari-pinned-tab.svg" color="#5bbad5" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
        
  
            </Helmet>

        )
    }
}

const capitalizeFirstLetter = (string) =>{
    if(!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const mapStateToProps = ({ mode_preview }) => {

    return {
        basic_info: mode_preview.basic_info
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
     

    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)

