import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { pickTextColorBasedOnBgColorAdvanced } from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Page_Settings from '../general/page_settings';

import Moment from 'react-moment'

class Layout_Timeline extends React.Component {

    constructor(props) {
        super(props);   

        this.state={
        }
    }

    componentDidMount(){
        this.props.HandleBodyClasses(this.props.currentpage);
    }

    componentDidUpdate(newProps){

        var newPage = newProps.currentpage.route;
        var oldPage = this.props.currentpage.route;

        if(newPage !== oldPage){
            this.props.HandleBodyClasses(this.props.currentpage);
        }
    }
    
    render(){
          
        var {currentpage, currentblocks} = this.props;
        var {title, description, title_html, description_html, page_bj_color, page_layout} = currentpage;
        
        //Page Settings
        var block_class = Page_Settings(currentpage);

        //New Edits
        var background_color = page_bj_color || '#ffffff';
        var text_color = pickTextColorBasedOnBgColorAdvanced(page_bj_color, '#FFFFFF', '#000000');
        
        var general_styles = {
            color: text_color,
            background: background_color
        }
       
        
        var timeline_array = currentpage.timeline_array || [];
        var timeline_items = timeline_array.map((item, index)=> {
            return (
                <Timeline_Card key={index} payload={item} index={index}/>
            )
        })

        return(

            <>  
                <div className={block_class} id="experiencePage" style={general_styles}>

                    <div className="title-set">
                        <Block_Textbox type="page_title" content={title} content_html={title_html} />  
                        <Block_Textbox type="page_description" content={description} content_html={description_html} />
                    </div>
                     
                    <div className="experience-blocks">

                        {timeline_items.length == 0 ? (

                             <Timeline_Placeholder onAddTimeline={this.onAddTimeline} layout={page_layout} />

                        ) : (
                            timeline_items
                        )}
                            

                    </div>

                </div>
                
                {currentblocks}
            </>

        )
    }
};


const Timeline_Card = ({payload, index}) =>{

    var {from,to,title,subtitle,location,description, format} = payload;
    
    var timeline_class = (index % 2)==0 ? '': 'reverse';

    var timeline_subtext = '';
    if(subtitle && location){
        timeline_subtext = subtitle + ' • ' + location;
    }
    else{

        if(subtitle){
            timeline_subtext = subtitle;
        }

        if(location){
            timeline_subtext = location;
        }
    }
  
    return (

        <div className={"experience-block "+timeline_class}>

            <Timeline_Date format={format || 'month_year'} from={from} to={to} />

            <div className="description">
                <h4 className="job-title">{title}</h4>
                {(subtitle || location) && (                    
                    <span> {timeline_subtext}</span> 
                )}
                <p>{description}</p>
            </div>
        </div>


    )
}


const Timeline_Date = ({format, from, to}) => {

    if(format === 'month_year'){
        
        return (
            <div className="date">
                <Moment date={from} format="MMMM YYYY"/> – {to ? <Moment date={to} format="MMMM YYYY"/> : 'Present'}
            </div>
        )
    }
    else if(format === 'day_month_year'){

        return (
            <div className="date">
                <Moment date={from} format="DD MMMM YYYY"/> – {to ? <Moment date={to} format="DD MMMM YYYY"/> : 'Present'}
            </div>
        )
    }
    else if(format === 'day_month_time'){

        return (
            <div className="date">
                <Moment date={from} format="MMMM Do - HH:mm"/> – {to ? <Moment date={to} format="MMMM Do - HH:mm"/> : 'Present'}
            </div>
        )
    }

}


const Timeline_Placeholder = ({layout}) => {

 
    return (

        <>
            <div className="experience-block">    
                <div className="date">JANUARY 2019 – PRESENT</div>
                <div className="description">
                    <h4 className="job-title">Design Director</h4>
                    <span>GOOGLE LLC • MOUNTAIN VIEW - CA</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                </div>
            </div>

            <div className={"experience-block "+(layout === 'experience-1' ? 'reverse' : '')}>  
                <div className="date">JANUARY 2018 – DECEMBER 2018</div>
                <div className="description">
                    <h4 className="job-title">Creative Director</h4>
                    <span>APPLE INC • CUPERTINO - CA</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                </div>
            </div>

            <div className="experience-block">    
                <div className="date">MAY 2017 – DECEMBER 2017</div>
                <div className="description">
                    <h4 className="job-title">UI/UX Expert</h4>
                    <span>MICROSOFT CORPORATION • SEATTLE - WA</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = ({ mode_preview }) => {

    return {
       
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
  
    }, dispatch)
  }
export default connect(mapStateToProps, mapDispatchToProps)(Layout_Timeline)
