import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Block_Textbox from '../general/block_textbox';
import Block_Button from '../general/block_button';
import Page_Settings from '../general/page_settings';
import {Video_Frame, Image_Frame} from '../general/page_media';

import {pickTextColorBasedOnBgColorAdvanced} from '../../../../../helpers/GeneralHelper';

class Layout_Home extends React.Component {

    constructor(props) {
        super(props);   
    }

    componentDidMount(){
        this.props.HandleBodyClasses(this.props.currentpage);
    }

    componentDidUpdate(newProps){

        var newPage = newProps.currentpage.route;
        var oldPage = this.props.currentpage.route;

        if(newPage !== oldPage){
            this.props.HandleBodyClasses(this.props.currentpage);
        }
    }

    render(){

        var {currentpage, currentblocks} = this.props;
        var {title, description, title_html, description_html, page_action_button, page_content_url, page_content_type, page_bj_color, slider} = currentpage;
        
        //Page Settings
        var block_btn = page_action_button || {}; 
        var block_class = Page_Settings(currentpage);

        //New Edits
        var background_color = page_bj_color || '#ffffff';
        var text_color = pickTextColorBasedOnBgColorAdvanced(page_bj_color, '#FFFFFF', '#000000');
        var general_styles = {
            color: text_color,
            background: background_color
        }

        var isVideo = page_content_type === 'video';
        var isImage = page_content_type === 'image';

        var page_settings = currentpage.settings || {};
        
        
        return(

            <>

                <div className={block_class} id="homePage" style={general_styles}>
                    
                    <div className="home-info">
                        <div className="home-text">
                            <div className="home-text-title">
                             <Block_Textbox type="page_title" content={title} content_html={title_html} />     
                            </div>
                            <div className="home-description"> 
                                <Block_Textbox type="page_description" content={description} content_html={description_html} />  
                                <Block_Button block_btn={block_btn} />
                            </div>
                        </div>
                    </div>
                    
                    <div className="home-photos" id="slider_cover">
                        {isImage && (
                            slider[0] && <Image_Frame image_url={slider[0]} settings={page_settings} className="home-page-hero-image" />
                        )}
                        {isVideo && (
                           <Video_Frame video_url={page_content_url} settings={page_settings} />
                        )}
                    </div>
                        
                </div>

                {currentblocks}
       
            </>

        )
    }
};



const mapStateToProps = ({ mode_preview }) => {

    return {
       
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
  }
export default connect(mapStateToProps, mapDispatchToProps)(Layout_Home)
