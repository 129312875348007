import React from 'react';

import {pickTextColorBasedOnBgColorAdvanced} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Background from '../general/block_background';
import Block_Button from '../general/block_button';
import Block_Settings from '../general/block_settings';

class Block_Text extends React.Component {

    constructor(props) {
        super(props);   
    }

    render(){

        var currentblock = this.props.currentblock || {};
        var {title, description, title_html, description_html, layout, bg_color, bg_image} = currentblock;
        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');

        var block_btn = currentblock.block_btn || {}; 
        var block_class = Block_Settings(currentblock);
       
        return(

            <div className={block_class} style={{background: bg_color, color: text_color}}>

               <div className={"design-block-text "+layout}>
                    
                    <div className="design-block-text-left">
        
                        <Block_Textbox type="block_title" content={title} content_html={title_html} />  
                                          
                    </div>

                    <div className="design-block-text-right">

                       <Block_Textbox type="block_description" content={description} content_html={description_html} />         
                       <Block_Button type='block' block_btn={block_btn} />
                                  
                    </div>
     
                </div>
                
                {bg_image && <Block_Background bg_image={bg_image} />}
               
            </div>
        )

    }
};



export default Block_Text
