import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {HandleGalleryModal} from '../../../../../actions/mode_preview';

import { pickTextColorBasedOnBgColorAdvanced} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Background from '../general/block_background';
import Block_Button from '../general/block_button';
import Block_Settings from '../general/block_settings';

import { Link } from 'react-router-dom';


const AVAILABLE_LAYOUTS = ['layout-7', 'layout-8', 'layout-9'];
 

class Block_Gallery extends React.Component {

    constructor(props) {
        super(props);   

        this.state = {
            isReordering : false,
            slide_index: 0,
            auto_show: true
        }
         
        this.onSelectPhoto = this.onSelectPhoto.bind(this);

        this.onNextSlide = this.onNextSlide.bind(this);
        this.onBackSlide = this.onBackSlide.bind(this);
        this.onAutoShow = this.onAutoShow.bind(this);
        this.onStopShow = this.onStopShow.bind(this);

    }

    componentDidMount(){

        var {currentblock} = this.props;
        var block_layout = currentblock.layout;

        if(AVAILABLE_LAYOUTS.includes(block_layout)){

            var block_settings = currentblock.settings || {};

            var block_autoplay = block_settings.autoplay || false;
            var block_timer = block_settings.timer || 5000;

            if(block_autoplay){
                
                this.interval = setInterval(() => {
                  this.onAutoShow();
                  this.setState({auto_show: true})
                },	 		
                block_timer);

            }

        }

    }

    componentWillUnmount() {
  
      clearInterval(this.interval);
    
    }


    onSelectPhoto(index){

        var {currentblock} = this.props;
        var slideshow_items = currentblock ? currentblock.slider : [];

        if(slideshow_items.length > 0){
          document.body.classList.add('gallery-modal-active');
          this.props.HandleGalleryModal(true, slideshow_items, index);
        }
       
    }

   
    //SlideShow
    onNextSlide(){
        var {slide_index} = this.state;
        var {currentblock} = this.props;
        var gallery_items = currentblock.slider || [];

        var newGallery = slide_index;

        if(slide_index == gallery_items.length-1){
          newGallery = 0;
        }
        else{
          newGallery = slide_index+1;
        }

        this.setState({slide_index: newGallery});
        clearInterval(this.interval);
    }

    onBackSlide(){
        var {slide_index} = this.state;
        var {currentblock} = this.props;
        var gallery_items = currentblock.slider || [];

        var newGallery = slide_index;

        if(slide_index == 0){
          newGallery = gallery_items.length-1;
        }
        else{
          newGallery = slide_index-1;
        }

        this.setState({slide_index: newGallery});
        clearInterval(this.interval);
    }

    onAutoShow(){
        
        var {slide_index} = this.state;
        var {currentblock} = this.props;
        var gallery_items = currentblock.slider || [];
        var newGallery = slide_index;

        if(slide_index == gallery_items.length-1){
          newGallery = 0;
        }
        else{
            newGallery = slide_index+1;
        }

        this.setState({slide_index: newGallery});
    }

    onStopShow(){
      console.log("STOPING SHOW");
      this.setState({auto_show: false});
      clearInterval(this.interval);
    }


    render(){
        
      var {slide_index} = this.state;

      var {currentblock} = this.props;
      var {title, description, title_html, description_html, layout, bg_color, bg_image} = currentblock;
      
        
      var block_settings = currentblock.settings || {};

      var slider_array = currentblock.slider || [];
      var slider_items = slider_array.map((item, index)=> {

          var isActive = (AVAILABLE_LAYOUTS.includes(layout) && slide_index == index);

          return (
             <Card_Element key={index} payload={item} 
                              onSelectPhoto={this.onSelectPhoto} 
                              index={index} 
                              isActive={isActive}
                              block_settings={block_settings}
                />
          )
      })
    
      var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');

      //Block Settings
      var block_btn = currentblock.block_btn || {}; 
      var block_class = Block_Settings(currentblock);

      
      
      return(

              <div className={block_class} style={{background: bg_color, color: text_color}}>
                    
                    <div className={"design-block-gallery "+layout}>
                          
                          <div className="title-set">
                              <Block_Textbox type="block_title" content={title} content_html={title_html} />  
                              <Block_Textbox type="block_description" content={description} content_html={description_html} />
                              <Block_Button type='block' block_btn={block_btn} /> 
                          </div>
                          
                          <div className="design-block-gallery-thumbs">
                            
                            {AVAILABLE_LAYOUTS.includes(layout) && (
                              <div className="design-block-gallery-nav">
                                {slider_items.length > 1 ? <span className="prev" onClick={()=>this.onBackSlide()}><i className="fa fa-chevron-left" /></span> : null}
                                {slider_items.length > 1 ? <span className="next" onClick={()=>this.onNextSlide()}><i className="fa fa-chevron-right" /></span> : null}
                              </div>
                            )}
                            
                            {slider_items.length == 0 ? (
                                <div className="design-block-feature-placeholder-wrapper">
                                    <div className="design-block-feature-placeholder"></div>
                                    <div className="design-block-feature-placeholder"></div>
                                    <div className="design-block-feature-placeholder"></div>
                                </div>

                            ) : (
                              slider_items
                            )}

                          
                          </div>

                    </div>

                    {bg_image && <Block_Background bg_image={bg_image} />}
                  
                </div>
        )
    }
};


class Card_Element extends React.Component {

    constructor(props){
      super(props);

    }


    render(){

        var {payload, index, onSelectPhoto, isActive, block_settings} = this.props;

        var image_value = payload.img ? payload.img : payload;
        var image_link = payload.url ? payload.url : '';

        var block_modal = block_settings.modal || false;

        if(block_modal){
            return (
              <div className={"design-block-gallery-item "+(isActive ? 'active' : '')}>
                  <img src={image_value} className="design-block-gallery-image"  onClick={()=> onSelectPhoto(index)}/>
              </div>
            )
        }

        if(!image_link){
            return (
              <div className={"design-block-gallery-item "+(isActive ? 'active' : '')}>
                  <img src={image_value} className="design-block-gallery-image"/>
              </div>
            )
        }


        if(image_link.includes('https://') || image_link.includes('http://')){

              return (
                <a className={"design-block-gallery-item "+(isActive ? 'active' : '')} href={image_link}>
                    <img src={image_value} className="design-block-gallery-image" />
                </a>
              )
          }
          else{

              return (
                <Link className={"design-block-gallery-item "+(isActive ? 'active' : '')} to={image_link}>
                    <img src={image_value} className="design-block-gallery-image" />
                </Link>
              )
          }

    }
  
}


const mapStateToProps = ({ mode_preview }) => {
  return {
    
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      HandleGalleryModal

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Block_Gallery)
