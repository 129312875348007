import React from 'react';
import { Link } from 'react-router-dom';
import {isURL} from '../../../../../helpers/GeneralHelper';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Block_Button extends React.Component {

    constructor(props) {
        super(props);   

        this.onScrollBlock = this.onScrollBlock.bind(this);
    }

    onScrollBlock(url){

        var block_id = url.replace('#', 'bk');
        const element = document.getElementById(block_id);

        console.log("WANT TO ACCESS URL ===> ", url);

        if(element){
            const yOffset = -10; 
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});

            // element.scrollIntoView(true);
            // element.scrollTop -= 10;
           // element.scrollIntoView({ block: 'center',  behavior: 'smooth', inline: 'center' });
        }
        else{
           // alert('block not found = '+ url);
        }
       
    }

    render(){
          
        
            var {subtype} = this.props;
          
            var block_btn = this.props.block_btn || {};

            var button_color = block_btn.button_color || '#000000';
            var button_text_color = block_btn.button_text_color || '#ffffff';
            var button_border_color = block_btn.button_border_color || '';
           
            var button_arrow = block_btn.button_arrow ? 'button-arrow' : '';
            var button_transparent = block_btn.button_transparent ? 'button-transparent' : '';
            var button_shadow = block_btn.button_shadow ? 'button-shadow' : '';
            var button_hidden =  block_btn.button_hidden ? 'button-hidden' : '';

            if (Object.keys(block_btn).length == 0) {
                button_hidden = 'button-hidden';
            }

            var button_font = this.props.style.font_button || '';

            //Button Border
            var border_inline = button_border_color ? 'solid 1px '+button_border_color : 'none';

            //Button Styling 
            var button_text = block_btn.button_title || '';
            var button_type = block_btn.button_type || '';
            var button_size = block_btn.button_size || 'medium';

            if(subtype === 'form'){
                button_text = button_text || 'SUBMIT FORM';
                button_type = button_type || 'btn-square';
                button_hidden = false;
                
            }
            else if(subtype === 'contact'){
                button_text = button_text || 'SEND MESSAGE'
                button_type = button_type || 'btn-square';
                button_hidden = false;
            }
            else if(subtype === 'signup'){
                button_text = button_text || 'SIGN UP'
                button_type = button_type || 'btn-square';
                button_hidden = false;
            }
            else {
                button_text = button_text || 'EXPLORE'
                button_type = button_type || 'btn-circle';
            }


            //Setup Styling
            var button_class = [
                'explore-btn',
                button_arrow,
                button_transparent,
                button_shadow,
                button_type,
                button_hidden,
                button_font,
                'btn-'+button_size
            ].join(' ');


            //Check if External URL 

            var button_url = block_btn.button_action || '#';
            var isExternalUrl = isURL(button_url);

            //var button_url = '#bkVHQBRR9ycP';

            if(subtype === 'form' || subtype === 'contact' || subtype === 'signup'){

                var {isSending, onSendForm} = this.props;
                var not_allowed_btn = { cursor: 'not-allowed',  background: 'linear-gradient(-135deg, #ccc 0%, #ccc 100%)'}
    
                if(isSending){
                    return (
                        <a className={button_class} style={{ color: '#ffffff', ...not_allowed_btn}}>
                            <i className="fa fa-spinner fa-spin" style={{marginRight: '10px', display: 'inherit'}}/>
                        </a>
                    )
                }
                return (
                    <a onClick={()=>onSendForm()}
                        className={button_class} 
                        style={{ background: button_color, color: button_text_color, border: border_inline}}>
                        {button_text}
                        <i className="fa fa-arrow-right"></i>
                    </a>
                )
                
            }
        

            if(isExternalUrl){

                return (
                    <a href={button_url}
                        target={'__blank'}
                        className={button_class} 
                        style={{ background: button_color, color: button_text_color, border: border_inline}}>
                        {button_text}
                        <i className="fa fa-arrow-right"></i>
                    </a>
                )
               
            }
            else{

                if(button_url.includes('#')){
                    return(
                        <a  onClick={()=>this.onScrollBlock(button_url)}
                            className={button_class} 
                            style={{ background: button_color, color: button_text_color, border: border_inline}}>
                            {button_text}
                            <i className="fa fa-arrow-right"></i>
                        </a>
                    )
                }

                return(
                    <Link to={button_url || '#'}
                        className={button_class} 
                        style={{ background: button_color, color: button_text_color, border: border_inline}}>
                        {button_text}
                        <i className="fa fa-arrow-right"></i>
                    </Link>
                )
            }
         
    }
    
};
 

const mapStateToProps = ({ mode_preview }) => {

    return {
        style: mode_preview.style
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
  
    }, dispatch)
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Block_Button)



