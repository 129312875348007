

import React from 'react';
import axios from 'axios';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {ShowCheckout, RemoveFromCart, ResetCart} from '../../../../../actions/mode_preview';
import {forceSSL, validateEmail} from '../../../../../helpers/GeneralHelper';

import StripeCheckout from '../../../Store/StripeCheckout';
import { CountriesList } from '../../../Lists/list_countries';

import NumberFormat from 'react-number-format';

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  DateTimePicker
} from "@material-ui/pickers";

import frLocale from "date-fns/locale/fr";
import itLocale from "date-fns/locale/it";
import esLocale from "date-fns/locale/es";
import usLocale from "date-fns/locale/en-US";

import {addDays} from "date-fns";


const ValidateForms = (state, checkout) =>{

    //Inputs
    var customer = state.customer || {};
    var shipping = state.shipping || {};
    var delivery = state.delivery || {};
    var additional = state.additional || {};
    var terms = state.terms || false;

    //Conditions

    var additional_info = checkout.additional_info || {};
    var additional_dic = {
        status: additional_info.status || false,
        required: additional_info.required || false,
    }

    var delivery_info = checkout.delivery_info || {};
    var delivery_dic = {
        status: delivery_info.status || false,
        required: delivery_info.required || false
    }

    
    var hasBilling = checkout.billing_info || false;
    var hasShipping = checkout.shipping_info || false;
    var hasTerms = checkout.terms_info || false;
    var hasDelivery = delivery_dic.status || false;
    var hasAdditional = additional_dic.status || false;

    console.log("hasBilling ===> ", hasBilling);
    console.log("hasShipping ===> ", hasShipping);
    console.log("hasDelivery ===> ", hasDelivery);
    console.log("hasTerms ===> ", hasDelivery);
    console.log("hasAdditional ===> ", hasDelivery);


    //CUSTOMER ---> REQUIRED
    if(!(validateEmail(customer.email))){
        return false;
    }

    if(!customer.firstname){
        return false;
    }

    if(!customer.lastname){
        return false;
    }

    //TERMS ---> 
    if(hasTerms){
        if(!terms){
            return false;
        }
    }

    //BILLING ---> 
    if(hasBilling){

        if(!customer.address){
            return false;
        }
    
        if(!customer.city){
            return false;
        }

        if(!customer.country){
            return false;
        }

        if(!customer.zip){
            return false;
        }
    }


    //SHIPPING ---> 
    if(hasShipping){

        if(!shipping.address){
            return false;
        }
    
        if(!shipping.city){
            return false;
        }

        if(!shipping.country){
            return false;
        }

        if(!shipping.zip){
            return false;
        }
    }


    //DELIVERY --->
    if(hasDelivery){
        if(!delivery.date){
            return false
        }
    }

    //ADDITIONAL --->
    if(hasAdditional){
        var isRequired = additional_dic.required || false;
        if(isRequired){
            if(!additional.message){
                return false
            }
        }
    }

    return true;
}


class Checkout_Cart extends React.Component {

    constructor(props) {
        super(props);  
        
        this.state={
            status: false,
            isSending: false,
            orderId: '',

            customer: {},
            shipping: {},
            delivery: {},
            additional: {},
            terms: false,

            hasShipping: false,
            hasPayment: false,
            hasNote: false,
            shippingCost: {}
        }

        this.PaymentMethodButton = this.PaymentMethodButton.bind(this);
        this.HandleCheckoutLoading = this.HandleCheckoutLoading.bind(this);

        this.onChangeForm = this.onChangeForm.bind(this);
        this.onSendOrder = this.onSendOrder.bind(this);
        this.onSelectCountries = this.onSelectCountries.bind(this);

        this.onPixelPayInfo = this.onPixelPayInfo.bind(this);
        this.onPixelPurchase = this.onPixelPurchase.bind(this);
        
    }

    componentDidMount(){

        document.body.style.overflow = 'hidden';

        var {cart, web_note, web_shipping, web_pay} = this.props;

        var hasShipping = false;
        var hasPayment = web_pay ? true : false;
        var hasNote = web_note ? true : false;
    
        for(var i=0; i<cart.length; i++){
            var product_obx = cart[i];
            var product_type = product_obx.type;
            if(product_type === 'physical'){
              hasShipping = true;
            }
        }

        var firstShipping = web_shipping[0] ? web_shipping[0] : '';
        var firstRegion = firstShipping ? firstShipping.region : '';
        var CountryIndex = firstRegion ? CountriesList.findIndex((item)=> item.value === firstRegion.toUpperCase()) : -1;
        var CountryMatched = CountryIndex != -1 ? CountriesList[CountryIndex] : {};

        this.setState({
          hasShipping, 
          hasPayment, 
          hasNote, 
          customer: {
            ...this.state.customer, 
            country: CountryMatched.label || CountriesList[0].label 
          },
          shipping: {
            ...this.state.shipping, 
            country: CountryMatched.label || CountriesList[0].label 
          }
        });

    }

    onChangeForm(e, type, subtype){

        if(type === 'delivery'){
            var delivery = this.state.delivery || {};
            return this.setState({delivery: {...delivery, date: e}});
        }

        var event_value = e.target.value ? e.target.value.trim() : e.target.value;
        this.setState({[type]: {...this.state[type], [subtype]: event_value}});
    }

    onSelectCountries(e, type){
      const country = e.target.value;
      this.setState({[type]: {...this.state[type], country: country}});
    }

    onSendOrder(){

        var {cart, web_id} = this.props;

        console.log("SENDING FORM ===> ", this.state); 

        this.setState({isSending:true, orderId: ''});


        const url = '/api/v1/builder/websites/orders/create';
      
        var form = {
            id: web_id,
            cart: cart,
            customer : this.state.customer,
            shipping: this.state.shipping,
            delivery: this.state.delivery,
            additional: this.state.additional,
            order_type: 'offline'
        }


        axios.post(url ,form).then(response => {
            
            console.log("DATA FROM SERVER = ",response.data);
            this.setState({
                isSending:false, 
                orderId: response.data.order_id,
                customer: {},
                shipping: {},
                delivery: {},
                additional: {},
                terms: false,
            
            });
            this.props.ResetCart();

        })
        .catch(error => {
            console.log(error);
            this.setState({isSending:false, orderId: ''});
        });
        
    }

    PaymentMethodButton(status){
        this.setState({status})
    }

    HandleCheckoutLoading(state, status){
      this.setState(state)

      if(status === 'reset'){
        this.props.ResetCart();
      
      }
    }

    onPixelPayInfo(){

        //  //Pixel
        //  var {isFbPixel, web_pixel, web_currency, cart} = this.props;

        //  if(isFbPixel && web_pixel){
 
        //      var num_ids = [];
        //      var num_total = 0;
        //      for(var i=0; i<cart.length; i++){
        //          var cart_item = cart[i];
 
        //          var item_id = cart_item.id;
        //          var item_quantity = cart_item.quantity;
        //          var item_total = cart_item.sale ? cart_item.sale : cart_item.cost;
        //          num_total = num_total + (item_total *item_quantity) ;
 
        //          num_ids.push(item_id);
        //      }
 
        //      var pixel_data = {
        //          content_ids: num_ids,
        //          content_type: 'product',
        //          value: num_total,
        //          currency: web_currency 
        //      }
 
        //      console.log("Pixel Data ===> ", pixel_data, num_total, num_ids);
        //      fbq('track', 'AddPaymentInfo', pixel_data);  
         
        //  }
    }

    onPixelPurchase(){

        //Pixel
        // var {isFbPixel, web_pixel, web_currency, cart} = this.props;

        // if(isFbPixel && web_pixel){

        //     var num_items = cart.length;
        //     var num_ids = [];

        //     var num_total = 0;
        //     for(var i=0; i<cart.length; i++){
        //         var cart_item = cart[i];

        //         var item_id = cart_item.id;
        //         var item_quantity = cart_item.quantity;
        //         var item_total = cart_item.sale ? cart_item.sale : cart_item.cost;
        //         num_total = num_total + (item_total *item_quantity) ;

        //         num_ids.push(item_id);
        //     }

        //     var pixel_data = {
        //         num_items: num_items, 
        //         content_ids: num_ids,
        //         content_type: 'product',
        //         value: num_total,
        //         currency: web_currency 
        //     }

        //     console.log("Pixel Data ===> ", pixel_data, num_total, num_ids);
        //     fbq('track', 'Purchase', pixel_data);  
        
        // }
    }

    render(){
        
        var {customer, shipping, delivery, additional, terms, isSending, orderId} = this.state;
        var {cart, web_symbol, web_currency, web_shipping, web_id, basic_info, web_icon, web_lang} = this.props;

        var cart_items = cart.map((item, index)=> {
            return (
              <Cart_Cell key={index} payload={item} web_symbol={web_symbol} />
            )
        } );
       
        var web_shipping = web_shipping || [];
        var filtred_countries = web_shipping.length != 0 ? getFilterCountries(web_shipping) : CountriesList;

        var selected_country = shipping.country ? shipping.country : filtred_countries[0];
        var checkout_dic = CalculateCheckout(cart, web_shipping, selected_country);
        
        console.log("CHECKOUT DICTIONARY = ", checkout_dic);


        //Form Order

        var {store_settings, web_checkout, web_pay} = this.props;
        var cta_color = store_settings.extra_color || '#38964c';

        var PayButtonStyle = {
            border: '1px #d9d9d9 solid',
            backgroundClip: 'padding-box',
            borderRadius: '5px',
            outline: 'none!important',
            width: '100%',
            padding: '0.92857em 0.78571em',
            wordBreak: 'normal',
            fontSize: '14px',
            background: cta_color
        }

        var checkout = web_checkout || {};
        var hasBilling = checkout.billing_info || false;
        var hasShipping = checkout.shipping_info || false;
        var hasTerms = checkout.terms_info || false;

        //Extra
        var additional_info = checkout.additional_info || {};
        var additional_dic = {
            status: additional_info.status || false,
            required: additional_info.required || false,
            label: additional_info.label || getTranslatedCheckout('additional_information', web_lang),
            placeholder: additional_info.placeholder || 'Write a custom message to join your order'
        }

        var delivery_info = checkout.delivery_info || {};
        var delivery_dic = {
            ...delivery_info,
            status: delivery_info.status || false,
            required: delivery_info.required || false,
            label: delivery_info.label || getTranslatedCheckout('delivery_information', web_lang),
            placeholder: delivery_info.placeholder || '',
            type: delivery_info.type || 'date_time'
        }

        var hasDelivery = delivery_dic.status || false;
        var hasAdditional = additional_dic.status || false;
        
        var isValid = ValidateForms(this.state, checkout);


        var localeProvider = usLocale;
        if(web_lang === 'fr'){
            localeProvider = frLocale
        }
        else if(web_lang === 'it'){
            localeProvider = itLocale
        }
        else if(web_lang === 'es'){
            localeProvider = esLocale
        }



        return(

            <>
                  <div className='modal side active' id="checkoutModal">
                    <div className="modal-content">

                        <div className="close" id="closeModal" onClick={()=>this.props.ShowCheckout(false)}></div>

                        <Cart_Block cart_items={cart_items} checkout_dic={checkout_dic} {...this.state} {...this.props} />
                        
                        
                        <div className='checkout-form active' id="shippingDetails">

                            <h2>{getTranslatedCheckout('your_information', web_lang)}</h2>
                            <div className="checkout-form-group">	
                                <input placeholder={getTranslatedForm('your_email', web_lang)} type="email" onChange={(e)=>this.onChangeForm(e, 'customer', 'email')}/>
                            </div>
                            <div className="checkout-form-group row">	
                                <input placeholder={getTranslatedForm('first_name', web_lang)} type="text" onChange={(e)=>this.onChangeForm(e, 'customer', 'firstname')}/>
                                <input placeholder={getTranslatedForm('last_name', web_lang)} type="text" onChange={(e)=>this.onChangeForm(e, 'customer', 'lastname')}/>
                            </div>

                            <div className="checkout-form-group">	
                                <input placeholder={getTranslatedForm('phone', web_lang)} onChange={(e)=>this.onChangeForm(e, 'customer', 'phone')}/>
                            </div>

                            {hasBilling && (
                                <>
                                    <h2>{getTranslatedCheckout('billing_information', web_lang)}</h2>
                                    <div className="checkout-form-group">	
                                        <input placeholder={getTranslatedForm('address', web_lang)} onChange={(e)=>this.onChangeForm(e, 'customer', 'address')}/>
                                    </div>
                                    <div className="checkout-form-group">	
                                        <input placeholder={getTranslatedForm('city', web_lang)} onChange={(e)=>this.onChangeForm(e, 'customer', 'city')}/>
                                    </div>
                                    <div className="checkout-form-group row">	
                                        <DropdownCountries
                                                onChange={this.onSelectCountries}
                                                defaultOption="Country"
                                                countries={filtred_countries} 
                                                current_country= {customer ? customer.country : ''} 
                                                subtype={'customer'}  
                                        />
                                        <input placeholder={getTranslatedForm('zip_code', web_lang)} onChange={(e)=>this.onChangeForm(e, 'customer', 'zip')}/>
                                    </div>
                                </>
                            )}

                            {hasShipping && (
                                <>
                                    <h2>{getTranslatedCheckout('shipping_information', web_lang)}</h2>
                                    <div className="checkout-form-group">	
                                        <input placeholder={getTranslatedForm('address', web_lang)} onChange={(e)=>this.onChangeForm(e, 'shipping', 'address')}/>
                                    </div>
                                    <div className="checkout-form-group">	
                                        <input placeholder={getTranslatedForm('city', web_lang)} onChange={(e)=>this.onChangeForm(e, 'shipping', 'city')}/>
                                    </div>
                                    <div className="checkout-form-group row">	
                                        <DropdownCountries
                                                onChange={this.onSelectCountries}
                                                defaultOption="Country"
                                                countries={filtred_countries} 
                                                current_country= {shipping ? shipping.country : ''}
                                                subtype={'shipping'}     
                                        />
                                        <input placeholder={getTranslatedForm('zip_code', web_lang)} onChange={(e)=>this.onChangeForm(e, 'shipping', 'zip')}/>
                                    </div>
                                   
                                </>
                            )}

                            {hasDelivery && (
                                <>
                                    <h2>{delivery_dic.label}</h2>
                                    <div className="checkout-form-group">	
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} style={{zIndex : 2223}} locale={localeProvider}>
                                            <DateTimeSelector delivery_dic={delivery_dic} 
                                                              type={delivery_dic.type} 
                                                              onChangeForm={this.onChangeForm} 
                                                              value={delivery.date || null} 
                                                              ampm={web_lang === 'en'}
                                            />    
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </>
                            )}

                            {hasAdditional && (
                                <>
                                    <h2>{additional_dic.label}</h2>
                                    <div className="checkout-form-group">	
                                        <textarea placeholder={additional_dic.placeholder} onChange={(e)=>this.onChangeForm(e, 'additional', 'message')}/>
                                    </div>
                                </>
                            )}
                        
                            {hasTerms && (
                                <div className="checkout-terms" style={{marginTop: '30px'}}>
                                    <input type="checkbox" checked={terms} onChange={()=>this.setState({terms: !terms})}/>
                                    <Terms_Component web_lang={web_lang} HandleBodyLegal={this.props.HandleBodyLegal}  />
                                </div>
                            )}
                            

                            {web_pay ? (

                                <>
                                    <StripeCheckout 
                                        basic_info={basic_info}
                                        web_id={web_id} 
                                        cart={cart} 
                                        customer={customer} 
                                        shipping={shipping}
                                        delivery={delivery}
                                        additional={additional}
                                        stripeAccount={web_pay} 

                                        amount_total={checkout_dic.amount_total} 
                                        currency_code={web_currency}
                                        HandleCheckoutLoading={this.HandleCheckoutLoading}
                                        web_lang={web_lang}
                                        btn_style = {PayButtonStyle}
                                        btn_title = {getTranslatedCheckout('pay_with_card', web_lang)}
                                        isValid={isValid}

                                        onPixelPayInfo={this.onPixelPayInfo}
                                        onPixelPurchase={this.onPixelPurchase}
                                    />

                                    <div className="stripe-secure-payment">
                                        <img src="/assets/img/secure_payment.png" />
                                    </div>
                                </>
                            ) : (

                                <>
                                    {isValid ? (
                                        <div className="button-set" style={{marginTop: '20px'}} onClick={()=>this.onSendOrder()}>
                                            <div className="cart-button" style={PayButtonStyle} >
                                            {getTranslatedCheckout('place_order', web_lang)}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="button-set" style={{marginTop: '20px'}}>
                                            <div className="cart-button" style={{...PayButtonStyle, background: 'linear-gradient(-135deg, #a1a7a3 0%, #b9c3be 100%)', cursor: 'not-allowed'}}>
                                            {getTranslatedCheckout('place_order', web_lang)}
                                            </div>
                                        </div>
                                    )}
                                    

                                </>
                            )}

                            
                            
                        
                        </div>
                        

                    </div>
                  </div>
                 
                  {isSending && (

                    <div className="modal active" id="orderConfirm">
                          <div className="modal-content">
                                <div className="placing-order-flex">
                                  <div className="page-loader">
                                    <img className="loader-logo" src={forceSSL(web_icon)} />
                                  </div>
                                  <h4>{getTranslatedConfirmation('placing_order', web_lang)}</h4>
                                  <p>{getTranslatedConfirmation('take_couple_seconds', web_lang)} </p>
                                </div>
                          </div>
                      </div>

                  )}

                  {orderId && (

                    <div className="modal active" id="orderConfirm">
                          <div className="modal-content">
                              <img className="order-confirm" src={forceSSL(web_icon)} style={{width: '30%', marginTop: '50px', borderRadius: '20px'}}/>
                              <div className="close" id="closeModal" onClick={()=>this.props.ShowCheckout(false)}></div>
                                  <h4>{getTranslatedConfirmation('order_thanks', web_lang)}</h4>
                                  <p>{getTranslatedConfirmation('order_email_confirmation', web_lang)}
                                      <br /><br />
                                      <span>{getTranslatedConfirmation('order_number', web_lang)}</span> 
                                      <strong>{orderId ? orderId.toUpperCase() : orderId}</strong>
                                  </p>
                                  <div className="continue-btn" onClick={()=>{this.setState({orderId: '', isSending: false }); this.props.ShowCheckout(false)}}>Back to Home</div>
                              
                          </div>
                      </div>
                  )}
                  
              

            </>

        )
    }
};

//LEFT
const Cart_Block = ({cart_items, checkout_dic, hasShipping, hasNote, web_note, web_symbol, web_currency, web_lang}) =>{
    
  var {amount_total, amount_subtotal, amount_tax, amount_shipping, amount_discounted, amount_weight} = checkout_dic;

  var cart_discount = amount_discounted ? amount_discounted*100/amount_subtotal : 0; 

  return (

      <div className="checkout-cart">
          <h1>{getTranslatedCheckout('cart_summary', web_lang)}</h1>
          
          {cart_items}

          <div className="cart-totals-table">
              <div className="row">
                  <div className="label">{getTranslatedCheckout('subtotal', web_lang)}</div>
                  <div className="amount">
                      <Checkout_Price prefix={web_symbol} suffix={web_currency} value={amount_subtotal} />
                  </div>
              </div>
              
              {amount_discounted > 0 && (
                  <div className="row">
                      <div className="label">{getTranslatedCheckout('discount', web_lang)} ({parseInt(cart_discount)}%)</div>
                      <div className="amount" style={{color: 'red'}}>
                        - <Checkout_Price prefix={web_symbol} suffix={web_currency} value={amount_discounted} />
                      </div>
                  </div>
              )}
             
              {(hasShipping && amount_shipping > 0) && (
                  <div className="row">
                     <div className="label">{getTranslatedCheckout('shipping', web_lang)} ({amount_weight} kg)</div>
                     <div className="amount">
                       <Checkout_Price prefix={web_symbol} suffix={web_currency} value={amount_shipping} />
                    </div>
                  </div>
              )}

              {(hasShipping && amount_shipping == 0) && (
                  <div className="row">
                     <div className="label">{getTranslatedCheckout('shipping', web_lang)}</div>
                     <div className="amount">
                       {getTranslatedCheckout('free', web_lang)}
                    </div>
                  </div>
              )}
             
              {amount_tax > 0 && (
                <div className="row">
                    <div className="label">Tax</div>
                    <div className="amount">
                       <Checkout_Price prefix={web_symbol} suffix={web_currency} value={amount_tax} />
                    </div>
                </div>
              )}
              
              <div className="row large">
                  <div className="label">{getTranslatedCheckout('total', web_lang)}</div>
                  <div className="amount">
                    <Checkout_Price prefix={web_symbol} suffix={web_currency} value={amount_total} />
                  </div>
              </div>
          </div>

          {(hasNote && hasShipping) && (
            <div className="shipping-well">
                <h4>{getTranslatedCheckout('checkout_note', web_lang)}</h4>
                <p>{web_note}</p>
            </div>
          )}
          
      </div>

  )

}

const Cart_Cell = ({web_symbol, payload}) => {
     
      var title = payload.title || '';
      var icon = payload.icon || '';
      var quantity = payload.quantity;
      var cost = payload.cost;
      var option = payload.option;
      var sale = payload.sale;

      var total_full_cost = cost*quantity;
      var total_sale_cost = sale*quantity;
      
      return (
          <a className="cart-product">
              <img src={forceSSL(icon)} />

              <div className="cart-product-right">
              <h4>{title}</h4>
            
                   {total_sale_cost ? (
                        <div className="cart-product-price" style={{display: 'flex'}}>
                                <i> 
                                  <Checkout_Price prefix={web_symbol} value={total_sale_cost} /> 
                                </i>
                                <span className="cart-product-old-price">                        
                                  <i> 
                                    <Checkout_Price prefix={web_symbol} value={total_full_cost} /> 
                                  </i>
                                </span>
                        </div>
                      
                    ) : (
                      <div className="cart-product-price" style={{display: 'flex'}}>
                          <i> 
                            <Checkout_Price prefix={web_symbol} value={total_full_cost} /> 
                          </i>
                      </div>
                        
                    )}

              </div>
              <div className="cart-product-footer">
                        {option && <span>Option: {option}</span>}
                        <span>QTY: {quantity}</span>
                    </div>
          </a>      
      )

}

const CalculateCheckout = (cart, shipping, selected_country) => {

  var checkout_dic = {
    amount_total: 0,
    amount_subtotal: 0,
    amount_tax: 0,
    amount_shipping: 0,
    amount_discounted: 0,
    amount_weight: 0
  }

  //Fetch Current Shipping

  var shipping_products = cart.filter((item)=> item.type === 'physical') || [];
  var shipping_weight = shipping_products.map((item)=> item.weight ? item.quantity*item.weight : 0).reduce((a, b) => a + b, 0) || 0;
  
  console.log("WEIGHT SHIPPING = ", shipping_weight);

  var amount_shipping = getShippingCost(shipping_weight, shipping, selected_country) || 0;

  console.log("AMOUNT = ", amount_shipping);

  if(cart.length != 0){
  
      var amount_total = 0;
      var amount_subtotal = 0;
      var amount_tax = 0;
      var amount_discounted = 0;
      var amount_weight = 0;
      
      
      for(var i=0; i<cart.length; i++){
          var cart_item = cart[i];
          var price_sale = cart_item.sale ? cart_item.sale : 0;
          var price_list = cart_item.cost ? cart_item.cost : 0;
          var product_tax = cart_item.tax ? parseFloat(cart_item.tax) : 0;
          var product_weight = cart_item.weight ? parseFloat(cart_item.weight) : 0;
          var product_quantity = cart_item.quantity ? parseFloat(cart_item.quantity) : 1;

          var total_full_cost = price_list*product_quantity;
          var total_sale_cost = price_sale*product_quantity;
          var total_per_item = total_sale_cost ? total_sale_cost : total_full_cost;

          
          amount_discounted = amount_discounted + (total_sale_cost ? total_full_cost-total_sale_cost : 0);
          amount_subtotal = amount_subtotal + total_full_cost;

          if(product_tax){
            var product_taxation = product_tax * total_per_item/100;
            amount_tax = amount_tax + product_taxation;

            console.log("TAX ==== ", product_tax, product_taxation, amount_tax);
          }
         
          if(product_weight){
            amount_weight = amount_weight + (product_weight*product_quantity);
          }

          if(i== cart.length-1){

            //Calculating  

            amount_total = amount_subtotal + amount_tax + amount_shipping - amount_discounted;
          
            checkout_dic = {
              ...checkout_dic,
              amount_total,
              amount_subtotal,
              amount_tax,
              amount_shipping,
              amount_discounted,
              amount_weight
            }
          }
      }

  }

  return checkout_dic;
}

const Checkout_Price = ({prefix, suffix, value}) => {

  return (
      <NumberFormat displayType="text" 
                    thousandSeparator={true} 
                    decimalScale={2} 
                    fixedDecimalScale={2}
                    prefix={prefix}
                    suffix={suffix ? ' '+suffix : ''}  
                    value={value} 
                    renderText={(form_value)=><>{form_value}</>}
      />
  )
}


const Terms_Component = ({web_lang, HandleBodyLegal}) => {


    if(web_lang === 'it'){
        return <div>Accetto i <a onClick={()=>HandleBodyLegal('terms')}>Termini e Condizioni</a> e la <a onClick={()=>HandleBodyLegal('privacy')}>Politica sulla riservatezza</a></div>
    }
    else if(web_lang === 'fr'){
        return <div>J'accepte les <a onClick={()=>HandleBodyLegal('terms')}>Termes et conditions</a> et la <a onClick={()=>HandleBodyLegal('privacy')}>Politique de confidentialité</a></div>
    }
    else if(web_lang === 'es'){
        return <div>Acepto los <a onClick={()=>HandleBodyLegal('terms')}>Términos y condiciones</a> y la <a onClick={()=>HandleBodyLegal('privacy')}>Política de privacidad</a></div>
    }

    return <div>I accept <a onClick={()=>HandleBodyLegal('terms')}>Terms and conditions</a> and <a onClick={()=>HandleBodyLegal('privacy')}>Privacy policy</a></div>
}

const DropdownCountries = ({onChange, defaultOption, countries, current_country, subtype}) => {
    return (
      <div className="form-group dd-group">
        <div className="field">
          <select
            onChange={(e)=>onChange(e, subtype)}
            className="form-control dd-select">
            <option disabled hidden>{defaultOption}</option>
            {countries.map((country) => {
              return <option key={country.value} selected={country.label === current_country ? true : false}>{country.label}</option>;
            })}
          </select>
        </div>
      </div>
    );
};


const getFilterCountries = (shipping) =>{

  if(!shipping){
     return CountriesList;
  }

  var WorldIndex = shipping.findIndex((item)=> item.region ==='world');
  if(WorldIndex != -1){
    return CountriesList;
  }

  var final_array = [];

  for(var i=0; i<CountriesList.length; i++){
    var country_obx = CountriesList[i];
    var country_code = country_obx.value;

    var indexOfCode = shipping.findIndex((item)=> (item.region === country_code.toLowerCase()));
    if(indexOfCode != -1){
      //Not Found in Shipping Array --> Add Country to Filtred Array
      final_array.push(country_obx);
    }

  }

  return final_array;
 
}

const getShippingCost = (shipping_weight, shipping, selected_country) =>{

  console.log("AMOUNT = ", shipping_weight,shipping, selected_country);

  if(!shipping || (shipping && shipping.length == 0)){
    return 0;
  }

  if(shipping_weight == 0 || !selected_country){
      return 0;
  }

  var WorldIndex = shipping.findIndex((item)=> item.region ==='world');
  if(WorldIndex != -1){
    var region_obx = shipping[WorldIndex] || {};
    var ratesArray = region_obx.rates || [];
  
    if(ratesArray.length == 0){
      return 0;
    }
  
    var lastRate = ratesArray[ratesArray.length-1];
    var matchedIndex = ratesArray.findIndex((item)=> shipping_weight<item.max && shipping_weight >item.min);
    var matchedRate = matchedIndex != -1 ? ratesArray[matchedIndex] : lastRate;
    var matchedCost = matchedRate.cost || 0;
  
    return parseFloat(matchedCost);
  }

  var countryIndex = CountriesList.findIndex((item)=> item.label === selected_country);
  var country_obx = countryIndex != -1 ? CountriesList[countryIndex] : '';

  if(!country_obx){
    return 0;
  }

  var country_code = country_obx.value || '';
  var regionIndex = shipping.findIndex((item)=> item.region === country_code.toLowerCase());
  var region_obx = regionIndex != -1 ? shipping[regionIndex] : '';

  var ratesArray = region_obx.rates || [];

  if(ratesArray.length == 0){
    return 0;
  }

  var lastRate = ratesArray[ratesArray.length-1];
  var matchedIndex = ratesArray.findIndex((item)=> shipping_weight<item.max && shipping_weight >item.min);
  var matchedRate = matchedIndex != -1 ? ratesArray[matchedIndex] : lastRate;
  var matchedCost = matchedRate.cost || 0;

  return parseFloat(matchedCost);


}

const DateTimeSelector = ({delivery_dic, type, onChangeForm, value, ampm}) => {

    var form_object = delivery_dic || {};
    var placeholder = form_object.placeholder;

    var form_min = form_object.min;
    var form_max = form_object.max;
    var form_min_type = form_object.min_type || 'fixed';
    var form_max_type = form_object.max_type || 'fixed';
    var form_min_days = form_object.min_days || 0;
    var form_max_days = form_object.max_days || 0;

    //Setup Min Date
    var minDate = (form_min && form_min !== 'Invalid Date') ? new Date(form_min) : ''
    if(form_min_type === 'dynamic'){
        if(minDate){
            minDate = form_min_days ? addDays(new Date(), form_min_days) : new Date();
        }
    }

    //Setup Max Date
    var maxDate = (form_max && form_max !== 'Invalid Date') ? new Date(form_max) : new Date('2099-31-12');
    if(form_max_type === 'dynamic'){
        if(maxDate){
            maxDate = form_max_days ? addDays(new Date(), form_max_days) : new Date()
        }
    }

    if(type === 'date'){
        return ( 
      
            <DatePicker value={value}  onChange={(newValue)=>onChangeForm(newValue, 'delivery', 'date')}
                        format='dd MMMM yyyy'
                        minDate={minDate}
                        maxDate={maxDate}
                        emptyLabel={placeholder}
                        minDateMessage={placeholder}
                        maxDateMessage={placeholder}
                       
            />
         
        )
    }

    if(type === 'date_time'){
        return ( 
            <DateTimePicker value={value}  onChange={(newValue)=>onChangeForm(newValue, 'delivery', 'date')}
                            format='dd MMM yyyy - HH:mm' variant='dialog' 
                            showTodayButton id="modal_dp" hideTabs 
                            minDate={minDate}
                            maxDate={maxDate}
                            emptyLabel={placeholder}
                            minDateMessage={placeholder}
                            maxDateMessage={placeholder}
                            ampm={ampm}
                           
            />
        )
    }
    
   
}

const getTranslatedConfirmation = (type, lang) => {
  
    var tr = '';
  
    if(type === 'order_thanks'){
        switch (lang) {
            case 'fr':
                tr = 'Merci pour votre commande!'
                break;
            case 'it':
                tr = 'Grazie per il vostro ordine!'
                break;
            case 'es':
                tr = '¡Gracias por su orden!'
                break;
            default:
                tr = 'Thank you for your order!'
                break;
        }
    }
    else if(type === 'order_email_confirmation'){
        switch (lang) {
            case 'fr':
                tr = 'Nous vous enverrons sous peu un e-mail de confirmation avec les détails de votre commande.'
                break;
            case 'it':
                tr = 'Ti invieremo a breve un\'e-mail di conferma con i dettagli dell\'ordine.'
                break;
            case 'es':
                tr = 'En breve le enviaremos un correo electrónico de confirmación con los detalles de su pedido.'
                break;
            default:
                tr = 'We will send you a confirmation email shortly with your order details.'
                break;
        }
    }
    else if(type === 'order_number'){
        switch (lang) {
            case 'fr':
                tr = 'N ° de commande. '
                break;
            case 'it':
                tr = 'Numero d\'ordine. '
                break;
            case 'es':
                tr = 'N º de pedido. '
                break;
            default:
                tr = 'Order No. '
                break;
        }
    } 
    else if(type === 'placing_order'){
        switch (lang) {
            case 'fr':
                tr = 'Traitement de votre commande ...'
                break;
            case 'it':
                tr = 'Elaborazione del tuo ordine ...'
                break;
            case 'es':
                tr = 'Procesando su pedido ...'
                break;
            default:
                tr = 'Placing your order...'
                break;
        }
    } 
    else if(type === 'take_couple_seconds'){
        switch (lang) {
            case 'fr':
                tr = 'Cela prendra quelques secondes.'
                break;
            case 'it':
                tr = 'Questo richiederà un paio di secondi.'
                break;
            case 'es':
                tr = 'Esto tardará unos segundos.'
                break;
            default:
                tr = 'This will take a couple of seconds.'
                break;
        }
    } 
    
  
    return tr
  
  }

const getTranslatedCheckout = (type, lang) => {
  
  var tr = '';

  if(type === 'cart_summary'){
      switch (lang) {
          case 'fr':
              tr = 'Résumé du panier'
              break;
          case 'it':
              tr = 'Riepilogo carrello'
              break;
          case 'es':
              tr = 'Resumen de la compra'
              break;
          default:
              tr = 'Cart Summary'
              break;
      }
  }
  else if(type === 'free'){
      switch (lang) {
          case 'fr':
              tr = 'GRATUIT'
              break;
          case 'it':
              tr = 'GRATIS'
              break;
          case 'es':
              tr = 'GRATUITA'
              break;
          default:
              tr = 'FREE'
              break;
      }
  }
  else if(type === 'subtotal'){
      switch (lang) {
          case 'fr':
              tr = 'Totale Partiéle'
              break;
          case 'it':
              tr = 'Totale Parziale'
              break;
          case 'es':
              tr = 'Total Parcial'
              break;
          default:
              tr = 'Subtotal'
              break;
      }
  } 
  else if(type === 'total'){
      switch (lang) {
          case 'fr':
              tr = 'TOTALE'
              break;
          case 'it':
              tr = 'TOTALE'
              break;
          case 'es':
              tr = 'TOTAL'
              break;
          default:
              tr = 'TOTAL'
              break;
      }
  } 
  else if(type === 'shipping'){
      switch (lang) {
          case 'fr':
              tr = 'Expédition'
              break;
          case 'it':
              tr = 'Consegna'
              break;
          case 'es':
              tr = 'Costo Envío'
              break;
          default:
              tr = 'Shipping'
              break;
      }
  } 
  else if(type === 'your_information'){
      switch (lang) {
          case 'fr':
              tr = 'Vos Informations'
              break;
          case 'it':
              tr = 'Le tue Informazioni'
              break;
          case 'es':
              tr = 'Sus Informaciones'
              break;
          default:
              tr = 'Your Information'
              break;
      }
  } 
  else if(type === 'billing_information'){
      switch (lang) {
          case 'fr':
              tr = 'Détails de Facturation'
              break;
          case 'it':
              tr = 'Dati di Fatturazione'
              break;
          case 'es':
              tr = 'Datos de Facturación'
              break;
          default:
              tr = 'Billing Information'
              break;
      }
  } 
  else if(type === 'shipping_information'){
      switch (lang) {
          case 'fr':
              tr = 'Lieu de Livraison'
              break;
          case 'it':
              tr = 'Luogo della consegna'
              break;
          case 'es':
              tr = 'Información de Envío'
              break;
          default:
              tr = 'Shipping Information'
              break;
      }
  } 
  else if(type === 'delivery_information'){
      switch (lang) {
          case 'fr':
              tr = 'Date de Livraison'
              break;
          case 'it':
              tr = 'Data di Consegna'
              break;
          case 'es':
              tr = 'Data de Entrega'
              break;
          default:
              tr = 'Date of Delivery'
              break;
      }
  } 
  else if(type === 'additional_information'){
      switch (lang) {
          case 'fr':
              tr = 'Information additionnelle'
              break;
          case 'it':
              tr = 'Informazioni aggiuntive'
              break;
          case 'es':
              tr = 'Información adicional'
              break;
          default:
              tr = 'Additional Information'
              break;
      }
  } 
  else if(type === 'pay_with_card'){
      switch (lang) {
          case 'fr':
              tr = 'Payer par Carte'
              break;
          case 'it':
              tr = 'Paga con Carta'
              break;
          case 'es':
              tr = 'Pagar con Tarjeta'
              break;
          default:
              tr = 'Pay with Card'
              break;
      }
  } 
  else if(type === 'place_order'){
      switch (lang) {
          case 'fr':
              tr = 'Passer la Commande'
              break;
          case 'it':
              tr = 'Invia Ordine'
              break;
          case 'es':
              tr = 'Realizar Pedido'
              break;
          default:
              tr = 'Place Order'
              break;
      }
  } 
  else if(type === 'checkout_note'){
      switch (lang) {
          case 'fr':
              tr = 'Note de paiement'
              break;
          case 'it':
              tr = 'Nota di checkout'
              break;
          case 'es':
              tr = 'Nota de pago'
              break;
          default:
              tr = 'Checkout Note'
              break;
      }
  } 
  else if(type === 'discount'){
      switch (lang) {
          case 'fr':
              tr = 'Rabais'
              break;
          case 'it':
              tr = 'Risparmi'
              break;
          case 'es':
              tr = 'Descuento'
              break;
          default:
              tr = 'Discount'
              break;
      }
  } 
  

  return tr

}

const getTranslatedForm = (type, lang) => {
  
  var tr = '';

  if(type === 'your_email'){
      switch (lang) {
          case 'fr':
              tr = 'Ton adresse Email'
              break;
          case 'it':
              tr = 'Il tuo indirizzo Email'
              break;
          case 'es':
              tr = 'Tu correo electronico'
              break;
          default:
              tr = 'Your Email'
              break;
      }
  }
  else if(type === 'first_name'){
      switch (lang) {
          case 'fr':
              tr = 'Prénom'
              break;
          case 'it':
              tr = 'Nome'
              break;
          case 'es':
              tr = 'Nombre'
              break;
          default:
              tr = 'First Name'
              break;
      }
  }
  else if(type === 'last_name'){
      switch (lang) {
          case 'fr':
              tr = 'Nom'
              break;
          case 'it':
              tr = 'Cognome'
              break;
          case 'es':
              tr = 'Apellido'
              break;
          default:
              tr = 'Last Name'
              break;
      }
  } 
  else if(type === 'address'){
      switch (lang) {
          case 'fr':
              tr = 'Adresse'
              break;
          case 'it':
              tr = 'Indirizzo'
              break;
          case 'es':
              tr = 'Dirección'
              break;
          default:
              tr = 'Address'
              break;
      }
  } 
  else if(type === 'city'){
      switch (lang) {
          case 'fr':
              tr = 'Ville'
              break;
          case 'it':
              tr = 'Città'
              break;
          case 'es':
              tr = 'Ciudad'
              break;
          default:
              tr = 'City'
              break;
      }
  } 
  else if(type === 'zip_code'){
      switch (lang) {
          case 'fr':
              tr = 'Code Postal'
              break;
          case 'it':
              tr = 'CAP'
              break;
          case 'es':
              tr = 'Código postal'
              break;
          default:
              tr = 'ZIP Code'
              break;
      }
  } 
  else if(type === 'phone'){
      switch (lang) {
          case 'fr':
              tr = 'Téléphone'
              break;
          case 'it':
              tr = 'Telefono'
              break;
          case 'es':
              tr = 'Teléfono'
              break;
          default:
              tr = 'Phone'
              break;
      }
  } 
  

  return tr

}



const mapStateToProps = ({ mode_preview }) => {

    return {
        cart : mode_preview.cart,
        web_id: mode_preview.basic_info.id,
        basic_info: mode_preview.basic_info,

        web_icon: mode_preview.basic_info.icon,
        web_symbol: mode_preview.store.symbol,
        web_currency: mode_preview.store.currency,
        web_shipping: mode_preview.store.shipping || [],
        web_note: mode_preview.store.note,
        web_pay: mode_preview.store.pay,
        web_checkout: mode_preview.store.checkout || {},

        web_pixel: mode_preview.basic_info.facebook_pixel,

        store_settings: mode_preview.style.store || {},

    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        ShowCheckout,
        RemoveFromCart,
        ResetCart
  
    }, dispatch)
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Checkout_Cart)


