import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Block_Textbox from '../general/block_textbox';
import Page_Settings from '../general/page_settings';

import { pickTextColorBasedOnBgColorAdvanced } from '../../../../../helpers/GeneralHelper';

import {Image_Frame} from '../general/page_media';

class Layout_MenuList extends React.Component {
 
    constructor(props) {
        super(props);   

    }

    componentDidMount(){
        var {currentpage} = this.props;
        this.props.HandleBodyClasses(currentpage);
    }

    componentDidUpdate(newProps){

        var newPage = newProps.currentpage.route;
        var oldPage = this.props.currentpage.route;

        if(newPage !== oldPage){
             this.props.HandleBodyClasses(this.props.currentpage);
        }
    }


  
    render(){

        
        var {currentpage, currentblocks} = this.props;
        var {title, description, title_html, description_html, page_bj_color, page_content_url, page_layout} = currentpage;
        
        //Page Settings
        var block_class = Page_Settings(currentpage);

        //New Edits
        var background_color = page_bj_color || '#ffffff';
        var text_color = pickTextColorBasedOnBgColorAdvanced(page_bj_color, '#FFFFFF', '#000000');
        var general_styles = {
            color: text_color,
            background: background_color
        }


        var page_settings = currentpage.settings || {};

        var list_array = currentpage.list_array || [];
        var list_items = list_array.map((item, index) => {

            return (
                <Menu_Card key={index} payload={item} 
                            index={index}
                            page_settings={page_settings}
                            block_image={(page_layout === 'menu-3' || page_layout === 'menu-4')}
                />
            )
        });

        return (
        
            <>
                <div className={block_class} id="menuContent" style={general_styles}>
                
                    <div className="menu-content">

                        <div className="menu-title">
                           
                            <Block_Textbox type="page_title" content={title} content_html={title_html} />  
                            <Block_Textbox type="page_description" content={description} content_html={description_html} />  
                            
                            {page_layout === 'menu-5' && (
                                <Image_Frame image_url={page_content_url} settings={page_settings} className="menu-list-hero" />
                            )}
                            
                        </div>

                        <div className="menu-blocks">

                            {list_items.length == 0 ? (

                                <h1> There is no Menu or Pricing on this page</h1>

                            ) : (
                                list_items
                            )}

                        </div>
                        
                    </div>
                 
                </div>

                {currentblocks}
            </>
              

        )
    }
};

const Menu_Card = ({index, payload, page_settings, block_image}) => {

    var {img, description, title} = payload;
    var price_list = payload.list || [];


    var block_title_size = page_settings.title_size || 32;
    var block_description_size = page_settings.description_size || 18;
    var block_subtitle_size = page_settings.subtitle_size || 18;
    var block_extra_size = page_settings.extra_size || 18;


    var block_title_color = page_settings.title_color || '#000000';
    var block_description_color = page_settings.description_color || '#000000';
    var block_subtitle_color = page_settings.subtitle_color || '#000000';
    var block_extra_color = page_settings.extra_color || '#000000';



    return (
        <>
            {block_image  && (
                <div className="menu-image">
                    <img src={img} alt={title} />
                </div>
            )}
            <div className="menu-block">
                <h2 className="menu-block-title" style={{fontSize: block_title_size+'px', color: block_title_color}}>{title}</h2>
                <div className="menu-table">
                    <div className="menu-block-description" style={{fontSize: block_description_size+'px', color: block_description_color, whiteSpace: 'pre-wrap'}}>
                        {description}
                    </div>

                    {
                        price_list.map((price, i) => {
                            return (
                                <Menu_Prices key={i} price={price} master_color={block_subtitle_color} master_size={block_subtitle_size} price_color={block_extra_color} price_size={block_extra_size} />
                            )
                        })
                    }

                </div>
            </div>
        </>
    )
}

const Menu_Prices = ({price, master_color, master_size, price_color, price_size}) => {

    var item_splited = price.split("|---|");
    var item_master = item_splited[0];
    var item_detail = item_splited[1];


    return (
        <div className="row">
            <strong style={{fontSize: master_size+'px', color: master_color}}>{item_master}</strong>
            <span style={{fontSize: price_size+'px', color: price_color}}>{item_detail}</span>
        </div>
    )
}



const mapStateToProps = () => {

    return {
       
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
  
    }, dispatch)
  }
export default connect(mapStateToProps, mapDispatchToProps)(Layout_MenuList)
