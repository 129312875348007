import React from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {pickTextColorBasedOnBgColorAdvanced} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Background from '../general/block_background';
import Block_Button from '../general/block_button';
import Block_Settings from '../general/block_settings';

class Block_Features extends React.Component {

    constructor(props) {
        super(props);   

    }

   
    render(){


        var {currentblock} = this.props;
        var {title, description, title_html, description_html, layout, bg_color, bg_image} = currentblock;

        var features_items = currentblock.features || []; 
        var block_settings = currentblock.settings || {};
        var features_items_array = features_items.map((item, i)=> {

            return (
                <Feature_Card key={i} 
                              payload={item} 
                              index={i} 
                              layout={layout} 
                              block_settings={block_settings}
                />
            )

        });

        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');

        //Block Settings
        var block_btn = currentblock.block_btn || {}; 
        var block_class = Block_Settings(currentblock);

        var block_settings = currentblock.settings || {};
        var block_columns = block_settings.columns || '';

        
        return(

            <div className={block_class} style={{background: bg_color, color: text_color}} >
                
                 <div className={"design-block-features "+layout} >

                     <div className="design-block-title">
                        <Block_Textbox type="block_title" content={title} content_html={title_html} />  
                        <Block_Textbox type="block_description" content={description} content_html={description_html} />
                        <Block_Button type='block' block_btn={block_btn} /> 
                    </div>
                      
                    <div className={"features-block-boxes "+(block_columns || '')}>
                        {features_items_array}
                    </div>
                    
                </div>

                {bg_image && <Block_Background bg_image={bg_image} />}

            </div>
               

        )
    }
};


const Feature_Card = ({payload, index, block_settings, layout}) => {

    var {title, description, image, link, alt} = payload;

    //Alt
    var block_alt = alt;

    var kind = payload.kind || 'image';
    var icon_file = payload.icon || 'fa fa-tint';
    var icon_size = payload.icon_size || 50;
    var icon_color = payload.icon_color || '#000000';

    //Optional

    var title_size = block_settings.title_size || 28;
    var description_size = block_settings.description_size || 18;

    var title_color = block_settings.title_color || '#000000';
    var description_color = block_settings.description_color || '#000000';

    //Exeptions
    var Can_Show_Numbers = (layout === 'layout-1' || layout === 'layout-8');
    var Can_Show_Image = (layout !== 'layout-1' && layout !== 'layout-6' && layout !== 'layout-8');
    
    //Layout Combination
    var align_extra = block_settings.align_extra || '';    
    var Final_Box_Style = getGridBoxStyle(layout, align_extra);

    //Card Type
    if(!link){
        return (
            <div className="features-block-box" style={Final_Box_Style}>
                {Can_Show_Numbers && (
                    <Grid_Number index={index} align_extra={align_extra} />
                )}
                {Can_Show_Image && (
                    <Grid_Image kind={kind} icon_size={icon_size} icon_color={icon_color} icon_file={icon_file} image={image} layout={layout} align_extra={align_extra} alt={block_alt}/>
                )}
                <Grid_Content title_size={title_size} title_color={title_color} description_size={description_size} description_color={description_color} title={title} description={description} layout={layout} align_extra={align_extra} />
            </div>
        )
    } 

    if(link.includes('https://') || link.includes('http://')){
        return (
            <a className="features-block-box" style={Final_Box_Style} href={link}>
                {Can_Show_Numbers && (
                    <Grid_Number index={index} align_extra={align_extra} />
                )}
                {Can_Show_Image && (
                    <Grid_Image kind={kind} icon_size={icon_size} icon_color={icon_color} icon_file={icon_file} image={image} layout={layout} align_extra={align_extra} alt={block_alt}/>
                )}
                <Grid_Content title_size={title_size} title_color={title_color} description_size={description_size} description_color={description_color} title={title} description={description} layout={layout} align_extra={align_extra} />
            </a>
        )
    }
    else{
        return (
            <Link className="features-block-box" style={Final_Box_Style} to={link}>
                {Can_Show_Numbers && (
                    <Grid_Number index={index} align_extra={align_extra} />
                )}
                {Can_Show_Image && (
                    <Grid_Image kind={kind} icon_size={icon_size} icon_color={icon_color} icon_file={icon_file} image={image} layout={layout} align_extra={align_extra} alt={block_alt}/>
                )}
                <Grid_Content title_size={title_size} title_color={title_color} description_size={description_size} description_color={description_color} title={title} description={description} layout={layout} align_extra={align_extra} />
            </Link>
        )
    }
        
    
}


const Grid_Number = ({index, align_extra}) => {

    if(align_extra === 'center'){
        return (
            <div className="number" style={{marginBottom: '15px'}}>0{index+1}</div>
        )
    }
    else if(align_extra === 'right'){
        return (
            <div className="number" style={{marginLeft: '15px'}}>0{index+1}</div>
        )
    }
    else {
        return (
            <div className="number">0{index+1}</div>
        )
    }
   
}

const Grid_Image = ({kind, icon_size, icon_color, icon_file, image, align_extra, layout, alt }) => {

    if(layout === 'layout-3' || layout === 'layout-9'){
        return (
            <div className="features-block-image">
                <img src={image} alt={alt} />
            </div>
        )
    }

    if(kind === 'image'){
        return (
            <div className="features-block-image">
                <img src={image} alt={alt} />
            </div>
        )
    }
    else {

        var final_size = icon_size + 20;
        var final_align = align_extra;
        var final_margin = {};

        if(final_align === 'right'){
            final_align = 'flex-end';
            final_margin = (layout === 'layout-7') ? {marginRight: '0', marginLeft: '30px'} : {};
        }
        else if(final_align === 'left'){
            final_align = 'flex-start';
            final_margin = (layout === 'layout-7') ? {marginRight: '30px', marginLeft: '0'} : {};
        }
        else{
            final_margin = (layout === 'layout-7') ? {marginRight: '0', marginLeft: '0'} : {};
        }
       
        return (
            <div className="features-block-image" style={{height: final_size+'px', ...final_margin}}>
                <div className={icon_file} style={{fontSize: icon_size+'px', color: icon_color, justifyContent: final_align}}></div>
            </div>
        )

    }
    
}

const Grid_Content = ({title_size, title_color, description_size, description_color, title, description, layout, align_extra}) =>{

    var marginTop = (layout === 'layout-1') ? '0px' : '20px';

    return (
        <div className="features-block-description">
            <h4 style={{fontSize: title_size+'px', color: title_color, marginTop: marginTop, textAlign: align_extra}}>{title}</h4>
            <p  style={{fontSize: description_size+'px', color: description_color, whiteSpace: 'pre-wrap', textAlign: align_extra}}>{description}</p>
        </div>
    )
}


const getGridBoxStyle = (layout, align)=> {

    if(layout === 'layout-1'){

        if(align === 'center'){
            return {flexDirection: 'column', alignItems: 'center'}
        }
        else if(align === 'right'){
            return {flexDirection: 'row-reverse'};
        }
    }
    else if(layout === 'layout-7'){

        if(align === 'center'){
            return {flexDirection: 'column', alignItems: 'center'}
        }
        else if(align === 'right'){
            return {flexDirection: 'row-reverse'};
        }
    }
    else if(layout === 'layout-8'){

        if(align === 'center'){
            return {textAlign: 'center'}
        }
        else if(align === 'right'){
            return {textAlign: 'right'};
        }
    }
}



const mapStateToProps = ({ mode_preview }) => {

    return {
      
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
       
  
    }, dispatch)
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Block_Features)
