import React from 'react';
import Iframe from 'react-iframe'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions_PreviewMode from '../../../../../actions/mode_preview';

import {pickTextColorBasedOnBgColorAdvanced} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Background from '../general/block_background';
import Block_Button from '../general/block_button';
import Block_Settings from '../general/block_settings';

class Block_Music extends React.Component {

    constructor(props) {
        super(props);   
    }

    componentDidMount(){

       
    }

    render(){

        var {currentblock} = this.props;
        var {title, description, title_html, description_html, layout,bg_color,bg_image} = currentblock;

        var music_items = currentblock.music || [];

        var songs_list_items = music_items.map((item, index) => <Music_Card key={index} payload={item} index={index} HandleEditMusic={this.HandleEditMusic} HandleDeleteMusic={this.HandleDeleteMusic} />);

        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');


        var block_btn = currentblock.block_btn || {}; 
        var block_class = Block_Settings(currentblock);

        return(

               
               <div className={block_class} style={{background: bg_color, color: text_color}}>
                
                    <div className={"design-block-music "+layout}>

                            <div className="design-block-music-title">
                                <Block_Textbox type="block_title" content={title} content_html={title_html} />  
                                <Block_Textbox type="block_description" content={description} content_html={description_html} />
                                <Block_Button type='block' block_btn={block_btn} /> 
                            </div>
                        
                            {songs_list_items}
                    </div>  

                    {bg_image && <Block_Background bg_image={bg_image} />}
                
            </div>


        )
    }
};


const Music_Card = (props) =>{

    var {payload } = props;
    var {type, value} = payload;

    var iframe_link = '';

    if(type === 'spotify'){
        
        iframe_link = value;
    }
    else if(type === 'soundcloud'){

        iframe_link = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/"+value+"&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
    }

    return (

        <div className="design-block-music-item">
            
            <Iframe url={iframe_link}
                                width="100%"
                                height="300"
                                id="music_frame"
                                className="" scrolling="no" frameborder="no" allow="autoplay"/>
        </div>
    )
}


function createMarkup(html) {
    return {__html: html};
}


const mapStateToProps = ({ mode_preview }) => {

    return {
       
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        ...Actions_PreviewMode
  
    }, dispatch)
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Block_Music)
