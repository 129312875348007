import React from 'react';
import { Link } from 'react-router-dom';
import Iframe from 'react-iframe'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions_PreviewMode from '../../../../../actions/mode_preview';

import {forceSSL} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Button from '../general/block_button';

class Layout_Faq extends React.Component {

    constructor(props) {
            super(props);
        

    }

    componentDidMount(){

        this.props.HandleBodyClasses(this.props.currentpage);
    }

    componentDidUpdate(newProps){

        var newPage = newProps.currentpage.route;
        var oldPage = this.props.currentpage.route;

        if(newPage !== oldPage){
            console.log("MENU CHANGED URL --->");
            this.props.HandleBodyClasses(this.props.currentpage);
        }
    }

    render(){

           
        var { 
            title, 
            description, 
            title_html, 
            description_html, 
            video,
            image,
            music,
            map,
            page_content_url,
            faq_array
            
            
          } = this.props.currentpage;


        const faq_items = faq_array ? faq_array.map((d,index) => <FAQ_Card  key={index}  payload={d} />) : []
      
        const {currentblocks, currentpage} = this.props;

        return(
            <>
  
                <div className="faq-edit-wrapper" style={{background:currentpage.page_bj_color}}>  

                            {video && (
                                 <div className="faq-image">
                                
                                    <div id="about_video">
                                    
                                        <Iframe url={"https://www.youtube.com/embed/"+video+"?autoplay=1"}
                                                width="560"
                                                height="315"
                                                id="video_frame"
                                                className="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen/>
                                    
                                    </div>
                                </div>
                            )}

                            {image && (
                                
                                 <div className="faq-image">
                                  
                                    <img className="mySlides" src={forceSSL(image)} />
                                </div>
                            
                            )}
                        
                            {music  && (
                                
                                 <div className="faq-image">

                                   
                                    <div id="about_music">
                                        <Iframe url={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/"+music+"&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"}
                                                width="100%"
                                                height="300"
                                                id="music_frame"
                                                className="" scrolling="no" frameborder="no" allow="autoplay"/>
            
                                    </div>
                                </div>
                            
                            )}

                            {map && (
                                 <div className="faq-image">

                                    <div id="about_map">

                                        <Iframe url={"https://maps.google.com/maps?q="+page_content_url+"&t=&z=13&ie=UTF8&iwloc=&output=embed"}
                                                width="600"
                                                height="450"
                                                id="map_frame"
                                                className="" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"/>
                                    </div>
                                </div>
                            )}


                <div className="flex-container" id="faqContent">
                    
                    <div className="faq-title">
                        <Block_Textbox type="page_title" content={title} content_html={title_html} />  
                        <Block_Textbox type="page_description" content={description} content_html={description_html} /> 
                    </div>
                    <div className="faq-container accordion" id="accordion">

                        {faq_items}
                        
                    </div>
                    
                </div>

              
            </div>

            {currentblocks}


        </>

        )
    }
};

class FAQ_Card extends React.Component {

    constructor(props) {
        super(props);   
    
        this.state = {
            isFaqOpened: false
        }
        
    }
    

    render(){

        var {title,description} = this.props.payload;
      
        var faq_class = this.state.isFaqOpened ? 'active' : '';

        return (

            <div className={faq_class} >
                <h3 className="accordion-toggle" onClick={()=>this.setState({isFaqOpened: !this.state.isFaqOpened})}>
                    {title}
                </h3>

                <div className="accordion-content">
                    {description}
                </div>  
            </div>
    
        )

    }

   
}


const mapStateToProps = ({ mode_preview }) => {

    return {
       
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        ...Actions_PreviewMode
  
    }, dispatch)
  }
export default connect(mapStateToProps, mapDispatchToProps)(Layout_Faq)
