import { 
  
  GET_FULLWEBSITE_BASICINFO, 
  GET_FULLWEBSITE_STYLE,
  GET_FULLWEBSITE_NAVIGATION,
  GET_FULLWEBSITE_PAGES,
  GET_FULLWEBSITE_CONTACT,
  GET_FULLWEBSITE_SOCIALMEDIA,
  GET_FULLWEBSITE_BLOCKS,
  GET_FULLWEBSITE_HEADER,
  GET_FULLWEBSITE_FOOTER,

  GET_FULLWEBSITE_STORE,
  GET_FULLWEBSITE_BLOG,

  GET_FULLWEBSITE_STOREINFO,
  HANDLE_FULLWEBSITE_CART,
  HANDLE_FULLWEBSITE_BLOG,
  HANDLE_FULLWEBSITE_MAINTENANCE,

  HANDLE_CART_ADD,
  HANDLE_CART_REMOVE,
  HANDLE_CART_RESET,
  HANDLE_CART_SETUP,
  HANDLE_CHECKOUT_SHOW,
  GET_FULLWEBSITE_PRODUCT,
  GET_FULLWEBSITE_ARTICLE,

  HANDLE_MODAL_GALLERY,
  HANDLE_MODAL_VIDEOS,


} from './_types'


import axios from 'axios'

export function getFullWebsiteLive(id, status) {

  return async (dispatch) => {

    if(status === 'initial'){

            var data = id || {};

            if(data.error){
              
              dispatch({type: HANDLE_FULLWEBSITE_MAINTENANCE, status: true});

              return 
            }
            else{

                dispatch({type: HANDLE_FULLWEBSITE_MAINTENANCE, status: false});
            
                dispatch(getFullWebsite_BasicInfo(data.basic_info)); 
                dispatch(getFullWebsite_Style(data.style)); 
                dispatch(getFullWebsite_Navigation(data.navigation)); 
                dispatch(getFullWebsite_Pages(data.pages)); 
                dispatch(getFullWebsite_Blocks(data.blocks));
                dispatch(getFullWebsite_Contact(data.contact)); 
                dispatch(getFullWebsite_SocialMedia(data.socialmedia)); 
                dispatch(getFullWebsite_Header(data.header));
      
                dispatch(getFullWebsite_Store(data.store)); 
                dispatch(getFullWebsite_Blog(data.blog)); 
      
                dispatch(getFullWebsite_Footer(data.footer)); 

            }

            return 
    }

    if(id){

        const url = 'https://www.clickai.co/api/v1/browser/websites/'+id+'/live';
        const jwt = localStorage.getItem('jwt_token');
        
        try {
            var res =  await axios.get(url, { headers: {authorization: jwt} });
            var data = res.data || {};

            if(data.error){
              
              dispatch({type: HANDLE_FULLWEBSITE_MAINTENANCE, status: true});

              return 
            }
            else{

                dispatch({type: HANDLE_FULLWEBSITE_MAINTENANCE, status: false});
                console.log("CIXXXX = ", data);
            
                dispatch(getFullWebsite_BasicInfo(data.basic_info)); 
                dispatch(getFullWebsite_Style(data.style)); 
                dispatch(getFullWebsite_Navigation(data.navigation)); 
                dispatch(getFullWebsite_Pages(data.pages)); 
                dispatch(getFullWebsite_Blocks(data.blocks));
                dispatch(getFullWebsite_Contact(data.contact)); 
                dispatch(getFullWebsite_SocialMedia(data.socialmedia)); 
                dispatch(getFullWebsite_Header(data.header));
      
                dispatch(getFullWebsite_Store(data.store)); 
                dispatch(getFullWebsite_Blog(data.blog)); 
      
                dispatch(getFullWebsite_Footer(data.footer)); 

            }

         

        }
        catch(e) {
          console.error('Fetching images 1: ' + e)

          dispatch(getFullWebsite_BasicInfo({})); 
          dispatch(getFullWebsite_Style({})); 
          dispatch(getFullWebsite_Navigation([])); 
          dispatch(getFullWebsite_Pages([])); 
          dispatch(getFullWebsite_Blocks([]));
          dispatch(getFullWebsite_Contact({})); 
          dispatch(getFullWebsite_SocialMedia({})); 
          dispatch(getFullWebsite_Header({}));
          dispatch(getFullWebsite_Footer({})); 

          dispatch(getFullWebsite_Store([])); 
          dispatch(getFullWebsite_Blog([])); 
          
        }

    }
    else{

          dispatch(getFullWebsite_BasicInfo({})); 
          dispatch(getFullWebsite_Style({})); 
          dispatch(getFullWebsite_Navigation([])); 
          dispatch(getFullWebsite_Pages([])); 
          dispatch(getFullWebsite_Blocks([]));
          dispatch(getFullWebsite_Contact({})); 
          dispatch(getFullWebsite_SocialMedia({})); 
          dispatch(getFullWebsite_Header({}));
          dispatch(getFullWebsite_Footer({})); 

          dispatch(getFullWebsite_Store([])); 
          dispatch(getFullWebsite_Blog([])); 
    }
    
  }
  
}

export function getFullWebsite_BasicInfo(basic_info) {

    return {
      type: GET_FULLWEBSITE_BASICINFO,
      payload: basic_info
    }

}

export function getFullWebsite_Style(style) {

    return {
      type: GET_FULLWEBSITE_STYLE,
      payload: style
    }

}

export function getFullWebsite_Navigation(navigation) {

    return {
      type: GET_FULLWEBSITE_NAVIGATION,
      payload: navigation
    }

}

export function getFullWebsite_Pages(pages) {

    return {
      type: GET_FULLWEBSITE_PAGES,
      payload: pages
    }

}

export function getFullWebsite_Blocks(blocks) {

    return {
      type: GET_FULLWEBSITE_BLOCKS,
      payload: blocks
    }

}


export function getFullWebsite_Contact(contact) {

    return {
      type: GET_FULLWEBSITE_CONTACT,
      payload: contact
    }

}

export function getFullWebsite_SocialMedia(socialmedia) {

    return {
      type: GET_FULLWEBSITE_SOCIALMEDIA,
      payload: socialmedia
    }

}


export function getFullWebsite_Header(header) {

  return {
    type: GET_FULLWEBSITE_HEADER,
    payload: header
  }

}

export function getFullWebsite_Footer(footer) {

  return {
    type: GET_FULLWEBSITE_FOOTER,
    payload: footer
  }

}

export function getFullWebsite_Store(store){

  return {

    type: GET_FULLWEBSITE_STORE,
    payload: store
  }
}

export function getFullWebsite_Blog(blog){

  return {

    type: GET_FULLWEBSITE_BLOG,
    payload: blog
  }
}



export function handleFullwebsite_Cart(status){

  return async (dispatch) => {

      dispatch({
        type: HANDLE_FULLWEBSITE_CART,
        status: status
      })
  
  }
  
}

export function handleFullwebsite_Blog(status, blog_post){

  return async (dispatch) => {

      dispatch({
        type: HANDLE_FULLWEBSITE_BLOG,
        status: status,
        source: blog_post
      })
  
  }
  
}

//Product Current 

export function getFullWebsite_Product(product_obx){

  return async (dispatch) => {

        dispatch({
          type: GET_FULLWEBSITE_PRODUCT,
          product_obx
        })

    }

}

//Article Current 

export function getFullWebsite_Article(article_obx){

  return async (dispatch) => {

        dispatch({
          type: GET_FULLWEBSITE_ARTICLE,
          article_obx
        })

    }

}

//Cart

export function AddToCart(product){

  return async (dispatch) => {

      dispatch({
        type: HANDLE_CART_ADD,
        product: product
      })
  
  }
  
}

export function RemoveFromCart(index){

  return async (dispatch) => {

      dispatch({
        type: HANDLE_CART_REMOVE,
        index: index
      })
  
  }
  
}

export function SetupCurrentCart(cart){

  return async (dispatch) => {

      dispatch({
        type: HANDLE_CART_SETUP,
        cart: cart
      })
  
  }
  
}



export function ResetCart(){

  return async (dispatch) => {

      dispatch({
        type: HANDLE_CART_RESET
      })
  
  }
  
}




export function ShowCheckout(status){

  return async (dispatch) => {

      dispatch({
        type: HANDLE_CHECKOUT_SHOW,
        status: status
      })
  
  }
  
}


export function HandleGalleryModal(status, source, index){

  return async (dispatch) => {

      dispatch({
        type: HANDLE_MODAL_GALLERY,
        status,
        source,
        index
      })
  
  }
  
}


export function HandleVideosModal(status, source, index){

  return async (dispatch) => {

      dispatch({
        type: HANDLE_MODAL_VIDEOS,
        status,
        source,
        index
      })
  
  }
  
}


