import React from 'react';
import scriptLoader from 'react-async-script-loader';
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';
import swal from 'sweetalert';

const STRIPE_PUBLIC_KEY = 'pk_live_KM8N3pjBgO5ku7Vfzh8nOS2y00YhM4kM58';
//const STRIPE_PUBLIC_KEY = 'pk_test_zHJnmBjLhE4bnjaJy6hWK4XR00czsTW0JY';

const StripeForm = ({ isScriptLoaded, isScriptLoadSucceed, basic_info, cart, customer, shipping, delivery, additional, stripeAccount, amount_total, currency_code, HandleCheckoutLoading, web_lang, btn_style, btn_title, isValid, onPixelPurchase, onPixelPayInfo}) => {

  
  console.log("CARTS = ", cart);

      const [stripe, setStripe] = React.useState(null);    

      React.useEffect(() => {
        if (isScriptLoaded && isScriptLoadSucceed) {
          setStripe(window.Stripe(STRIPE_PUBLIC_KEY, {
            stripeAccount: stripeAccount
          }));
        }
      }, [isScriptLoaded, isScriptLoadSucceed]);


      const handleSubmit = async (token) => {


          onPixelPayInfo();

          const url = '/api/v1/builder/websites/orders/create';

          var form = {
              id: basic_info.id,
              cart: cart,
              customer : customer,
              shipping: shipping,
              delivery: delivery,
              additional: additional,
              token: token || ''
          }

          HandleCheckoutLoading({isSending:true, orderId: ''});

          axios.post(url , form).then(response => {

            console.log("CHECKOUT ID = ", response);

            onPixelPurchase();

            var data = response.data;
            HandleCheckoutLoading({isSending:false, orderId: data.order_id}, 'reset');
          
          })
          .catch(error => {
            
              HandleCheckoutLoading({isSending:false, orderId: ''});
              swal('Payment Declined', '\nYour card has been declined. Please try again.', 'error');
              
          });
                
      }

      if (!stripe) {
        return null;
      }


      const onToken = async (token) => {
        handleSubmit(token.id)
      }

      var final_cost = parseFloat(amount_total.toFixed(2))*100;

      return (
        <>
          <StripeCheckout 
                locale={web_lang}
                name={basic_info.title || ''} // the pop-in header title
                description={basic_info.description || ''} // the pop-in header subtitle
                image={basic_info.icon || ''} // the pop-in header image (default none)
                amount={final_cost} // cents
                currency={currency_code || 'USD'}
                stripeKey={STRIPE_PUBLIC_KEY}
                email={customer.email || ''}
                token={onToken} // submit callback
                disabled={isValid ? false : true}
              >
                <>
                    {isValid ? (
                      <div className="button-set" style={{marginTop: '20px'}}>
                          <div className="cart-button" style={btn_style}>{btn_title}</div>
                      </div>
                    ) : (
                        <div className="button-set" style={{marginTop: '20px'}}>
                            <div className="cart-button" style={{...btn_style, background: 'linear-gradient(-135deg, #a1a7a3 0%, #b9c3be 100%)', cursor: 'not-allowed'}}>{btn_title}</div>
                      </div>
                    )}
                </>

          </StripeCheckout>
         
        </>
      );
};


export default scriptLoader('https://js.stripe.com/v3/')(StripeForm);
