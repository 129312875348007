import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions_PreviewMode from '../../../../../actions/mode_preview';

import Frame from 'react-frame-component'

import {forceSSL} from '../../../../../helpers/GeneralHelper';


class MyComponent extends React.Component{

  constructor() {
      super();
      this.state = {
          iFrameHeight: '0px'
      }

      this.adjustHeight = this.adjustHeight.bind(this);
      
  }

  adjustHeight(){

    var includes_iframe = this.props.html ? this.props.html.includes('iframe') : false;

    if(!includes_iframe){

      const iframeDOMNode = ReactDOM.findDOMNode(this.node)
      var iframe_height = iframeDOMNode.contentWindow.document.body.scrollHeight;
      var iframe_style = iframe_height ? iframe_height + 'px' : 'auto'
      iframeDOMNode.height = iframe_style;

      if(this.state.iFrameHeight !== iframe_style){
        this.setState({"iFrameHeight": iframe_style});
      }
      
      console.log("IFRAME HEIGHT WILL BE =====> ",iframe_style, this.state.iFrameHeight);
    }
		
    
  }


	componentDidMount () {
		this.adjustHeight()
	}

	componentDidUpdate () {
    this.adjustHeight()
	}

  render() {


        var includes_iframe = this.props.html ? this.props.html.includes('iframe') : false;

        if(includes_iframe){
          return (
            <>
              <div dangerouslySetInnerHTML={createMarkup(this.props.html)}  />
            </>
          )
        }
        else{


            return(

                <Frame style={{height: this.state.iFrameHeight}} 
                      head={<style>{this.props.css}</style>}
                      ref={x => this.node = x}
                  
                  >  
                      <div dangerouslySetInnerHTML={createMarkup(this.props.html)}  />
          
                </Frame>

            );

        }
       

    }



}
 



class Block_CustomHtml extends React.Component {

    constructor(props) {
        super(props);   

        this.state={
          height: '0px'
        }
    }

    componentDidMount(){

    }

    render(){
        
        var {currentblock, currentblockindex, switchHandleModals} = this.props;
        var {title, description, title_html, description_html, layout, bg_color, bg_image, html, css} = currentblock;
        
  
        return(
  
                <div className="design-block-background-wrapper" style={{background: bg_color}}>
  
                    <div className="design-block-custom-html" style={{background: bg_color}}>
                          
                          {html || css ? 
  
                            <>
                          
                              <MyComponent html={html} css={css}/>
                              
                            </>
                    
                          : 
  
                              <div className="custom-html-placeholder">
                                <h2>Your block is empty</h2>
                              </div>
                          }
  
                          
                    </div>  
                      
                    {bg_image && <img className="design-block-background-image" src={forceSSL(bg_image)} />}
  
                </div>
  
          )
      }
};

function createMarkup(html) {
    return {__html: html};
}

const mapStateToProps = ({ mode_preview }) => {

  return {
    
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      ...Actions_PreviewMode

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Block_CustomHtml)
