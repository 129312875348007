import React from 'react';
import Iframe from 'react-iframe'

class Modal_Video extends React.Component{

    constructor(props){
        super(props);

        this.state = {

            isZoomed: false,
            isDescriptionHidden : true

        }

        this.HandleKeyDown = this.HandleKeyDown.bind(this);
        this.HandleZoom = this.HandleZoom.bind(this);
        this.HandleDescriptionCard = this.HandleDescriptionCard.bind(this);
    }

    componentDidMount(){
        document.addEventListener("keydown", this.HandleKeyDown);
    }
    
    
    componentWillUnmount() {
        document.removeEventListener("keydown", this.HandleKeyDown);
    }
    

    HandleKeyDown(event){

        var {currentVideoIndex, HandleBack, HandleNext, HandleVideoModal} = this.props;

        if(event.keyCode ==37){

            HandleBack(currentVideoIndex);
        }
        if(event.keyCode ==39){

            HandleNext(currentVideoIndex);
        }

        if(event.keyCode ==27){

            HandleVideoModal();
        }
    }

    HandleZoom(){

        this.setState({isZoomed:!this.state.isZoomed});
    } 

    HandleDescriptionCard(){

        this.setState({isDescriptionHidden:!this.state.isDescriptionHidden});
    }


   

    render () {

        var {HandleVideoModal, currentVideoIndex, videos_array, HandleBack, HandleNext, isVideoModalOpened} = this.props;

        var post_obx = videos_array ? videos_array[currentVideoIndex] : {};

        var {title, description, video, extra} = post_obx;

        var back_arrow = 0;
        var next_arrow = 0;

        if(currentVideoIndex == 0){
                            
            back_arrow = 0;
            next_arrow = 1;
        }
        else if(currentVideoIndex == videos_array.length-1){

            back_arrow = 1;
            next_arrow = 0;
        }
        else{

            back_arrow = 1;
            next_arrow = 1;
        
        }
        

        return (

            <div className="gallery-modal video-gallery-modal">
                <div className="close-gallery-modal" onClick={()=>HandleVideoModal()}></div>
               
                <div className="gallery-number">
                    {currentVideoIndex+1}<span>/{videos_array.length}</span>
                </div>
               
                <div className="gallery-nav">
                   
                   {next_arrow == 1 && 
                   
                        <div className="gallery-arrow" 
                             onClick={()=>HandleNext(currentVideoIndex)} 
                             style={{opacity:next_arrow}}>

                            Next<span></span>

                        </div> 
                    }

                    {back_arrow == 1 && 
                   
                        <div className="gallery-arrow" 
                                onClick={()=>HandleBack(currentVideoIndex)} 
                                style={{opacity:back_arrow}}>

                                Previous<span></span>
                                
                        </div> 
                    }

                    {back_arrow == 0 && 
                   
                        <div></div> 
                    
                    }


                </div>
                
                <div className="gallery-modal-content">

                        {isVideoModalOpened && (

                            <div className="gallery-video">
                                <Iframe url={"https://www.youtube.com/embed/"+video+"?autoplay=1"}
                                                width="560"
                                                height="315"
                                                id="video_frame"
                                                className="" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen/>
                            
                            </div>
                        )}

                        <div className={"gallery-modal-description "+(this.state.isDescriptionHidden ? 'hide' : '')}>
                            <div className="close-description" id="closeDescription" onClick={()=>this.HandleDescriptionCard()}></div>
                            <h1 id="gallerymodal-title">{title}</h1>
                        </div>
                    
                </div>
    
            </div>
        )

    }

    
}


export default Modal_Video;