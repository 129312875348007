import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios'

import Block_Textbox from '../general/block_textbox';
import Block_Background from '../general/block_background';
import Block_Settings from '../general/block_settings';
 
import {pickTextColorBasedOnBgColorAdvanced} from '../../../../../helpers/GeneralHelper';
import { Link } from 'react-router-dom';

import NumberFormat from 'react-number-format';

class Block_Products extends React.Component {

    constructor(props) {
        super(props);  

        this.state ={
            data_index: 0,
            data_items: [],
            data_loading: true,
            data_total: 0,
        }
        
        this.onFetchProducts = this.onFetchProducts.bind(this);
      
    }

    componentDidMount(){
        this.onFetchProducts();
    }

    onFetchProducts(){

        var {web_id, currentblock} = this.props;

        var products_array = currentblock.slider || [];

        var nextUrl = '/api/v1/builder/websites/products/blocks';

        this.setState({data_loading: true});

        var form = {
            id: web_id,
            ids: products_array
        }

        axios.post(nextUrl, form).then(res => {

            var prod_array = res.data.array || [];
            this.setState({ 
                data_loading: false, 
                data_items: prod_array
            });
        
        })
        .catch((error) => {
            // handle error
            console.log(error);
            this.setState({data_loading: false})
        });
    };


    render(){
        
        var {currentblock} = this.props;
      
        var {symbol, web_lang} = this.props;
        var {title, description, title_html, description_html, layout, bg_color, bg_image} = currentblock;
        
        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');

        //Block Settings
        var block_class = Block_Settings(currentblock);

        var block_settings = currentblock.settings || {};

        //Products
        var {data_items, data_loading} = this.state;
        var products_items = data_items ? data_items.map((item, index)=> {

            return (
                <Product_Card payload={item} index={index} 
                              key={index} 
                              block_settings={block_settings} 
                              web_symbol={symbol} 
                              web_lang={web_lang}
                />
            )

        }) : [];

        return(

                <div className={block_class} style={{background: bg_color, color: text_color}}>
                   
                    <div className={"design-block-products "+layout}>
                        

                        <div className="title-set">
                                <Block_Textbox type="block_title" content={title} content_html={title_html} />  
                                <Block_Textbox type="block_description" content={description} content_html={description_html} />
                        </div>

                        {data_loading ? (
                            <div className="generic-loading-spin">
                                <i className="fa fa-spinner fa-spin" /> 
                            </div>
                        ) : (

                            <div className="block-products-container">
                                {products_items.length == 0 ? (
                                    <Product_Placeholder />
                                ) : (
                                    products_items
                                )}
                            </div>
                            
                        )}
                      
                    </div>
                    
                    {bg_image && <Block_Background bg_image={bg_image} />}

                </div>

        )
    }
};

const Product_Placeholder = () =>{
    return (
        <div className="empty-store-product-picker">
            <img src="/assets/img/placeholders/empty-store.png" />
            <h4 style={{marginTop: '20px'}}>No products</h4>
        </div>
    )
}


const Product_Card = ({payload, web_symbol, block_settings, web_lang}) =>{

    var {id,title,thumbnail,options} = payload;
        
    var price_tag = Product_Prices_Options(options);
    var price_offer = price_tag.sale ? ((price_tag.price - price_tag.sale) / price_tag.price)*100 : '';
    
    //Pricing
    var prefix = web_symbol;

    var block_title_size = block_settings.title_size || 23;
    var block_description_size = block_settings.description_size || 18;
    var block_subtitle_size = block_settings.subtitle_size || 18;
    var block_extra_size = block_settings.extra_size || 12;

    var block_title_color = block_settings.title_color || '#000000';
    var block_description_color = block_settings.description_color || '#000000';
    var block_subtitle_color = block_settings.subtitle_color || '#ff0000';
    var block_extra_color = block_settings.extra_color || '#38964c';

    //Format
    var block_format = block_settings.format || 'simple';

    if(options.length == 1){
        block_format = 'simple';
    }

    console.log("BLOCK FORMAT ===> ", block_format);


    return (

        <Link className="block-product-item" to={"/store/"+id}>
            <div className="product-item-image">
                {price_offer && (
                    <div className="special-offer-label" style={{fontSize: block_extra_size+'px', background: block_extra_color, color: '#ffffff'}}>
                        {parseInt(price_offer)}% OFF
                    </div>
                )}
                <img src={thumbnail || '/assets/img/placeholders/image.jpg'} alt={title} />
            </div>
            <div className="product-item-info">
                <h4 style={{fontSize: block_title_size+'px', color: block_title_color, fontWeight: 'bold'}}>{title}</h4>

                {price_offer ? (
                  
                    <div className="grid-price" style={{fontSize: block_description_size+'px', color: block_description_color}}>
                        <Product_Price prefix={prefix} value={price_tag.sale} />
                        <span className="old-price" style={{fontSize: block_subtitle_size+'px', color: block_subtitle_color}}>
                            <Product_Price prefix={prefix}  value={price_tag.price} />
                        </span>
                    </div>
                        
                ) : (
                    <>
                         {block_format === 'simple' && (
                            <div className="grid-price" style={{fontSize: block_description_size+'px', color: block_description_color}}>
                                <Product_Price prefix={prefix} value={price_tag.price} />
                            </div>
                        )}

                        {block_format === 'range' && (
                            <div className="grid-price" style={{fontSize: block_description_size+'px', color: block_description_color}}>
                                {price_tag.max ? (
                                    <>
                                        <Product_Price prefix={prefix} value={price_tag.price} />
                                        {'  -  '}
                                        <Product_Price prefix={prefix} value={price_tag.max} />
                                    </>
                                ) : (
                                    <Product_Price prefix={prefix} value={price_tag.price} />
                                )}
                            
                            </div>
                        )}

                        {block_format === 'minimum' && (
                            <div className="grid-price" style={{fontSize: block_description_size+'px', color: block_description_color}}>
                                <span style={{marginRight: '5px', fontWeight: '300'}}>{getTranslatedPage('from', web_lang)}</span>
                                <Product_Price prefix={prefix} value={price_tag.price} />
                            </div>
                        )}

                    </>
                )}
               
            </div>
        </Link>

      
    )
}

const Product_Price = ({prefix, value}) => {

    return (
        <NumberFormat displayType="text" 
                      thousandSeparator={true} 
                      decimalScale={2} 
                      fixedDecimalScale={2}
                      prefix={prefix}  
                      value={value} />
    )
}

const Product_Prices_Options = (options) =>{

    var price_tag = {
        price: 0,
        sale: 0
    }

    var sorted_options = (options && options.length>0) ? options.sort((a, b) => parseFloat(a.price) - parseFloat(b.price)) : [];

    if(sorted_options.length >0){
        var list_price = sorted_options[0].price;
        var sale_price = sorted_options[0].sale;
        
        price_tag = {...price_tag, price: parseFloat(list_price), sale: parseFloat(sale_price)};

        //Get Max
        var max_price = sorted_options[sorted_options.length - 1].price;

        if(max_price != list_price){
            price_tag = {...price_tag, max: parseFloat(max_price)}
        }
    }

    return price_tag;
}

const getTranslatedPage = (type, lang) => {
    
    var tr = '';

    if(type === 'from'){
        switch (lang) {
            case 'fr':
                tr = 'à partir de'
                break;
            case 'it':
                tr = 'da'
                break;
            case 'es':
                tr = 'desde'
                break;
            default:
                tr = 'from'
                break;
        }
    }

    return tr

}

const mapStateToProps = ({ mode_preview }) => {
    return {
        web_id : mode_preview.basic_info.id,
        currency: mode_preview.store.currency,
        symbol: mode_preview.store.symbol,
        web_lang: mode_preview.basic_info.language
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
   
  
    }, dispatch)
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Block_Products)
  