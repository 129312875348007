import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {forceSSL} from '../../../../../helpers/GeneralHelper';


import {HandleGalleryModal} from '../../../../../actions/mode_preview';

class Modal_Gallery extends React.Component{

    constructor(props){
        super(props);

        this.state = {

            isZoomed: false,
            isDescriptionHidden : true

        }

        this.HandleKeyDown = this.HandleKeyDown.bind(this);
        this.HandleZoom = this.HandleZoom.bind(this);
        this.HandleDescriptionCard = this.HandleDescriptionCard.bind(this);
        this.HandleBack = this.HandleBack.bind(this);
        this.HandleNext = this.HandleNext.bind(this);
        this.HandleClose = this.HandleClose.bind(this);
    }

    componentDidMount(){
        document.addEventListener("keydown", this.HandleKeyDown);
    }
    
    
    componentWillUnmount() {
        document.removeEventListener("keydown", this.HandleKeyDown);
    }
    

    HandleKeyDown(event){

        var {CurrentGalleryIndex} = this.props;

        if(event.keyCode ==37){

            this.HandleBack(CurrentGalleryIndex);
        }
        if(event.keyCode ==39){

            this.HandleNext(CurrentGalleryIndex);
        }

        if(event.keyCode ==27){

            this.HandleClose();
        }
    }

    HandleZoom(){

        this.setState({isZoomed:!this.state.isZoomed});
    } 

    HandleDescriptionCard(){

        this.setState({isDescriptionHidden:!this.state.isDescriptionHidden});
    }

    HandleNext(index){

        var {route} = this.props.currentpage;
        var {CurrentGallery} = this.props;
           
        if(index != CurrentGallery.length-1){

            this.props.HandleGalleryModal(true, CurrentGallery, index+1);

            var post_id = CurrentGallery[index+1].id;
            if(post_id){
                this.props.history.push('/'+route+'?uid='+post_id);
            }

        }
       
    }

    HandleBack(index){

        var {route} = this.props.currentpage;
        var {CurrentGallery} = this.props;

        if(index != 0){

            this.props.HandleGalleryModal(true, CurrentGallery, index-1);

            var post_id = CurrentGallery[index-1].id;

            if(post_id){
                this.props.history.push('/'+route+'?uid='+post_id);
            }
            
        }

    }

    HandleClose(){

        var {route} = this.props.currentpage;

        document.body.classList.remove('gallery-modal-active');

        this.props.HandleGalleryModal(false, [], null);  
        
        this.props.history.push('/'+route);
    }



    render () {

        var {CurrentGallery} = this.props;
        var {CurrentGalleryIndex} = this.props;

        var post_obx = CurrentGallery ? CurrentGallery[CurrentGalleryIndex] : {};
        var post_id = post_obx.id || '';
        var post_title = post_obx.title || '';
        var post_image = post_obx.img || post_obx;

        var back_arrow = 0;
        var next_arrow = 0;

        if(CurrentGalleryIndex == 0){
                            
            back_arrow = 0;
            next_arrow = 1;
        }
        else if(CurrentGalleryIndex == CurrentGallery.length-1){

            back_arrow = 1;
            next_arrow = 0;
        }
        else{

            back_arrow = 1;
            next_arrow = 1;
        
        }
        

        return (

            <div className="gallery-modal" id="gallerymodal-master">
                <div className="close-gallery-modal" onClick={()=>this.HandleClose()}></div>
               
                <div className="gallery-number">
                    {CurrentGalleryIndex+1}<span>/{CurrentGallery.length}</span>
                </div>
               
                <div className="gallery-nav">
                   
                   {next_arrow == 1 && 
                   
                        <div className="gallery-arrow" 
                             onClick={()=>this.HandleNext(CurrentGalleryIndex)} 
                             style={{opacity:next_arrow}}>

                            Next<span></span>

                        </div> 
                    }

                    {back_arrow == 1 && 
                   
                        <div className="gallery-arrow" 
                                onClick={()=>this.HandleBack(CurrentGalleryIndex)} 
                                style={{opacity:back_arrow}}>

                                Previous<span></span>
                                
                        </div> 
                    }

                    {back_arrow == 0 && 
                   
                        <div></div> 
                    
                    }


                </div>
                
                <div className="gallery-modal-content" id="gallerymodal-content">

                        <div className={"gallery-modal-image "+(this.state.isZoomed ? 'zoomed' : '')}>
                            <img src={forceSSL(post_image)} onClick={()=>this.HandleZoom()} />
                        </div>

                        <div className={"gallery-modal-description "+(this.state.isDescriptionHidden ? 'hide' : '')}>
                            <div className="close-description" id="closeDescription" onClick={()=>this.HandleDescriptionCard()}></div>
                            <h1 id="gallerymodal-title">{post_title}</h1>
                        </div>
                    
                </div>
    
            </div>
        )

    }

    
}


const mapStateToProps = ({ mode_preview }) => {

    return {
        CurrentGallery: mode_preview.CurrentGallery,
        CurrentGalleryIndex: mode_preview.CurrentGalleryIndex
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        HandleGalleryModal
  
    }, dispatch)
  }
export default connect(mapStateToProps, mapDispatchToProps)(Modal_Gallery)
