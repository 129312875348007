const Block_Settings = (currentblock, container_block) =>{
        
    var {bg_image, settings} = currentblock;

    var block_settings = settings || {};
    var block_align = block_settings.align || 'left';
    var block_padding = block_settings.padding || 'medium';
    var block_overlay = block_settings.overlay || 'overlay';
    var block_text = block_settings.text || false;

    var block_orientation = block_settings.orientation || 'square';

    if(!container_block){
        container_block = 'design-block-background-wrapper';
    }
    
    var block_class_array = [
        container_block,
        'design-block-align-'+block_align,
        'design-block-padding-'+block_padding,
        'image-display-'+block_orientation
    ];

    bg_image && block_class_array.push('design-block-text-'+block_overlay);
    block_text && block_class_array.push('hide-title-set');

    return block_class_array.join(' ');
}

export default Block_Settings;