import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {HandleGalleryModal} from '../../../../../actions/mode_preview';

import {forceSSL, pickTextColorBasedOnBgColorAdvanced} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Page_Settings from '../general/page_settings';

class Layout_Gallery extends React.Component {

    constructor(props) {
        super(props);   

        
        this.onSelectGalleryImage = this.onSelectGalleryImage.bind(this);
    }

    componentDidMount(){

        this.props.HandleBodyClasses(this.props.currentpage);
        var route_params = getQueryStringParams(window.location.search);
        var gallery_img = route_params.uid || '';

        if(gallery_img){
            this.onSelectGalleryImage(gallery_img);
        }

    }

    componentDidUpdate(newProps){

        var newPage = newProps.currentpage.route;
        var oldPage = this.props.currentpage.route;

        if(newPage !== oldPage){
            console.log("MENU CHANGED URL --->");
            this.props.HandleBodyClasses(this.props.currentpage);
        }
    }

    onSelectGalleryImage(uid){

        var {gallery_array} = this.props.currentpage;

        var gallery_items = gallery_array || [];
        var find_index =  gallery_items.length > 0 ? gallery_items.findIndex(post => post.id === uid)  : -1;
    
        document.body.classList.add('gallery-modal-active');

        this.props.HandleGalleryModal(true, gallery_items, find_index);

      
    }



    render(){
        
    
        var {currentpage, currentblocks} = this.props;
        var {title, description, title_html, description_html, gallery_array, page_bj_color, route} = currentpage;
        
        //Page Settings
        var block_class = Page_Settings(currentpage);

        //New Edits
        var background_color = page_bj_color || '#ffffff';
        var text_color = pickTextColorBasedOnBgColorAdvanced(page_bj_color, '#FFFFFF', '#000000');
        var general_styles = {
            color: text_color,
            background: background_color
        }
        
        var page_settings = currentpage.settings || {};
        var gallery_items = gallery_array ? gallery_array.map((d, index)=>  {
            return (
                <Card_Image key={index} 
                            payload={d} 
                            index={index}
                            settings={page_settings}
                            route={route}
                            onSelectGalleryImage={this.onSelectGalleryImage}
                />
            )
        }) : [];

       
        return(

            <>
                <div className={block_class} id="tableOfContents" style={general_styles}>

                        <div className="gallery-title-wrapper">
                            <div className="gallery-title">
                                <Block_Textbox type="page_title" content={title} content_html={title_html} />  
                                <Block_Textbox type="page_description" content={description} content_html={description_html} />  
                            </div>
                        </div>

                        {gallery_items.length == 0 ? (
                            <Gallery_Placeholder />
                        ) : (
                            <div className="grid-container music-grid">
                                {gallery_items}
                            </div>
                        )}

                </div>

               
                {currentblocks}
                                
            </>
        )
    }
};


const Card_Image = ({payload, settings, onSelectGalleryImage, route}) =>{

    var {img,title, alt, id} = payload;

    var block_title_size = settings.title_size || 20;
    var block_title_color = settings.title_color || '#000000';    

    if(id){
        return (
            <Link className="larger" to={'/'+route+'?uid='+id} onClick={()=>onSelectGalleryImage(id)}>
                <div className="gallery-image-wrapper">
                    <img src={forceSSL(img)} alt={alt || ''}/>
                </div>
                <h4 className="gallery-item-title" style={{fontSize: block_title_size+'px', color: block_title_color}}>{title}</h4>
            </Link>
        )
    }
    else {
        return (
            <a className="larger">
                <div className="gallery-image-wrapper">
                    <img src={forceSSL(img)} alt={alt || ''}/>
                </div>
                <h4 className="gallery-item-title" style={{fontSize: block_title_size+'px', color: block_title_color}}>{title}</h4>
            </a>
        )
    }

    
}


const Gallery_Placeholder = () =>{

    return (    
        <div className="grid-container music-grid">
            <h1>This Website doesnt have gallery images</h1>
        </div>
    )
}

const getQueryStringParams = (query) =>{
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
}

const mapStateToProps = ({ mode_preview }) => {

    return {
       
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        HandleGalleryModal
  
    }, dispatch)
  }
export default connect(mapStateToProps, mapDispatchToProps)(Layout_Gallery)
