import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import Page_404 from './partials/bj_404';

import Page_Cover from './partials/bj_cover';
import Page_Home from './partials/bj_home';
import Page_Blank from './partials/bj_blank';
import Page_About from './partials/bj_about';
import Page_Contact from './partials/bj_contact';
import Page_Faq from './partials/bj_faq';
import Page_Hero from './partials/bj_hero';
import Page_MenuList from './partials/bj_menulist';
import Page_Timeline from './partials/bj_timeline';
import Page_Reviews from './partials/bj_reviews';
import Page_Product from './partials/bj_product';
import Page_Article from './partials/bj_article';

import Page_Store from './partials/bj_store';
import Page_Blog from './partials/bj_blog';
import Page_Gallery from './partials/bj_gallery';
import Page_Videos from './partials/bj_videos';
import Page_Events from './partials/bj_events';

import Block_Text from './blocks/block_text';
import Block_TextImage from './blocks/block_textimage';
import Block_Signup from './blocks/block_signup';
import Block_Gallery from './blocks/block_gallery';
import Block_Blog from './blocks/block_blog';
import Block_CustomHTML from './blocks/block_customhtml';
import Block_CustomForm from './blocks/block_customform';
import Block_Table from './blocks/block_table';

import Block_Features from './blocks/block_features';
import Block_Products from './blocks/block_products';
import Block_Carousel from './blocks/block_carousel';
import Block_Video from './blocks/block_video';
import Block_Music from './blocks/block_music';
import Block_Map from './blocks/block_map';
import Block_Contact from './blocks/block_contact';

import Block_Faq from './blocks/block_faq';
import Block_Reviews from './blocks/block_reviews';


class Pages extends React.Component {

    constructor(props){
        super(props);

    }

    componentDidMount(){

        console.log("MOUNTING PAGES ====> ");

    }

    render() {

        var {pages, currenttype} = this.props;

        if(pages.length == 0){
            return (
                <div className="blank-page-container">
                    <div className="blank-website-placeholder">    
                        <h3>This website is in construction</h3>
                        <img className="blank-page-img bottom" src="/assets/img/blank-web-placeholder.png" />
                    </div> 
                </div>
            )
        }
    

        if(!currenttype){
            
            return (
                <Page_404 {...this.props} />
            )
        }


        return (
            <>
                <Current_Page {...this.props} />   
            </>
            
        )
    

    }
   
}

const Current_Page = (props) =>{
    
    var {currenttype, currentpage, currentindex, blocks, history, match, location, HandleBodyClasses, isFbPixel} = props;
    
    var sharedProps = {
        history,
        match,
        location,
        currentpage,
        currentindex,
        isFbPixel
    }

    //Current Blocks
    var page_blocks = currentpage.page_blocks || [];
    var currentblocks = page_blocks.map((item, item_index)=> {

        var block_index = blocks.findIndex((block)=> block.id === item);
        
        if(block_index != -1){
            var block_obx = blocks[block_index];

            var sharedExtra = {
                type: block_obx.type,
                currentblock: block_obx,
                currentblockindex: block_index,
                block_index: item_index
            }

            return (
                <div key={block_obx.id} id={'bk'+block_obx.id} className="section_block">
                    <Current_Block {...sharedProps} {...sharedExtra} />
                </div>
            )
        }
        
    })

    var sharedPageActions = {
        ...sharedProps,
        currentblocks,
        HandleBodyClasses: HandleBodyClasses,
    }

    var type = currenttype; 

    if(type === 'cover'){
        return <Page_Cover {...sharedPageActions} />
    }
    else if(type === 'home'){
        return <Page_Home {...sharedPageActions}  />
    }
    else if(type === 'blank'){
        return <Page_Blank {...sharedPageActions}  />
    }
    else if(type === 'about'){
        return <Page_About {...sharedPageActions}  />
    }
    else if(type === 'contact'){
        return <Page_Contact {...sharedPageActions}  />
    }
    else if(type === 'faq'){
        return <Page_Faq {...sharedPageActions}  />
    }
    else if(type === 'hero'){
        return <Page_Hero {...sharedPageActions}  />
    }
    else if(type === 'menulist'){
        return <Page_MenuList {...sharedPageActions}  />
    }
    else if(type === 'timeline'){
        return <Page_Timeline {...sharedPageActions}  />
    }
    else if(type === 'reviews'){
        return <Page_Reviews {...sharedPageActions}  />
    }
    else if(type === 'product'){
        return <Page_Product {...sharedPageActions}  />
    }
    else if(type === 'article'){
        return <Page_Article {...sharedPageActions}  />
    }
    else if(type === 'gallery'){
        return <Page_Gallery {...sharedPageActions}  />
    }
    else if(type === 'blog'){
        return <Page_Blog {...sharedPageActions}  />
    }
    else if(type === 'videos'){
        return <Page_Videos {...sharedPageActions}  />
    }
    else if(type === 'events'){
        return <Page_Events {...sharedPageActions}  />
    }
    else if(type === 'store'){
        return <Page_Store {...sharedPageActions}  />
    }
    else{
        return <Page_Blank {...sharedPageActions}  />;
    }
  
}

const Current_Block = (props) => {

    var {type, currentblock, currentblockindex, block_index} = props;
    var {currentpage, currentindex, history, match, location } = props;

    var sharedBasic = {
        history,
        match,
        location,
        currentpage,
        currentindex
    }

    var sharedProps = {
        ...sharedBasic,
        currentblock,
        currentblockindex,
        block_index,
    }

    console.log("LOOKING FOR A BLOCK = ",type);
      
    if(type === 'block_text'){
        return <Block_Text  {...sharedProps} />
    }
    else if(type === 'block_textimage'){
        return <Block_TextImage {...sharedProps} />
    }
    else if(type === 'block_signup'){
        return <Block_Signup {...sharedProps} />
    }
    else if(type === 'block_gallery'){
        return <Block_Gallery {...sharedProps} />
    }
    else if(type === 'block_blog'){
        return <Block_Blog {...sharedProps} />
    }
    else if(type === 'block_products'){
        return <Block_Products {...sharedProps} />
    }
    else if(type === 'block_customhtml'){
        return <Block_CustomHTML {...sharedProps} />
    }
    else if(type === 'block_features'){
        return <Block_Features {...sharedProps} />
    }
    else if(type === 'block_table'){
        return <Block_Table {...sharedProps} />
    }
    else if(type === 'block_carousel'){
        return <Block_Carousel {...sharedProps} />;
    }
    else if(type === 'block_video'){
        return <Block_Video {...sharedProps} />;
    }
    else if(type === 'block_music'){
        return <Block_Music {...sharedProps} />;
    }
    else if(type === 'block_map'){
        return <Block_Map {...sharedProps} />;
    }
    else if(type === 'block_faq'){
        return <Block_Faq {...sharedProps} />;
    }
    else if(type === 'block_reviews'){
        return <Block_Reviews {...sharedProps} />;
    }
    else if(type === 'block_contact'){
        return <Block_Contact {...sharedProps} />;
    }
    else if(type === 'block_customform'){
        return <Block_CustomForm {...sharedProps} />;
    }
    else{
        return <Block_Text {...sharedProps} />;
    }
      
}

const mapStateToProps = ({ mode_preview}) => {
  return {
    pages: mode_preview.pages,
    blocks: mode_preview.blocks,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({

  }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(Pages)
