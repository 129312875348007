import React from 'react';
import axios from 'axios';

import {editorStateFromRaw} from "megadraft";
import {stateToHTML} from 'draft-js-export-html';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {forceSSL, pickTextColorBasedOnBgColorAdvanced, createMarkup} from '../../../../../helpers/GeneralHelper';

import NumberFormat from 'react-number-format';

import {AddToCart} from '../../../../../actions/mode_preview';
  
class Layout_Product extends React.Component {

    constructor(props) {
        super(props);   

        this.state = {
            CurrentProduct: {},
            isLoadingProduct: true,
            isNotFound: false,
            currentImageIndex: 0,
            optionSelected: 0,
            quantitySelected : 1
        }

        this.onFetchProduct = this.onFetchProduct.bind(this);
        this.onAddCart = this.onAddCart.bind(this);
        this.onSelectOption = this.onSelectOption.bind(this);
        this.onSelectQuantity = this.onSelectQuantity.bind(this);
    
    }

     
    //LifeCycle
    componentDidMount(){
        this.props.HandleBodyClasses(this.props.currentpage);
        this.onFetchProduct();
    }

 
    //Functions
    onFetchProduct(){

        var {match, web_title, web_id} = this.props;
        var route_uid = match ? match.params.subpath : ''
       
        var req_url = '/api/v1/builder/websites/products/single/live';
        var req_form = {
           id: web_id,
           p_id: route_uid
        }

        axios.post(req_url, req_form).then(res => {

            console.log("PRODUCT IS ====> ", res.data);

            var data = res.data || {};
            var error = data.error;

            if(error){
                this.setState({isLoadingProduct: false, isNotFound: true});
            }
            else{
                var product_title = data.title;
                document.title = web_title + ' | '+ product_title;
                this.setState({isLoadingProduct: false, isNotFound: false, CurrentProduct: data});
            }
           

        })
        .catch((error) => {
            // handle error
            console.log(error);
            this.setState({data_loading: false});
        });


    };

    onAddCart(){

        var {optionSelected, quantitySelected} = this.state;
        var { id, title, images, options, type } = this.state.CurrentProduct;
            
        var price_tag = Product_Prices_Options(options, optionSelected);

        var current_price = price_tag.price || 0;
        var current_sale = price_tag.sale || 0;
        var current_option = price_tag.option || '';
        var current_tax = price_tag.tax || 0;
        var current_weight = price_tag.weight || 0;

        var product_obx = {
            id: id,
            icon: images[0],
            title: title,
            option: current_option,
            quantity: quantitySelected,
            cost: current_price,
            sale: current_sale,
            type: type,
            weight: current_weight,
            tax : current_tax 
        }

        console.log("PRICE TAG=====> ", price_tag, product_obx);

        this.props.AddToCart(product_obx);

        // //Pixel
        // var {isFbPixel, web_pixel, web_currency} = this.props;

        // if(isFbPixel && web_pixel){

        //     var pixel_data = {
        //         content_name: title, 
        //         content_ids: [id],
        //         content_type: 'product',
        //         value: current_sale > 0 ? current_sale : current_price,
        //         currency: web_currency 
        //     }

        //     console.log("Pixel Data ===> ", pixel_data, current_price, current_sale);
        //     fbq('track', 'AddToCart', pixel_data);   
        // }
        
    }


    onSelectOption(event){
        var {options} = this.state.CurrentProduct
        var options_items = options || [];
        var optionIndex = options_items.findIndex((item)=> item.option === event.target.value);
        if(optionIndex != -1){
            this.setState({optionSelected: optionIndex});
        }
    }

    onSelectQuantity(event){
        var value = event.target.value;
        console.log("QUANTITY ===> ", value);
        if(value <= 0){
            value = 1;
        }
        this.setState({quantitySelected: value});

    }


    render(){

        var {isLoadingProduct, isNotFound, CurrentProduct, currentImageIndex, optionSelected, quantitySelected} = this.state;
        var {web_symbol, web_lang} = this.props;

        var { 
            title,  
            content_raw,
            images, 
            options, 
            layout,
            bg_color,
        } = CurrentProduct;
       

        //Images
        var product_images = images || [];
        var product_images_items = product_images.map((item, index)=> {

            return (
                <img className="product-image-detail" 
                     key={index} src={forceSSL(item)} 
                     className={(currentImageIndex == index) ? 'active' : ''} 
                     onClick={()=>this.setState({currentImageIndex: index})}
                />
            )
        })
        
        //Options
        var product_options = options || [];
        var product_options_items = product_options.map((d,index) => {
            return (
                <option key={index} value={d.option}>
                    {d.option}
                </option>
            )
        });
    
        //Pricing
        var prefix = web_symbol;
        var price_tag = Product_Prices_Options(options, optionSelected);
    
        var current_price = price_tag.price || 0;
        var current_sale = price_tag.sale || 0;
        var price_offer = current_sale ? ((current_price - current_sale)/current_price)*100 : '';

        var total_full_cost = current_price * quantitySelected;
        var total_sale_cost = current_sale * quantitySelected;

        
        //Description
        var product_content_raw = JSON.parse(content_raw ? content_raw : null);    
        var product_description = getFormattedHtml(product_content_raw)
        
        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');
    
        if(isLoadingProduct){
           return (

                <div className="website-loader-wrapper">
                    <div className="branded-loading">
                        {/*<h5>Loading</h5>*/}
                       <i className="fa fa-spinner fa-spin" /> 
                    </div>
                </div>
                
           )
        }

        if(isNotFound){
            return (
                <div className="website-loader-wrapper">
                    <h1>Product not found</h1>      
                    <div className="explore-btn" style={{background: 'rgb(0, 0, 0)', color: 'rgb(255, 255, 255)'}} onClick={()=> this.props.history.push('/')}>
                        <i className="fa fa-plus-circle" /> Back Home
                    </div>      
                </div>
            )
        }


        //Store Settings

        var store_settings = this.props.store_settings || {};
        var block_title_size = store_settings.title_size || 50;
        var block_description_size = store_settings.description_size || 35;
        var block_subtitle_size = store_settings.subtitle_size || 20;
        var block_extra_size = store_settings.extra_size || 14;
    
        var block_title_color = store_settings.title_color || '#000000';
        var block_description_color = store_settings.description_color || '#000000';
        var block_subtitle_color = store_settings.subtitle_color || '#ff0000';
        var block_extra_color = store_settings.extra_color || '#38964c';

        var cart_style = {fontSize: block_extra_size+'px', background: block_extra_color, color: '#ffffff'};

        
        return(

            <div className={"flex-container "+(layout)+" "+(text_color==='#000000' ? 'text-black' : 'text-white')} id="productContent" style={{background: bg_color}}>

                    <div className="product-image-wrapper">
                        {product_images_items.length >1 &&(
                            <div className="product-image-thumbs">
                                {product_images_items}
                            </div>
                        )}
                        {product_images_items[currentImageIndex]}
                    </div>

                    <div className="product-details-wrapper">

                        <div className="product-details-title">
                            <h1 style={{fontWeight: 'bold', fontSize: block_title_size+'px', color: block_title_color}}>{title}</h1>
                        </div>
                        <div className="price-wrapper">
                            {price_offer ? (
                                <div className="grid-price" style={{fontSize: block_description_size+'px', color: block_description_color}}>
                                    <Product_Price prefix={prefix} value={total_sale_cost} />
                                    <span className="old-price" style={{fontSize: block_subtitle_size+'px', color: block_subtitle_color}}>
                                        <Product_Price prefix={prefix} value={total_full_cost} />
                                    </span>
                                </div>
                            ) : (
                                <div className="grid-price" style={{fontSize: block_description_size+'px', color: block_description_color}}>
                                    <Product_Price prefix={prefix} value={total_full_cost} />
                                </div>
                            )}
                        </div>
                        
                        
                        {product_options_items.length > 1 ? (
                            <div className="product-options">
                                <label>{getTranslatedPage('quantity', web_lang)}</label>
                                <input type="number" value={quantitySelected} placeholder="QTY" onChange={(e)=>this.onSelectQuantity(e)} />

                                <select onChange={this.onSelectOption}>
                                      {product_options_items}
                                </select>
                               
                                <a className="cart-button" id="addToCart" style={cart_style} onClick={()=> this.onAddCart()}>
                                    <img src="/assets/img/add-cart.png" className="cart-icon" />
                                    {getTranslatedPage('add_cart', web_lang)}
                                </a>
                            </div>
                        ) : (
                            <div className="product-options">
                                <label>{getTranslatedPage('quantity', web_lang)}</label>
                                <input type="number" value={quantitySelected} placeholder="QTY" onChange={(e)=>this.onSelectQuantity(e)} />
                                
                                <a className="cart-button" id="addToCart" style={cart_style} onClick={()=> this.onAddCart()}>
                                    <img src="/assets/img/add-cart.png" className="cart-icon" />
                                    {getTranslatedPage('add_cart', web_lang)}
                                </a>
                            </div>
                        )}
                        
                        
                        <div className="product-description">
                            <div dangerouslySetInnerHTML={createMarkup(product_description)} />
                        </div>
                    </div>	

                </div>

        )
    }
};


const Product_Price = ({prefix, value}) => {

    return (
        <NumberFormat displayType="text" 
                      thousandSeparator={true} 
                      decimalScale={2} 
                      fixedDecimalScale={2}
                      prefix={prefix}  
                      value={value} />
    )
}

const Product_Prices_Options = (options, optionSelected) =>{

    var price_tag = {};

    if(options && options.length >0){
        
        var current_option = options[optionSelected] ? options[optionSelected].option : options[0].option;
        var current_tax = options[optionSelected] ? options[optionSelected].tax : options[0].tax;
        var current_weight = options[optionSelected] ? options[optionSelected].weight : options[0].weight;

        var list_price = options[optionSelected] ? options[optionSelected].price : options[0].price;
        var sale_price = options[optionSelected] ? options[optionSelected].sale : options[0].sale;
        
        price_tag = {...price_tag, 
                        price: parseFloat(list_price), 
                        sale: parseFloat(sale_price), 
                        option: current_option,
                        weight: current_weight,
                        tax: current_tax
                    };

    }
   
    return price_tag;
}

const getFormattedHtml = (content_raw) => {

    var editorState = editorStateFromRaw(content_raw);
    var options = {

        blockRenderers: {
            atomic: (block) => {
                var blockType = block.get('type').toLowerCase();
                if (blockType === 'atomic') {
                    
                    var data = block.getData();
                    var blogSubType = data.get("type");
                    var blogDisplay = data.get("display");

                    if(blogSubType === 'click_imagepicker'){
                        
                        var image_size_type = blogDisplay || 'medium';
                        var image_class_name = 'blog_image_'+image_size_type;
                
                        return '<img width="420" height="315" src="'+data.get("src")+'" class='+image_class_name+'></img>';
                        
                    }
                    else if(blogSubType === 'click_videopicker'){
                        
                        var video_size_type = blogDisplay || 'medium';
                        var video_class_name = 'blog_video_'+video_size_type;
                
                        return '<iframe width="420" height="315" class="'+video_class_name+'" src="https://www.youtube.com/embed/'+data.get('src')+'"></iframe>';
                        
                    }
                    else if(blogSubType === 'click_mappicker'){
                        
                        var map_size_type = blogDisplay || 'medium';
                        var map_class_name = 'blog_map_'+map_size_type;
                
                        var mapAddress = data.get('src').replace(' ', '+');
                        console.log("SOURCE = ", data.get('src'));
                        return '<iframe width="420" height="315" class="'+map_class_name+'" src="https://maps.google.com/maps?q='+mapAddress+'k&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>';
                        
                    }
                    else if(blogSubType === 'click_musicpicker'){
                        
                        var music_type = data.get("music_type");
                        var music_size_type = blogDisplay || 'medium';
                        var music_class_name = 'blog_music_'+music_size_type;
                        
                        var iframe_link = '';

                        if(music_type === 'spotify'){
                            
                            iframe_link = data.get("src");
                        }
                        else if(music_type === 'soundcloud'){

                            iframe_link = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/"+data.get("src")+"&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                        }
                        
                
                        return '<iframe width="420" height="315" class="'+music_class_name+'" src="'+iframe_link+'"></iframe>';
                        
                    }
                }
            },
        },
        entityStyleFn: (entity) => {
            const entityType = entity.get('type').toLowerCase();
            
            console.log("ENTYT = ", entityType);

            if (entityType === 'link') {
                
                const data = entity.getData();
                
                return {
                element: 'a',
                attributes: {
                    href: data.href,
                    title: data.title
                    
                },
                style: {
                    color: '#3192E7'
                }
                };
            }
            },
    }
    return stateToHTML(editorState.getCurrentContent(), options);
    
}

const getTranslatedPage = (type, lang) => {
    
    var tr = '';

    if(type === 'quantity'){
        switch (lang) {
            case 'fr':
                tr = 'Quantité:'
                break;
            case 'it':
                tr = 'Quantità:'
                break;
            case 'es':
                tr = 'Cantidad:'
                break;
            default:
                tr = 'Quantity:'
                break;
        }
    }
    else if(type === 'add_cart'){
        switch (lang) {
            case 'fr':
                tr = 'Ajouter au panier'
                break;
            case 'it':
                tr = 'Aggiungi al carrello'
                break;
            case 'es':
                tr = 'Añadir a tu carrito'
                break;
            default:
                tr = 'Add to cart'
                break;
        }
    } 

    return tr

}


const mapStateToProps = ({ mode_preview }) => {
  
    return {
        web_title: mode_preview.basic_info.title,
        web_symbol: mode_preview.store.symbol,
        web_currency: mode_preview.store.currency,
        web_id: mode_preview.basic_info.id,
        web_lang: mode_preview.basic_info.language,
        web_pixel: mode_preview.basic_info.facebook_pixel,
        store_settings: mode_preview.style.store || {}
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        AddToCart
    }, dispatch)
  }


export default connect(mapStateToProps, mapDispatchToProps)(Layout_Product)

