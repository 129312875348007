import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {forceSSL, pickTextColorBasedOnBgColorAdvanced} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Button from '../general/block_button';
import Page_Settings from '../general/page_settings';

const AVAILABLE_LAYOUTS = ['cover-3', 'cover-4'];

class Layout_Cover extends React.Component {

    constructor(props) {
        super(props);  
   
        this.state={
            slide_index: 0,
            auto_show: true
        }

        this.onNextSlide = this.onNextSlide.bind(this);
        this.onBackSlide = this.onBackSlide.bind(this);
        this.onAutoShow = this.onAutoShow.bind(this);
        this.onStopShow = this.onStopShow.bind(this);
    }

 
    //Lifecycle
    componentDidMount(){

        var {currentpage} = this.props;
        var layout = currentpage.page_layout;

        if(AVAILABLE_LAYOUTS.includes(layout)){

            var page_settings = currentpage.settings || {};

            var block_autoplay = page_settings.autoplay || false;
            var block_timer = page_settings.timer || 5000;

            if(block_autoplay){
                
                this.interval = setInterval(() => {
                  this.onAutoShow();
                  this.setState({auto_show: true})
                },	 		
                block_timer);

            }

        }

        this.props.HandleBodyClasses(currentpage);

    }

    componentDidUpdate(newProps){

        var newPage = newProps.currentpage.route;
        var oldPage = this.props.currentpage.route;

        if(newPage !== oldPage){
            console.log("MENU CHANGED URL --->");
            this.props.HandleBodyClasses(this.props.currentpage);
        }
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }

 

    //SlideShow
    onNextSlide(){
        var {slide_index} = this.state;
        var {currentpage} = this.props;
        var gallery_items = currentpage.slider || [];

        var newGallery = slide_index;

        if(slide_index == gallery_items.length-1){
          newGallery = 0;
        }
        else{
          newGallery = slide_index+1;
        }

        this.setState({slide_index: newGallery});
        clearInterval(this.interval);
    }

    onBackSlide(){
        var {slide_index} = this.state;
        var {currentpage} = this.props;
        var gallery_items = currentpage.slider || [];

        var newGallery = slide_index;

        if(slide_index == 0){
          newGallery = gallery_items.length-1;
        }
        else{
          newGallery = slide_index-1;
        }

        this.setState({slide_index: newGallery});
        clearInterval(this.interval);
    }

    onAutoShow(){
        
        var {slide_index} = this.state;
        var {currentpage} = this.props;
        var gallery_items = currentpage.slider || [];
        var newGallery = slide_index;

        if(slide_index == gallery_items.length-1){
          newGallery = 0;
        }
        else{
            newGallery = slide_index+1;
        }

        this.setState({slide_index: newGallery});
    }

    onStopShow(){
      console.log("STOPING SHOW");
      this.setState({auto_show: false});
      clearInterval(this.interval);
    }


    

    render(){
        
        var {slide_index} = this.state;
        var {currentpage, currentblocks} = this.props;
        var {title, description, title_html, description_html, page_action_button, page_bj_color, page_layout} = currentpage;
        
        //Page Settings
        var block_btn = page_action_button || {}; 
        var block_class = Page_Settings(currentpage);

        //New Edits
        var background_color = page_bj_color || '#ffffff';
        var text_color = pickTextColorBasedOnBgColorAdvanced(page_bj_color, '#FFFFFF', '#000000');
        var general_styles = {
            color: text_color,
            background: background_color
        }


        var slider_array = currentpage.slider || [];
        var slider_items = slider_array.map((item, index)=> {

            var isSlideshow = AVAILABLE_LAYOUTS.includes(page_layout);
            var isActive = (isSlideshow && slide_index == index);

            return (
                <Card_Element key={index} payload={item} index={index} isActive={isActive} isSlideshow={isSlideshow} />   
            )

        })

    
        return(

            <>
                <div className={block_class} id="collagePage" style={general_styles}>
                           
                    <div className="collage-info">
                        <div className="collage-text">

                            <div className="collage-text-title">
                                 <Block_Textbox type="page_title" content={title} content_html={title_html} />   
                            </div>
                            <div className="collage-text-description">
                              
                                <Block_Textbox type="page_description" content={description} content_html={description_html} /> 
                                <Block_Button  block_btn={block_btn} />  
                            </div>
                             
                        </div>
                    </div>
                    
        
                    
                    {slider_items && (

                        <div className="collage-photos">
                            <div></div>

                            {AVAILABLE_LAYOUTS.includes(page_layout) && (
                              <div className="design-block-gallery-nav">
                                {slider_items.length > 1 ? <span className="prev" onClick={()=>this.onBackSlide()}><i className="fa fa-chevron-left" /></span> : null}
                                {slider_items.length > 1 ? <span className="next" onClick={()=>this.onNextSlide()}><i className="fa fa-chevron-right" /></span> : null}
                              </div>
                            )}

                            <div className="collage-photos-wrapper">
                        
                                {slider_items}
                                
                            </div>
                        </div>
                    )}

                        
                </div>

                {currentblocks}
               
            </>

        )
    }
};


const Card_Element = ({payload, isActive, isSlideshow}) =>{

    if(isSlideshow){
        var cover_styles =  !isActive ? {opacity: '0'} : {}
        return (
            <div className="collage-photo" style={cover_styles}>
                <img src={forceSSL(payload)} />
            </div>
        )
    }

    return (

        <div className="collage-photo">
            <img src={forceSSL(payload)} />
        </div>
    )
}

const mapStateToProps = ({ mode_preview }) => {
    return {
       
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
  
    }, dispatch)
  }
export default connect(mapStateToProps, mapDispatchToProps)(Layout_Cover)
