/* User */

export const GET_CURRENTUSER = 'get_currentuser';
export const GET_CURRENTWEBSITE = 'get_currentwebsite';
export const RESET_APP = 'get_resetapp';

/* Dashboard */

export const GET_MYFEEDS = 'get_myfeeds';
export const GET_MYWEBSITES = 'get_mywebsites';
export const GET_MYDESIGNS = 'get_mydesigns';
export const GET_MYORDERS = 'get_myorders';
export const GET_MYAUDIENCE = 'get_myaudience';
export const GET_MYSTORES = 'get_mystores';
export const GET_MYANALYTICS =  'get_myanalytics';
export const GET_DISCOVER = 'get_discover';
export const GET_MYPOSTS = 'get_myposts';
export const GET_MYPRODUCTS = 'get_myproducts';
export const GET_MYNOTIFICATIONS = 'get_mynotifications';

export const HANDLE_MODAL_WEBSITESPICKER = 'handle_modal_websitepicker';
export const HANDLE_MODAL_SOCIALMEDIA = 'handle_modal_socialmedia';



/* Analytics */

export const GET_FULLANALYTICS = 'get_fullanalytics';


/* Mode Live & Preview */

export const GET_FULLWEBSITE_BASICINFO = 'get_fullwebsite_basicinfo';
export const GET_FULLWEBSITE_STYLE = 'get_fullwebsite_style';
export const GET_FULLWEBSITE_NAVIGATION = 'get_fullwebsite_navigation';
export const GET_FULLWEBSITE_PAGES = 'get_fullwebsite_pages';
export const GET_FULLWEBSITE_BLOCKS = 'get_fullwebsite_blocks';
export const GET_FULLWEBSITE_CONTACT = 'get_fullwebsite_contact';
export const GET_FULLWEBSITE_SOCIALMEDIA = 'get_fullwebsite_socialmedia';
export const GET_FULLWEBSITE_HEADER = 'get_fullwebsite_header';
export const GET_FULLWEBSITE_FOOTER = 'get_fullwebsite_footer';

export const GET_FULLWEBSITE_STORE = 'get_fullwebsite_store';
export const GET_FULLWEBSITE_BLOG = 'get_fullwebsite_blog';
export const GET_FULLWEBSITE_STOREINFO = 'get_fullwebsite_storeinfo';
export const GET_FULLWEBSITE_PRODUCT = 'get_fullwebsite_product';
export const GET_FULLWEBSITE_ARTICLE = 'get_fullwebsite_article';

export const HANDLE_FULLWEBSITE_MAINTENANCE = 'get_fullwebsite_maintenance';

export const HANDLE_MODAL_GALLERY = 'handle_modal_gallery';
export const HANDLE_MODAL_VIDEOS  = 'handle_modal_videos';

export const HANDLE_MODAL_PRODUCTS = 'handle_modal_products';
export const HANDLE_MODAL_BLOGPOST = 'handle_modal_blogpost';
export const HANDLE_MODAL_SELECTPRODUCTS = 'handle_modal_selectproducts';
export const HANDLE_MODAL_SELECTBLOGPOSTS = 'handle_modal_selectblogposts';


/* Mode Edit */

export const EDIT_FULLWEBSITE_BASICINFO = 'edit_fullwebsite_basicinfo';
export const EDIT_FULLWEBSITE_STYLE = 'edit_fullwebsite_style';
export const EDIT_FULLWEBSITE_NAVIGATION = 'edit_fullwebsite_navigation';
export const EDIT_FULLWEBSITE_PAGES = 'edit_fullwebsite_pages';
export const EDIT_FULLWEBSITE_BLOCKS = 'edit_fullwebsite_blocks';
export const EDIT_FULLWEBSITE_CONTACT = 'edit_fullwebsite_contact';
export const EDIT_FULLWEBSITE_SOCIALMEDIA = 'edit_fullwebsite_socialmedia';
export const EDIT_FULLWEBSITE_HEADER = 'edit_fullwebsite_header';
export const EDIT_FULLWEBSITE_FOOTER = 'edit_fullwebsite_footer';

export const EDIT_FULLWEBSITE_STORE = 'edit_fullwebsite_store';
export const EDIT_FULLWEBSITE_BLOG = 'edit_fullwebsite_blog';
export const EDIT_FULLWEBSITE_PRODUCT = 'edit_fullwebsite_product';
export const EDIT_FULLWEBSITE_ARTICLE = 'edit_fullwebsite_article';


export const EDITBAR_CHANGE_BLOG = 'editbar_change_blog';
export const EDITBAR_CHANGE_BLOCKS = 'editbar_change_blocks';
export const EDITBAR_CHANGE_SINGLE_BLOCK = 'editbar_change_single_block';
export const EDITBAR_CHANGE_SINGLE_PAGE = 'editbar_change_single_page';

export const EDITBAR_CHANGE_PAGE_SETTINGS = 'EDITBAR_CHANGE_PAGE_SETTINGS';

export const EDITBAR_CHANGE_HEADER = 'editbar_change_header'
export const EDITBAR_CHANGE_FOOTER = 'edit_change_footer';

export const EDITBAR_CHANGE_SEO = 'editbar_change_seo';
export const EDITBAR_CHANGE_LAYOUT = 'editbar_change_layout';
export const EDITBAR_CHANGE_STYLE = 'editbar_change_style';
export const EDITBAR_CHANGE_COLORS = 'editbar_change_colors';
export const EDITBAR_CHANGE_FONT = 'editbar_change_font';
export const EDITBAR_CHANGE_TEXT = 'editbar_change_text';
export const EDITBAR_CHANGE_SETTINGS = 'editbar_change_settings';

export const EDITBAR_CHANGE_BUTTON = 'editbar_change_button';
export const EDITBAR_CHANGE_PHOTOSLIDER = 'editbar_change_photoslider';
export const EDITBAR_CHANGE_GALLERYPOST = 'editbar_change_gallerypost';
export const EDITBAR_CHANGE_VIDEOPOST = 'editbar_change_videopost';
export const EDITBAR_CHANGE_BLOGPOST = 'editbar_change_blogpost';
export const EDITBAR_CHANGE_FEATURESPOST = 'editbar_change_features';
export const EDITBAR_CHANGE_FAQ = 'editbar_change_faq';
export const EDITBAR_CHANGE_REVIEWS = 'editbar_change_reviews';
export const EDITBAR_CHANGE_TIMELINE = 'editbar_change_timeline';
export const EDITBAR_CHANGE_MENULIST = 'editbar_change_menulist';
export const EDITBAR_CHANGE_STORE = 'editbar_change_store';

export const HANDLE_MODAL_ADDBLOCK = 'handle_modal_addblock';
export const HANDLE_MODAL_APPS = 'handle_modal_apps';

export const HANDLE_MODAL_HEROBUTTON = 'handle_modal_herobutton';
export const HANDLE_MODAL_BUTTON = 'handle_modal_button';
export const HANDLE_MODAL_PHOTOSLIDER = 'handle_modal_photoslider';
export const HANDLE_MODAL_WEBSITEICON = 'handle_modal_websiteicon';
export const HANDLE_MODAL_WEBSITEINFO = 'handle_modal_websiteinfo';
export const HANDLE_MODAL_GALLERYPOST = 'handle_modal_gallerypost';
export const HANDLE_MODAL_VIDEOPOST = 'handle_modal_videopost';

export const HANDLE_MODAL_FAQ = 'handle_modal_faq';
export const HANDLE_MODAL_REVIEWS = 'handle_modal_reviews';
export const HANDLE_MODAL_TIMELINE = 'handle_modal_timeline';
export const HANDLE_MODAL_MENULIST = 'handle_modal_menulist';
export const HANDLE_MODAL_AUTHOR = 'handle_modal_author';

export const HANDLE_MODAL_CUSTOMHTML = 'handle_modal_customhtml';
export const HANDLE_MODAL_CHARTS = 'handle_modal_charts';

export const HANDLE_MODAL_FEATURES = 'handle_modal_features';


export const HANDLE_PICKER_IMAGE = 'handle_picker_image';
export const HANDLE_PICKER_VIDEO = 'handle_picker_video';
export const HANDLE_PICKER_MUSIC = 'handle_picker_music';
export const HANDLE_PICKER_MAP = 'handle_picker_map';
export const HANDLE_PICKER_EDITIMAGE = 'handle_picker_editimage';

export const HANDLE_PICKER_IMAGE_SELECT = 'handle_picker_image_select';
export const HANDLE_PICKER_VIDEO_SELECT = 'handle_picker_video_select';
export const HANDLE_PICKER_MUSIC_SELECT = 'handle_picker_music_select';
export const HANDLE_PICKER_MAP_SELECT = 'handle_picker_map_select';

export const HANDLE_EDITBAR_LAYOUT = 'handle_editbar_layout';
export const HANDLE_EDITBAR_FONT= 'handle_editbar_font';
export const HANDLE_EDITBAR_COLORS = 'handle_editbar_colors';
export const HANDLE_EDITBAR_NAVIGATION = 'handle_editbar_navigation';
export const HANDLE_EDITBAR_FOOTER = 'handle_editbar_footer';
export const HANDLE_EDITBAR_PAGES = 'handle_editbar_pages';
export const HANDLE_EDITBAR_BLOCKS = 'handle_editbar_blocks';
export const HANDLE_EDITBAR_NAVLINK = 'handle_editbar_navlink';
export const HANDLE_EDITBAR_ADDPAGES = 'handle_editbar_addpages';
export const HANDLE_EDITBAR_SETTINGS = 'handle_editbar_settings';

export const HANDLE_EDITBAR_THEMES = 'handle_editbar_themes';

export const HANDLE_UPGRADE_MODAL = 'handle_upgrade_modal';
export const HANDLE_ONBOARDING_MODAL = 'handle_onboarding_modal';
export const HANDLE_HELP_MODAL = 'handle_help_modal';

/* Manage Store */

export const GET_MANAGESTORE = 'get_managestore';
export const GET_MANAGESTORE_PRODUCTS = 'get_managestore_products';
export const GET_MANAGESTORE_CATEGORIES = 'get_managestore_categories';
export const GET_MANAGESTORE_ORDERS = 'get_managestore_orders';
export const GET_MANAGESTORE_COUPONS = 'get_managestore_coupons';
export const GET_MANAGESTORE_PAYMENTS = 'get_managestore_payments';
export const GET_MANAGESTORE_SHIPPING = 'get_managestore_shipping';
export const GET_MANAGESTORE_EMAILNOTIFICATIONS = 'get_managestore_emailnotifications';

export const GET_MANAGESTORE_ORDERS_DETAIL = 'get_managestore_orders_detail';


export const HANDLE_FULLWEBSITE_CART = 'handle_fullwebsite_cart';
export const HANDLE_FULLWEBSITE_BLOG = 'handle_fullwebsite_blog';

// Manage Cart

export const HANDLE_CART_ADD = 'handle_cart_add';
export const HANDLE_CART_REMOVE = 'handle_cart_remove';
export const HANDLE_CART_RESET = 'handle_cart_reset';
export const HANDLE_CART_SETUP = 'handle_cart_setup';
export const HANDLE_CHECKOUT_SHOW = 'handle_checkout_show';
