const Page_Settings = (currentpage) =>{
        
    var page_class_array = [
        'flex-container'
    ];

    var page_settings = currentpage.settings || {};
    var page_align = page_settings.align || '';
    var page_orientation = page_settings.orientation || '';
    var page_direction = page_settings.direction || 'top';

    page_align && page_class_array.push('design-page-align-'+page_align);
    page_orientation && page_class_array.push('image-display-'+page_orientation);

    if(page_direction === 'left'){
        page_class_array.push('cat-left');
    }
    
    return page_class_array.join(' ');
}

export default Page_Settings;