import { applyMiddleware, compose, createStore } from 'redux'
import createRootReducer from './actions/reducers';

import reduxThunk from 'redux-thunk';
import history from './history';

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        reduxThunk
      ),
    ),
  )
  return store
}