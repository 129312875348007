import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


class Layout_Blank extends React.Component {

    componentDidMount(){

        this.props.HandleBodyClasses(this.props.currentpage);
    }

    componentDidUpdate(newProps){

        var newPage = newProps.currentpage.route;
        var oldPage = this.props.currentpage.route;

        if(newPage !== oldPage){
            console.log("MENU CHANGED URL --->");
            this.props.HandleBodyClasses(this.props.currentpage);
        }
    }
    
    render(){
        
        var {currentblocks} = this.props;

        return(

            <div className="blank-page-container">
           
                {currentblocks.length == 0 && 
                        <div className="flex-container">
                            <div className="blank-page-placeholder live-mode">
                                <h1>This Page is Empty</h1>
                                <Link className="btn" to="/">Back Home</Link>
                            </div>
                        </div>
                }

                {currentblocks}
            </div>            
        )
    }
};


const mapStateToProps = ({ mode_preview }) => {

    return {
     
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
     
  
    }, dispatch)
  }
export default connect(mapStateToProps, mapDispatchToProps)(Layout_Blank)
