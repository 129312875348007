import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Layout_404 extends React.Component {

    constructor(props) {
        super(props);   
        
    }

    componentDidMount(){
        this.props.HandleBodyClasses(this.props.currentpage);
    }
   
    render(){


        return(

            <div className="blank-page-container">
             
                <div className="blank-website-placeholder">
                    
                    <h1>Page not found</h1>
                    <div className="add-section-btn" onClick={()=> this.props.history.push('/')}>
                        <i className="fa fa-plus-circle" /> Back Home
                    </div>
                    <img className="blank-page-img bottom" src="/assets/img/blank-web-placeholder.png" />
                </div>
                  
            </div>

        )
    }
};


const mapStateToProps = ({ }) => {
  
    return {
  
    }
}
  
const mapDispatchToProps = dispatch => {
    return bindActionCreators({


    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout_404)

