import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {forceSSL, pickTextColorBasedOnBgColorAdvanced} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Page_Settings from '../general/page_settings';


class Layout_Reviews extends React.Component {

    constructor(props) {
        super(props);   

        this.state={
            slide_index: 0,
            auto_show: true
        }

        this.onNextSlide = this.onNextSlide.bind(this);
        this.onBackSlide = this.onBackSlide.bind(this);
        this.onAutoShow = this.onAutoShow.bind(this);
        this.onStopShow = this.onStopShow.bind(this);
    }


    //Lifecycle
    
    componentDidMount(){

        var {currentpage} = this.props;
        var page_layout = currentpage.page_layout;

        if(page_layout === 'recommendations-1'){

            var page_settings = currentpage.settings || {};

            var block_autoplay = page_settings.autoplay || false;
            var block_timer = page_settings.timer || 5000;

            if(block_autoplay){

                this.interval = setInterval(() => {
                  this.onAutoShow();
                  this.setState({auto_show: true});
                },	 		
                block_timer);

            }

        }

        this.props.HandleBodyClasses(currentpage);

    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }

    componentDidUpdate(newProps){

        var newPage = newProps.currentpage.route;
        var oldPage = this.props.currentpage.route;

        if(newPage !== oldPage){
             this.props.HandleBodyClasses(this.props.currentpage);  
        }
    }


    //SlideShow
    onNextSlide(){
        var {slide_index} = this.state;
        var {currentpage} = this.props;
        var gallery_items = currentpage.reviews_array || [];

        var newGallery = slide_index;

        if(slide_index == gallery_items.length-1){
          newGallery = 0;
        }
        else{
          newGallery = slide_index+1;
        }

        this.setState({slide_index: newGallery});
        clearInterval(this.interval);
    }

    onBackSlide(){
        var {slide_index} = this.state;
        var {currentpage} = this.props;
        var gallery_items = currentpage.reviews_array || [];

        var newGallery = slide_index;

        if(slide_index == 0){
          newGallery = gallery_items.length-1;
        }
        else{
          newGallery = slide_index-1;
        }

        this.setState({slide_index: newGallery});
        clearInterval(this.interval);
    }

    onAutoShow(){
        var {slide_index} = this.state;
        var {currentpage} = this.props;
        var gallery_items = currentpage.reviews_array || [];

        var newGallery = slide_index;

        if(slide_index == gallery_items.length-1){
          newGallery = 0;
        }
        else{
            newGallery = slide_index+1;
        }

        this.setState({slide_index: newGallery});
    }

    onStopShow(){
      console.log("STOPING SHOW");
      this.setState({auto_show: false});
      clearInterval(this.interval);
    }


    
    render(){

        
        
        var {slide_index} = this.state;
        var {currentpage, currentblocks} = this.props;
        var {title, description, title_html, description_html, page_bj_color, page_layout} = currentpage;
        
        //Page Settings
        var block_class = Page_Settings(currentpage);

         //New Edits
         var background_color = page_bj_color || '#ffffff';
         var text_color = pickTextColorBasedOnBgColorAdvanced(page_bj_color, '#FFFFFF', '#000000');
         var general_styles = {
             color: text_color,
             background: background_color
         }

         var page_settings = currentpage.settings || {};
         var reviews_array = currentpage.reviews_array || [];
         var reviews_items = reviews_array.map((item, index)=>  {

            var isActive = (page_layout === 'recommendations-1' && slide_index == index);

            return (
                <Review_Card key={index} payload={item} 
                             index={index} page_settings={page_settings} 
                             isActive={isActive} 
                             
                />
            )
        });

        return(

            <>

                <div className={block_class} id="reviews" style={general_styles}>

                    <div className="reviews-title">
                        <Block_Textbox type="page_title" content={title} content_html={title_html} />  
                        <Block_Textbox type="page_description" content={description} content_html={description_html} />  
                    </div>
                         
                    {reviews_items.length >1 && (
                            <div className="slider-buttons">
                                <button onClick={()=>this.onBackSlide()}>&#10094;</button>
                                <button onClick={()=>this.onNextSlide()}>&#10095;</button>
                            </div>
                    )}
                            
                    {reviews_items.length == 0 ? (

                        <div className="review-placeholder">
                                <strong></strong>
                                <div className="rating star-5">
                                        <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" /> 
                                </div> 
                                <p></p>
                        </div>

                    ) : (
                        reviews_items
                    )}  
                    
                 
                </div>

                {currentblocks}

            </>

        )
    }
};

const Review_Card = ({payload, page_settings, isActive}) =>{

    var {img,title,description, stars} = payload;

    var block_title_size = page_settings.title_size || 30;
    var block_description_size = page_settings.description_size || 16;
    var block_title_color = page_settings.title_color || '#000000';
    var block_description_color = page_settings.description_color || '#000000';

    var block_stars = page_settings.stars ? true : false;
    var block_icon = page_settings.icon ? true : false


    var block_style = !isActive ? {display: 'none'} : {}

    return (

        <div className="review mySlides" style={block_style} >
        
            {!block_icon && (
                <img className="review-profile" src={img || '/assets/img/place_holder.jpg'} />
            )}

            <h4 style={{fontSize: block_title_size+'px'}}>{title}</h4>
          
            {!block_stars && (
                <div className={"rating star-"+stars}>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                </div> 
            )}

            <p  style={{fontSize: block_description_size+'px', whiteSpace: 'pre-wrap'}}>
                {description}
            </p>
        
        </div>
    )
}


const mapStateToProps = ({ }) => {

    return {
       
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({

  
    }, dispatch)
  }
export default connect(mapStateToProps, mapDispatchToProps)(Layout_Reviews)
