import React from 'react'
import axios from 'axios'

import {editorStateFromRaw} from "megadraft";
import {stateToHTML} from 'draft-js-export-html';
import { createMarkup } from '../../../../../helpers/GeneralHelper';

class Legal_Alert extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            isLoading: true,
            legal: null
        }

    }

    componentDidMount(){
       document.body.style.overflow = 'hidden'
       this.onFetchLegal();
    }

    componentWillUnmount(){
        document.body.style.overflow = 'auto'
    }
    onFetchLegal(){
        
          var {web_id, type} = this.props;
          var url = '/api/v1/dashboard/'+web_id+'/legal/'+type;
       
          this.setState({isLoading: true});
          axios.get(url).then(res => {

            console.log("RESULT OF QUERY = ", res.data);

            var result_data = res.data;
            this.setState({
              isLoading: false, 
              legal: result_data.legal || null
            })

          })
          .catch(function(error) {
              // handle error
              console.log(error);
              this.setState({isLoading: false});
          });

    }

    render(){

        var {isLoading,} = this.state;

        var editContent = this.state.legal || null;
        var editLegal = JSON.parse(editContent ? editContent : null);
        var editorState = editorStateFromRaw(editLegal);
        var editorHtml = stateToHTML(editorState.getCurrentContent(),options);


        return (
            <div className="modal active" id="legalModal">
                <div className="modal-content">
                    <div className="close" onClick={()=> this.props.HandleBodyLegal(null)}></div>
                    <div className="modal-content-scroll">
                        {isLoading ? (
                            <div className="generic-loading-spin">
                                <i className="fa fa-spinner fa-spin" /> 
                            </div>
                        ) : (
                            <>
                                {!editContent ? (
                                    <div className="legal-modal-placeholder">
                                        <img src="/assets/img/placeholders/legal.png" />
                                    </div>
                                ) : (
                                    <div dangerouslySetInnerHTML={createMarkup(editorHtml)} id="article_content"/>
                                )}
                               
                            </>
                        )}
                    </div>

                    <div className="modal-bottom-footer">
                        <div className="btn" onClick={()=> this.props.HandleBodyLegal(null)}>Done</div>

                    </div>

                </div>
            </div>
        )
    }
}


var options = {

    blockRenderers: {
        atomic: (block) => {
            var blockType = block.get('type').toLowerCase();
            if (blockType === 'atomic') {
              
                var data = block.getData();
                var blogSubType = data.get("type");
                var blogDisplay = data.get("display");

                if(blogSubType === 'click_imagepicker'){
                    
                    var image_size_type = blogDisplay || 'medium';
                    var image_class_name = 'blog_image_'+image_size_type;
            
                    return '<img width="420" height="315" src="'+data.get("src")+'" class='+image_class_name+'></img>';
                    
                }
                else if(blogSubType === 'click_videopicker'){
                    
                    var video_size_type = blogDisplay || 'medium';
                    var video_class_name = 'blog_video_'+video_size_type;
            
                    return '<iframe width="420" height="315" class="'+video_class_name+'" src="https://www.youtube.com/embed/'+data.get('src')+'"></iframe>';
                    
                }
                else if(blogSubType === 'click_mappicker'){
                    
                    var map_size_type = blogDisplay || 'medium';
                    var map_class_name = 'blog_map_'+map_size_type;
            
                    var mapAddress = data.get('src').replace(' ', '+');
                    console.log("SOURCE = ", data.get('src'));
                    return '<iframe width="420" height="315" class="'+map_class_name+'" src="https://maps.google.com/maps?q='+mapAddress+'k&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>';
                    
                }
                else if(blogSubType === 'click_musicpicker'){
                    
                    var music_type = data.get("music_type");
                    var music_size_type = blogDisplay || 'medium';
                    var music_class_name = 'blog_music_'+music_size_type;
                    
                    var iframe_link = '';

                    if(music_type === 'spotify'){
                        
                        iframe_link = data.get("src");
                    }
                    else if(music_type === 'soundcloud'){

                        iframe_link = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/"+data.get("src")+"&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                    }
                    
            
                    return '<iframe width="420" height="315" class="'+music_class_name+'" src="'+iframe_link+'"></iframe>';
                    
                }
            }
        },
    },
    entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase();
        
        console.log("ENTYT = ", entityType);

        if (entityType === 'link') {
            
          const data = entity.getData();
          
          return {
            element: 'a',
            attributes: {
              href: data.href,
              title: data.title
              
            },
            style: {
                color: '#3192E7'
            }
          };
        }
      },
}


export default Legal_Alert;