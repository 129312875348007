import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {pickTextColorBasedOnBgColorAdvanced} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Button from '../general/block_button';
import Block_Settings from '../general/block_settings';

class Block_Carousel extends React.Component {

    constructor(props) {
        super(props); 

        this.state = {
          current_carousel :0,
          auto_show: true
        }
    
        this.onNextSlide = this.onNextSlide.bind(this);
        this.onBackSlide = this.onBackSlide.bind(this);
        this.onAutoShow = this.onAutoShow.bind(this);
        this.onStopShow = this.onStopShow.bind(this);

    }

    //Lifecycle

    componentDidMount(){

        var {currentblock} = this.props;
        var block_settings = currentblock.settings || {};

        var block_autoplay = block_settings.autoplay || false;
        var block_timer = block_settings.timer || 5000;

        if(block_autoplay){

            console.log("AUTOPLAY CAROUSEL === ", block_settings);
            
            this.interval = setInterval(() => {
              this.onAutoShow();
              this.setState({auto_show: true})
            },	 		
            block_timer);

        }

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    //Navigation
    onNextSlide(){
        var {current_carousel} = this.state;
        var {currentblock} = this.props;
        var carousel_items = currentblock.carousel || [];

        var newCarousel = current_carousel;

        if(current_carousel == carousel_items.length-1){
          newCarousel = 0;
        }
        else{
            newCarousel = current_carousel+1;
        }

        this.setState({current_carousel: newCarousel});
        clearInterval(this.interval);
    }

    onBackSlide(){
        var {current_carousel} = this.state;
        var {currentblock} = this.props;
        var carousel_items = currentblock.carousel || [];

        var newCarousel = current_carousel;

        if(current_carousel == 0){
           newCarousel = carousel_items.length-1;
        }
        else{
          newCarousel = current_carousel-1;
        }

        this.setState({current_carousel: newCarousel});
        clearInterval(this.interval);
    }

    onAutoShow(){
        var {current_carousel} = this.state;
        var {currentblock} = this.props;
        var carousel_items = currentblock.carousel || [];

        var newCarousel = current_carousel;

        if(current_carousel == carousel_items.length-1){
          newCarousel = 0;
        }
        else{
            newCarousel = current_carousel+1;
        }

        this.setState({current_carousel: newCarousel});
    }

    onStopShow(){
      console.log("STOPING SHOW");
      this.setState({auto_show: false});
      clearInterval(this.interval);
    }



    render(){

        var {currentblock} = this.props;
       
        var {current_carousel, auto_show} = this.state;
        var {layout, bg_color} = currentblock;
        
        var block_carousel = currentblock.carousel || [];

        var block_class = Block_Settings(currentblock, 'design-block-carousel');

        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');
       
        return(

               <div className={block_class+" "+layout}  style={{background: bg_color, color: text_color}} onClick={auto_show ? ()=>this.onStopShow() : undefined}>
                   
                     <div className="click-carousel">


                          {block_carousel.length > 1 ? <div className="click-carousel-arrow" onClick={()=>this.onBackSlide()}></div> : null}
                          {block_carousel.length > 1 ? <div className="click-carousel-arrow next" onClick={()=>this.onNextSlide()}></div> : null}
                        
                          {block_carousel.map((item, index) => {

                                  var {title, description, image, action_button} = item;

                                  var focus_obx =  item.focus || {x: 0.5, y: 0.5};
                                  var position_obx = focus_obx.x*100+'% '+focus_obx.y*100+'%';

                                  return (

                                      <div className={"click-carousel-item "+(index == current_carousel ? 'active' : '')} key={index}>
                                          <img className="click-carousel-img" 
                                               src={image} 
                                               style={{ objectPosition: position_obx }}  
                                               data-focus-x={focus_obx.x} 
                                               data-focus-y={focus_obx.y}
                                          />
                                          
                                          <div className="click-carousel-text">
                                              <Block_Textbox type="block_title" content={title} content_html={title} />  
                                              <Block_Textbox type="block_description" content={description} content_html={description} />

                                              <Block_Button  block_btn={action_button} /> 
                                          </div>
                                      </div>

                                  )
                                  

                            })}

                 
                      </div>

                                
                </div>

        )
    }
};


const mapStateToProps = () => {
  
    return {
     
  
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
  
    }, dispatch)
  }

export default connect(mapStateToProps, mapDispatchToProps)(Block_Carousel)

