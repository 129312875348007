import React, {useState} from 'react';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  DateTimePicker
} from "@material-ui/pickers";


import frLocale from "date-fns/locale/fr";
import itLocale from "date-fns/locale/it";
import esLocale from "date-fns/locale/es";
import usLocale from "date-fns/locale/en-US";

import DateRangePickerController from './block_daterange';


const Picker_Controller = (props) => {

    //Get Date Language
    var localeProvider = usLocale;
    var {web_lang, format, minDate, maxDate, onChangeForm, form_name, form_object} = props;

    if(web_lang === 'fr'){
        localeProvider = frLocale
    }
    else if(web_lang === 'it'){
        localeProvider = itLocale
    }
    else if(web_lang === 'es'){
        localeProvider = esLocale
    }
    
    if(format === 'date_range'){
        return (     
            <DateRangePickerController localeProvider={localeProvider} web_lang={web_lang} form_object={form_object} onChangeForm={onChangeForm} form_name={form_name}/> 
        )
    }

    return (

        <MuiPickersUtilsProvider utils={DateFnsUtils}  locale={localeProvider} style={{zIndex : 2223}}>
            <Picker_Types {...props}/>    
        </MuiPickersUtilsProvider>
    )
}


const Picker_Types = ({format, style}) => {

    
    //Choose Date Picker
    const [value, setValue] = useState(new Date());

    if(format === 'time'){
        
        return (
            <TimePicker value={value}  onChange={(newValue) => setValue(newValue)} 
                        format='HH:mm' style={style}   
            />
        )
    }
    else if(format === 'month_year'){
        
        return (
            <DatePicker value={value}  onChange={(newValue) => setValue(newValue)} 
                        format='MMMM yyyy' views={['year', 'month']}  openTo='year'
                        style={style} 
            />
        )
    }
    else if(format === 'day_month_year'){
      
            return (
                <DatePicker value={value}  onChange={(newValue) => setValue(newValue)} 
                            format='dd MMMM yyyy' style={style} 
                />
            )
    }
    else if(format === 'day_month_time'){
    
            return ( 
                <DateTimePicker value={value}  onChange={(newValue) => setValue(newValue)} 
                                format='dd MMM yyyy - HH:mm' variant='dialog' 
                                showTodayButton id="modal_dp" hideTabs style={style} 
                />
            )
    }
    else{
        return (
            <h1>Picker not Found</h1>
        )
    }

}


export default Picker_Controller