import React from 'react';
import axios from 'axios';
import swal from 'sweetalert';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {isURL, validateEmail, pickTextColorBasedOnBgColorAdvanced, getFormSearchParams} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Background from '../general/block_background';
import Block_Button from '../general/block_button';
import Block_Settings from '../general/block_settings';

class Block_Signup extends React.Component {
 
    constructor(props) {
        super(props);   

        this.state = {
            contact_name:'',
            contact_email:'',
            isSending: false
        } 

        this.onSendForm = this.onSendForm.bind(this);
        this.onFormRedirect = this.onFormRedirect.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onSendForm(){
    
        var {web_id, web_lang, currentblock} = this.props;
        var {contact_name, contact_email} = this.state;

        //Checking Fields
        if(contact_name.length == 0){
    
            swal(
                getTranslatedFormAlerts('missing_fields', web_lang), 
                getTranslatedFormAlerts('missing_name', web_lang), 
                "warning"
            );

            return
        }
        if(!validateEmail(contact_email)){
            
            swal(
                getTranslatedFormAlerts('missing_fields', web_lang), 
                getTranslatedFormAlerts('missing_email', web_lang), 
                "warning"
            );
         
            return
        }

        //Generate Form
        var form = {
            id:web_id,
            contact_name: contact_name,
            contact_email: contact_email
        }

        //Checking Form Submit type
        var block_btn = currentblock.block_btn || {}; 
        var button_link = block_btn.button_action || '';
        var button_submit = block_btn.button_submit || 'submit';
        var button_success = block_btn.button_success || getTranslatedFormAlerts('form_success', web_lang);
        var button_search = block_btn.button_search || false;

        if(button_submit === 'submit'){
            return this.onFormSubmit(form, button_success);
        }
        else if(button_submit === 'redirect'){
            return this.onFormRedirect(form, button_link, button_search);
        }
        else if(button_submit === 'submit_redirect'){
            return this.onFormSubmit(form, button_success, button_link, true, button_search);
        }
    
    }

    //Actions
    onFormRedirect(form, link, isSearch){

        if(!form) return;
        if(!link) return;

        //Get Search Params
        var form_redirect_search = getFormSearchParams({name: form.contact_name, email: form.contact_email}); 

        var isExternalUrl = isURL(link);
        if(isExternalUrl){
            if(isSearch){
                window.location.href = link + '?' + form_redirect_search;
            }
            else{
                window.location.href = link;
            }
        }
        else{

            if(isSearch){
                this.props.history.push(link + '?' + form_redirect_search);
            }
            else{
                this.props.history.push(link)
            }

        }

    }

    onFormSubmit(form, message_success, link, isRedirect, isSearch){

        var {web_lang} = this.props;

		const url = '/api/v1/audience/signup_form/send';
		const jwt = localStorage.getItem('jwt_token');

        this.setState({ isSending:true});

		axios.post(url , form ,{ headers: {authorization: jwt}  })
			.then(response => {

            this.setState({
                contact_name:'',
                contact_email:'',
                isSending: false
            });

            var data = response.data || {};
            var error = data.error; 

            if(error){
                return swal(
                    getTranslatedFormAlerts('form_already', web_lang), 
                    '',
                    "warning"
                );
            }

            if(isRedirect){
                return this.onFormRedirect(form, link, isSearch);
            }
            else{

                return swal(
                    message_success,
                    '',
                    'success'
                );
               
            }

		})
		.catch(error => {
            console.log(error);
            
            this.setState({
                contact_name:'',
                contact_email:'',
                isSending: false
            });

		});
    }
   

    render(){

        var {currentblock, web_lang} = this.props;
        var {title, description, title_html, description_html, layout,bg_color, bg_image} = currentblock;
        
        var {contact_name, contact_email, isSending} = this.state;

        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');

        var block_btn = currentblock.block_btn || {}; 
        var block_class = Block_Settings(currentblock);

        return(

            <>
                
                <div className={block_class} style={{background: bg_color, color: text_color}}>
                    
                    {bg_image && <Block_Background bg_image={bg_image} block_type="block_signup"/>}
                    
                    <div className={"design-block-signup "+layout}>
                        <div className="design-block-signup-wrapper-description">
                            <Block_Textbox type="block_title" content={title} content_html={title_html} />  
                            <Block_Textbox type="block_description" content={description} content_html={description_html} />
                        </div>

                        <div className="design-block-signup-form">
                            <input placeholder={getTranslatedFormFields("full_name", web_lang)} type="text"  value={contact_name} onChange={(e)=>this.setState({contact_name: e.target.value})} />
                            <input placeholder={getTranslatedFormFields("email", web_lang)} type="email"  value={contact_email} onChange={(e)=>this.setState({contact_email: e.target.value})}/>
                           
                            <Block_Button type='block' subtype='signup' block_btn={block_btn} isSending={isSending} onSendForm={this.onSendForm}/>
                            
                        </div>
                    </div>
                    
                </div>
              
      
            </>

        )
    }
};

const getTranslatedFormFields = (type, lang) => {

    if(lang === 'fr'){
        if(type === 'full_name'){
            return 'Nom'
        }
        else if(type === 'email'){
            return 'Email'
        }
    }
    else if(lang === 'it'){
        if(type === 'full_name'){
            return 'Nome'
        }
        else if(type === 'email'){
            return 'Email'
        }
    }
    else if(lang === 'es'){
        if(type === 'full_name'){
            return 'Nombre'
        }
        else if(type === 'email'){
            return 'Email'
        }        
    }
    else {
        if(type === 'full_name'){
            return 'Name'
        }
        else if(type === 'email'){
            return 'Email'
        }
    }
}

const getTranslatedFormAlerts = (type, lang) => {
    
    var tr = '';

    if(type === 'missing_fields'){
        switch (lang) {
            case 'fr':
                tr = 'Champs Manquants'
                break;
            case 'it':
                tr = 'Campi Mancanti'
                break;
            case 'es':
                tr = 'Campos Faltantes'
                break;
            default:
                tr = 'Missing Fields'
                break;
        }
    }
    else if(type === 'missing_name'){
        switch (lang) {
            case 'fr':
                tr = 'Vous devez ajouter votre nom pour soumettre le formulaire d\'inscription'
                break;
            case 'it':
                tr = 'È necessario aggiungere il tuo nome per inviare il modulo di registrazione'
                break;
            case 'es':
                tr = 'Debe agregar su nombre para enviar el formulario de registro'
                break;
            default:
                tr = 'You need to add your name to submit the signup form'
                break;
        }
    }
    else if(type === 'missing_email'){
        switch (lang) {
            case 'fr':
                tr = 'Vous devez ajouter une adresse e-mail valide pour soumettre le formulaire d\'inscription'
                break;
            case 'it':
                tr = 'È necessario aggiungere il tuo indirizzo email per inviare il modulo di registrazione'
                break;
            case 'es':
                tr = 'Debe agregar una dirección de correo electrónico válida para enviar el formulario de registro'
                break;
            default:
                tr = 'You need to add a valid email address to submit the signup form'
                break;
        }
    }
    else if(type === 'form_success'){
        switch (lang) {
            case 'fr':
                tr = 'Vous vous êtes inscrit avec succès sur notre site Web'
                break;
            case 'it':
                tr = 'Si é registrato con successo al nostro sito web'
                break;
            case 'es':
                tr = 'Se registró correctamente en nuestro sitio web'
                break;
            default:
                tr = 'You signed up successfully to our website'
                break;
        }
    }
    else if(type === 'form_already'){
        switch (lang) {
            case 'fr':
                tr = 'Vous vous êtes déjà inscrit sur notre site Web'
                break;
            case 'it':
                tr = 'Si é già iscritto al nostro sito web'
                break;
            case 'es':
                tr = 'Ya te registraste en nuestro sitio web'
                break;
            default:
                tr = 'You signed up successfully to our website'
                break;
        }
    }
    

    return tr

}



const mapStateToProps = ({ mode_preview }) => {

  return {
    web_id: mode_preview.basic_info.id,
    web_lang: mode_preview.basic_info.language,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Block_Signup)
