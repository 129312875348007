import React from 'react';
import axios from 'axios';

import InfiniteScroll from 'react-infinite-scroller';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { pickTextColorBasedOnBgColorAdvanced } from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Page_Settings from '../general/page_settings';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
 

class Layout_Blog extends React.Component {

    constructor(props) {
        super(props);   

        this.state={
            selected_type: 'all',
            data_index: 0,
            data_items: [],
            data_loading: true,
            data_total: 0
        }
    
      
        this.onFetchArticles = this.onFetchArticles.bind(this);
        this.onSortArticles = this.onSortArticles.bind(this);

    }

    //Lifecycle
    componentDidMount(){
        var {currentpage} = this.props;
        this.props.HandleBodyClasses(currentpage);

        setTimeout(() => {
			this.onFetchArticles();
          }, 300);
    }

    componentDidUpdate(newProps){

        var newPage = newProps.currentpage.route;
        var oldPage = this.props.currentpage.route;

        if(newPage !== oldPage){
            console.log("MENU CHANGED URL --->");
            this.props.HandleBodyClasses(this.props.currentpage);
        }
    }


    //Functions
    onFetchArticles(){
        
        var {web_id} = this.props;
        var {selected_type, data_index, data_items} = this.state;
        
        var jwt = localStorage.getItem('jwt_token');
        var req_url = '/api/v1/builder/websites/articles/categories/live';
        var req_form = {
           id: web_id,
           cat: selected_type,
           query: data_index
        }

        axios.post(req_url, req_form, {headers: {authorization: jwt}}).then(res => {

            console.log('FETCHED PRODUCTS ==== ', res.data);

            var new_index = res.data.next_page || '';
            var new_items = res.data.array || [];
            var total_count = res.data.total || 0;

            this.setState({
                data_index: new_index,
                data_items: [...data_items, ...new_items],
                data_loading: false,
                data_total: total_count
            });

        })
        .catch((error) => {
            // handle error
            console.log(error);
            this.setState({data_loading: false});
        });

    };
 
    onSortArticles(type){
        this.setState({
            selected_type: type,
            data_index: 0,
            data_items: [],
            data_loading: true,
            data_total: 0
        });
        setTimeout(() => {
          this.onFetchArticles(type);
        }, 300);
    }
   
    
    render(){


        var {selected_type, data_items, data_total, data_loading} = this.state;

        var { 
              
            title, 
            description, 
            title_html, 
            description_html,
            page_bj_color,

        } = this.props.currentpage;

        var {currentpage, currentblocks, web_categories} = this.props;
    
       
       
        //Page Settings
      
        var block_class = Page_Settings(currentpage);
        var page_settings = currentpage.settings || {}

        //New Edits
        var background_color = page_bj_color || '#ffffff';
        var text_color = pickTextColorBasedOnBgColorAdvanced(page_bj_color, '#FFFFFF', '#000000');
        var general_styles = {
            color: text_color,
            background: background_color
        }


         //Page Articles
         var articles_items = data_items.map((d, index)=> {
            return ( 
                <Article_Card key={index} payload={d} index={index} page_settings={page_settings} /> 
            )
        });    
    
        //Page Categories
        var blog_categories_items = web_categories.map((item)=>{
            var title = item.title;
            return (
                <div className={selected_type === item.id ? 'active' : ''} onClick={()=>this.onSortArticles(item.id)} key={item.id}>
                    {title}
                </div>
            )
        });

        return(
 
            <>  
                <div className={block_class} id="blogContainer" style={general_styles} >

                    
                        {/* Blog Top Section */}

                        <div className="gallery-title-wrapper">
                            <div className="gallery-title">          
                                <Block_Textbox type="page_title" content={title} content_html={title_html} />  
                                <Block_Textbox type="page_description" content={description} content_html={description_html} /> 
                            </div>

                            {/* Blog Categories */}

                            {blog_categories_items.length != 0 && (
                                <div className="cat-filter-nav">
                                    <div className={selected_type === 'all' ? 'active' : ''} onClick={()=>this.onSortArticles('all')}>All</div>
                                    {blog_categories_items}                            
                                </div>
                            )}
                            
                        </div>

                        {data_loading ? (
                                /* Loading Articles */
                                <div className="generic-loading-spin">
                                    <i className="fa fa-spinner fa-spin" /> 
                                </div>
 
                        ) : (
                                /* Articles Container */
                            
                               <>
                                    {articles_items.length == 0 ? (
                                        <Article_Placeholder  />
                                    ): (

                                        <InfiniteScroll
                                            className="blog-grid-container"
                                            pageStart={0}
                                            loadMore={this.onFetchArticles}
                                            hasMore={(articles_items.length >= data_total) ? false : true}
                                            loader={
                                                <div className="generic-loading-spin" key={1000}>
                                                    <i className="fa fa-spinner fa-spin" /> 
                                                </div>
                                            }
                                        >
                                            {articles_items}
                                        </InfiniteScroll>
                                       
                                    )}

                                </>

                        )}

                    </div>

                {currentblocks}
              
            </>

        )
    }
};





const Article_Card = ({payload, page_settings}) =>{


    var {title, description, thumbnail, date, id} = payload;

    var block_title_size = page_settings.title_size || 23;
    var block_description_size = page_settings.description_size || 18;
    var block_subtitle_size = page_settings.subtitle_size || 18;
    var block_extra_size = page_settings.extra_size || 12;

    var block_title_color = page_settings.title_color || '#000000';
    var block_description_color = page_settings.description_color || '#000000';
    var block_subtitle_color = page_settings.subtitle_color || '#ff0000';
    var block_extra_color = page_settings.extra_color || '#38964c';

    //Metadata
    var block_format = page_settings.format || 'simple';
    if(block_format === 'metadata'){
        var blog_metadata = payload.metadata || {};
        if(blog_metadata.title){
            title = blog_metadata.title;
        }
        if(blog_metadata.description){
            description = blog_metadata.description;
        }
    }

    return (

        <Link className="blog-card" to={'/blog/'+id}>
            <div className="cart-item-trigger"></div>
            
            <div className="blog-card-image">
                <img src={thumbnail} alt={title} />
            </div>
            <div className="blog-card-description">
                <h1>{title}</h1>
                <p>{description}</p>
                <span><Moment date={date} format="DD MMMM YYYY" /></span>
            </div>	
        </Link>
    )
}

const Article_Placeholder = () =>{

    return (
        <div className="empty-store-product-picker">
            <img src="/assets/img/placeholders/empty-store.png" />
            <h4 style={{marginTop: '20px'}}>Blog is empty</h4>
        </div>
    )
}


const mapStateToProps = ({ mode_preview}) => {
  
    return {
      web_id : mode_preview.basic_info.id,
      web_categories: mode_preview.blog.categories || []
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
      
  
    }, dispatch)
  }

export default connect(mapStateToProps, mapDispatchToProps)(Layout_Blog)

