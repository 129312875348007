import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {forceSSL, pickTextColorBasedOnBgColorAdvanced, getBadgesArray} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Button from '../general/block_button';
import Page_Settings from '../general/page_settings';

class Layout_Hero extends React.Component {

    constructor(props) {
        super(props);
     

    }

    componentDidMount(){

        this.props.HandleBodyClasses(this.props.currentpage);
    }

    componentDidUpdate(newProps){

        var newPage = newProps.currentpage.route;
        var oldPage = this.props.currentpage.route;

        if(newPage !== oldPage){
            console.log("MENU CHANGED URL --->");
            this.props.HandleBodyClasses(this.props.currentpage);
        }
    }
    
    render(){

        var {currentpage, currentblocks} = this.props;
        var {title, description, title_html, description_html, page_action_button, page_bj_color, slider} = currentpage;
        
        //Page Settings
        var block_btn = page_action_button || {}; 
        var block_class = Page_Settings(currentpage);

        //New Edits
        var background_color = page_bj_color || '#ffffff';
        var text_color = pickTextColorBasedOnBgColorAdvanced(page_bj_color, '#FFFFFF', '#000000');
        var general_styles = {
            color: text_color,
            background: background_color
        }

            //Badges

        var page_badges = currentpage.badges || {};

        const badges_array = page_badges ? getBadgesArray(page_badges) : [];
        const badges_items = badges_array ? badges_array.map((d,index) => {

            var image_url = "";

            if(d.type === 'applestore'){

                image_url = "/assets/img/hero/hero-"+d.type+".jpg";
            }
            else{

                var image_url = "/assets/img/hero/hero-"+d.type+".png";
            }

            return(
                <a href={d.url} key={index}>
                    <div key={index}>
                        <img src={forceSSL(image_url)} />    
                    </div>
                </a>
            )
            
        }) : [];

       
        return(
         
            <>

                <div className={block_class} id="startupContent" style={general_styles}>
                    
                    <div className="startup-hero">
                        
                        <div className="startup-text">
                            
                            <Block_Textbox type="page_title" content={title} content_html={title_html} />  
                            <Block_Textbox type="page_description" content={description} content_html={description_html} />  
                            
                            <Block_Button block_btn={block_btn} />

                            <div className="hero-app-buttons">
                                {badges_items}
                            </div>

                        </div>

                        {slider && (

                            <div className="startup-screens">
                                
                                <div className="desktop-screen">
                                
                                    {slider[0] ? <img src={slider[0]} /> : <img src="/assets/img/placeholders/screen-1.jpg" />}
                                </div>
                                <div className="phone-screen-startup">
                                   
                                    {slider[1] ? <img src={slider[1]} /> : <img src="/assets/img/placeholders/app-1.jpg" />}
                                </div>
                                <div className="phone-screen-startup second">
                                    
                                    {slider[2] ? <img src={slider[2]} /> : <img src="/assets/img/placeholders/app-2.jpg" />}
                                </div>
                            </div>


                        )}

                    </div>
                    
                </div>
              
                {currentblocks}

            </>
        )
    }
};


const mapStateToProps = ({ mode_preview }) => {

    return {
       
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
      
  
    }, dispatch)
  }
export default connect(mapStateToProps, mapDispatchToProps)(Layout_Hero)
