import React from 'react'
import {addDays, addMonths, addWeeks, addYears, isBefore} from "date-fns";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

class DateRangePickerController extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            selected_period: '1_month',
            selected_range : {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            }
        }

        this.onSelectRange = this.onSelectRange.bind(this);
        this.onSelectPeriod = this.onSelectPeriod.bind(this);
    }

    componentDidMount(){

        var {form_object} = this.props;

        var form_min = form_object.min;
        var form_max = form_object.max;
        var form_min_type = form_object.min_type || 'fixed';
        var form_max_type = form_object.max_type || 'fixed';
        var form_min_days = form_object.min_days;
        var form_max_days = form_object.max_days;

        //Setup Min Date
        var minDate = (form_min && form_min !== 'Invalid Date') ? new Date(form_min) : ''
        if(form_min_type === 'dynamic'){
            if(minDate){
                minDate = addDays(new Date(), form_min_days)
            }
        }

        //Setup Max Date
        var maxDate = (form_max && form_max !== 'Invalid Date') ? new Date(form_max) : ''
        if(form_max_type === 'dynamic'){
            if(maxDate){
                maxDate = addDays(new Date(), form_max_days)
            }
        }

    
        if(isBefore(new Date, minDate)){
            var startDate = minDate;
            this.setState({
                selected_period: 'custom',
                selected_range: {
                    startDate: startDate,
                    endDate: startDate,
                    key: 'selection',
                }
            })
        }

    }

    onSelectPeriod(period){

        var {onChangeForm, form_name} = this.props;
        var {selected_range} = this.state;

        var startDate = selected_range.startDate || new Date();
        var endDate = new Date();

        switch (period) {
            case '1_month':
                endDate = addMonths(startDate, 1)
                break;
            case '2_month':
                endDate = addMonths(startDate, 2)
                break;
            case '3_month':
                endDate = addMonths(startDate, 3)
                break;
            case '4_month':
                endDate = addMonths(startDate, 4)
                break;
             case '5_month':
                endDate = addMonths(startDate, 5)
                break;    
            case '6_month':
                endDate = addMonths(startDate, 6)
                break;
            case '7_month':
                endDate = addMonths(startDate, 7)
                break;
            case '8_month':
                endDate = addMonths(startDate, 8)
                break;
            case '9_month':
                endDate = addMonths(startDate, 9)
                break;
            case '10_month':
                endDate = addMonths(startDate, 10)
                break;
            case '11_month':
                endDate = addMonths(startDate, 11)
                break;
            case '12_month':
                endDate = addMonths(startDate, 12)
                break;

            default:
                break;
        }
        
        endDate = addDays(endDate, -1);

        this.setState({
            selected_period: period,
            selected_range: {
                startDate: startDate,
                endDate: endDate,
                key: 'selection',
            }
        })

        onChangeForm(form_name, {from: startDate, to: endDate});

    }
    onSelectRange(ranges){

        var {onChangeForm, form_name} = this.props;
        
        var selected_range = ranges.selection || {};
        var startDate = selected_range.startDate || new Date();
        var endDate = selected_range.endDate || new Date();
        
        this.setState({
            selected_period: 'custom',
            selected_range: {...ranges.selection}
        });

        onChangeForm(form_name, {from: startDate, to: endDate});
    }

    render(){

        var {selected_range, selected_period} = this.state;
        var {localeProvider, web_lang, form_object} = this.props;

        var form_min = form_object.min;
        var form_max = form_object.max;
        var form_min_type = form_object.min_type || 'fixed';
        var form_max_type = form_object.max_type || 'fixed';
        var form_min_days = form_object.min_days || 0;
        var form_max_days = form_object.max_days || 0;

        //Setup Min Date
        var minDate = (form_min && form_min !== 'Invalid Date') ? new Date(form_min) : ''
        if(form_min_type === 'dynamic'){
            if(minDate){
                minDate = form_min_days ? addDays(new Date(), form_min_days) : new Date();
            }
        }

        //Setup Max Date
        var maxDate = (form_max && form_max !== 'Invalid Date') ? new Date(form_max) : ''
        if(form_max_type === 'dynamic'){
            if(maxDate){
                maxDate = form_max_days ? addDays(new Date(), form_max_days) : new Date()
            }
        }

        console.log("MIN DATE ====> ", minDate);
        console.log("MAX DATE ====> ", maxDate);

        return (
            <>
                <DatePickerPeriodArray selected_period={selected_period} onSelectPeriod={this.onSelectPeriod} web_lang={web_lang}/>

                {(minDate && maxDate) ? (
                    <DateRange
                        ranges={[selected_range]}
                        onChange={this.onSelectRange}
                        locale={localeProvider}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                ) : (
                    <>
                        {minDate && (
                            <DateRange
                                ranges={[selected_range]}
                                onChange={this.onSelectRange}
                                locale={localeProvider}
                                minDate={minDate}
                            />
                        )}

                        {maxDate && (
                            <DateRange
                                ranges={[selected_range]}
                                onChange={this.onSelectRange}
                                locale={localeProvider}
                                minDate={minDate}
                            />
                        )}

                        {(!minDate && !maxDate) &&(
                           <DateRange
                                ranges={[selected_range]}
                                onChange={this.onSelectRange}
                                locale={localeProvider}
                            />
                        )}

                    </>
                )}
                
            </>
        )
    }
}



const DatePickerPeriodArray = ({web_lang, selected_period, onSelectPeriod}) =>{

    if(web_lang === 'it'){
        return (
            <select value={selected_period} onChange={(e)=>onSelectPeriod(e.target.value)} style={{marginBottom: '10px'}}>
                <option value="1_month">1 Mese</option>
                <option value="2_month">2 Mesi</option>
                <option value="3_month">3 Mesi</option>
                <option value="4_month">4 Mesi</option>
                <option value="5_month">5 Mesi</option>
                <option value="6_month">6 Mesi</option>
                <option value="7_month">7 Mesi</option>
                <option value="8_month">8 Mesi</option>
                <option value="9_month">9 Mesi</option>
                <option value="10_month">10 Mesi</option>
                <option value="11_month">11 Mesi</option>
                <option value="12_month">12 Mesi</option>
                <option value="custom">Personalizzato</option>
            </select>
        )

    }

    return (
        <select value={selected_period} onChange={(e)=>onSelectPeriod(e.target.value)} style={{marginBottom: '10px'}}>
            <option value="1_month">1 Month</option>
            <option value="2_month">2 Months</option>
            <option value="3_month">3 Months</option>
            <option value="4_month">4 Months</option>
            <option value="5_month">5 Months</option>
            <option value="6_month">6 Months</option>
            <option value="7_month">7 Months</option>
            <option value="8_month">8 Months</option>
            <option value="9_month">9 Months</option>
            <option value="10_month">10 Months</option>
            <option value="11_month">11 Months</option>
            <option value="12_month">12 Months</option>
            <option value="custom">Custom</option>
        </select>
    )
}

export default DateRangePickerController