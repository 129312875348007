import React from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Block_Textbox from '../general/block_textbox';

class Layout_Videos extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
            currentCategory: 'all',

            isVideoModalOpened: false,
            currentVideoIndex: 0
        }  

        this.HandleCategoryChange = this.HandleCategoryChange.bind(this);
        this.HandleVideoModal = this.HandleVideoModal.bind(this);
       
        this.HandleBack = this.HandleBack.bind(this);
        this.HandleNext = this.HandleNext.bind(this);

        this.HandleVideoOnLoad = this.HandleVideoOnLoad.bind(this);
    }

   
    componentDidMount(){

        this.props.HandleBodyClasses(this.props.currentpage);
        var route_params = getQueryStringParams(window.location.search);
   
        var gallery_video = route_params.uid || '';

        if(gallery_video){
            this.HandleVideoOnLoad(gallery_video);
        }
    }

    componentDidUpdate(newProps){

        var newPage = newProps.currentpage.route;
        var oldPage = this.props.currentpage.route;

        if(newPage !== oldPage){
            console.log("MENU CHANGED URL --->");
            this.props.HandleBodyClasses(this.props.currentpage);
        }
    }

    

    HandleVideoOnLoad(uid){

        var { videos_array } = this.props.currentpage;

        console.log("MARCH ===>", videos_array);

        var video_items = videos_array || [];
        var find_index =  video_items.length > 0 ? video_items.findIndex(post => post.id === uid)  : -1;
    
        document.body.classList.add('gallery-modal-active');

        this.setState({
            isVideoModalOpened : true,
            currentVideoIndex: find_index
        })
       
    }

    HandleVideoModal(){

        var {route} = this.props.currentpage;

        document.body.classList.remove('gallery-modal-active');

        this.setState({
            isVideoModalOpened : false
        })        

        this.props.history.push('/'+route);

    }

    HandleNext(currentVideoIndex){

        var {currentCategory} = this.state;
        var { videos_array, route} = this.props.currentpage;
        var filtred_videos = [];
        
        if(currentCategory == 'all'){
            filtred_videos = videos_array || [];
        }
        else{
            filtred_videos = videos_array ? videos_array.filter((f) => f.category.includes(currentCategory)) : [];
        }
    
        if(currentVideoIndex != filtred_videos.length-1){

            this.setState({
                currentVideoIndex: currentVideoIndex+1
            });

            var post_id = filtred_videos[currentVideoIndex+1].id;
            this.props.history.push('/'+route+'?uid='+post_id);

        }

       
    }

    HandleBack(currentVideoIndex){

        var {currentCategory} = this.state;

        var { videos_array, route} = this.props.currentpage;
        var filtred_videos = [];
        
        if(currentCategory == 'all'){
            filtred_videos = videos_array || [];
        }
        else{
            filtred_videos = videos_array ? videos_array.filter((f) => f.category.includes(currentCategory)) : [];
        }

        if(currentVideoIndex != 0){

            this.setState({
                currentVideoIndex: currentVideoIndex-1
            })

            var post_id = filtred_videos[currentVideoIndex-1].id;
            this.props.history.push('/'+route+'?uid='+post_id);
        }

    }

    HandleCategoryChange(category_id){
        this.setState({currentCategory: category_id});
    }

    render(){

        var {currentCategory, isVideoModalOpened} = this.state;

        var { 
              
            title, 
            description, 
            title_html, 
            description_html, 
            videos_array,
            categories,
            route     
              
        } = this.props.currentpage;

        var { currentblocks, currentpage} = this.props;
       
        //Filter Videos by Category

        var filtred_videos = [];
        
        if(currentCategory == 'all'){
            filtred_videos = videos_array || [];
        }
        else{
            filtred_videos = videos_array ? videos_array.filter((f) => f.category.includes(currentCategory)) : [];
        }

        var videos_items = filtred_videos ? filtred_videos.map((d, index)=>  <Card_Video key={index} 
                                                                                         payload={d}  
                                                                                         index={index}
                                                                                         HandleVideoModal={this.HandleVideoModal} 
                                                                                         HandleSelectPost={this.HandleSelectPost}
                                                                                         isVideoModalOpened ={this.state.isVideoModalOpened}
                                                                                         currentVideoIndex = {this.state.currentVideoIndex}
                                                                                         HandleVideoOnLoad={this.HandleVideoOnLoad}
                                                                                         route={route}
                                                                                         />) : [];


        var categories_items = categories ? categories.map((d, index)=> <Category_Video key={index} 
                                                                                        payload={d} 
                                                                                        index={index} 
                                                                                        currentCategory={currentCategory} 
                                                                                        HandleCategoryChange={this.HandleCategoryChange}
                                                                                        HandleVideoOnLoad={this.HandleVideoOnLoad}
                                                                                        route={route}
                                                                                        /> ) : [];
       
        return(

            <>

                <div className="flex-container" id="videoContents" style={{background:currentpage.page_bj_color}}>
                        <div className="gallery-title-wrapper">
                            <div className="gallery-title">
                                <Block_Textbox type="page_title" content={title} content_html={title_html} />  
                                <Block_Textbox type="page_description" content={description} content_html={description_html} />  
                            </div>
                        </div>

                        <div className="video-categories">
                            <div className={"playlist-item "+(currentCategory == 'all' ? 'active' : '')} onClick={()=>this.setState({currentCategory: 'all'})}>All</div>
                            {categories_items}
                        </div>

                        <div className="videos-grid">
                            {videos_items.length == 0 
                                
                                ?   
                                <div className="product-grid">
                                        <div className="empty-store-placeholder">
                                            <div className="store-placeholder-title">
                                                <h1>This website does not have any video yet.</h1>
                                            </div>
                                            <div className="empty-store-placeholder-item video-ph">
                                            
                                            </div>
                                            <div className="empty-store-placeholder-item video-ph">
                                            
                                            </div>
                                            <div className="empty-store-placeholder-item video-ph">
                                            
                                            </div>
                                        </div>
                                    </div>
                                : 
                                
                                (videos_items)
                        
                            }
                        </div>
                </div>

             
               {currentblocks}
            
            </>
        )
    }
};


const Card_Video = (props) =>{

    var {duration,video,title, id} = props.payload;
    var { HandleVideoOnLoad, route} = props;

    return (

        <Link className="video-box" to={'/'+route+'?uid='+id} onClick={()=>HandleVideoOnLoad(id)}>
        
            <div className="video-box-image">
                <img className="play-icon" src="/assets/img/play-circle.png" />
                <i className="duration">{duration}</i>			  	
                <img className="video-thumb" src={'https://i.ytimg.com/vi/'+video+'/hqdefault.jpg'} />
            </div>
            <h4>{title}</h4>
        </Link>

    )
}

const Category_Video = (props) =>{

    var {index, payload, currentCategory, HandleCategoryChange} = props;

    var category_id = payload.id;
    var category_title = payload.title;

    return (

        <div className={"playlist-item "+(currentCategory == index ? 'active' : '')} 
             onClick={()=>HandleCategoryChange(category_id)}
        
        >
            {category_title}
        </div>

    )
   
}

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};
  


const mapStateToProps = ({ mode_preview }) => {

    return {
       
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
       
  
    }, dispatch)
  }

  
export default connect(mapStateToProps, mapDispatchToProps)(Layout_Videos)
