

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {RemoveFromCart, handleFullwebsite_Cart, ShowCheckout} from '../../../../../actions/mode_preview';

import {forceSSL} from '../../../../../helpers/GeneralHelper';

import NumberFormat from 'react-number-format';

class Checkout_Cart extends React.Component {

    constructor(props) {
        super(props);  
        
        this.onInitialCheckout = this.onInitialCheckout.bind(this);
    }

   
    componentDidMount(){
        document.body.style.overflow = 'hidden';
    }

    componentWillUnmount(){
        document.body.style.overflow = 'auto';
    }

    onInitialCheckout(){

        // //Pixel
        // var {isFbPixel, web_pixel, web_currency, cart} = this.props;

        // if(isFbPixel && web_pixel){

        //     var num_items = cart.length;
        //     var num_ids = [];

        //     var num_total = 0;
        //     for(var i=0; i<cart.length; i++){
        //         var cart_item = cart[i];

        //         var item_id = cart_item.id;
        //         var item_quantity = cart_item.quantity;
        //         var item_total = cart_item.sale ? cart_item.sale : cart_item.cost;
        //         num_total = num_total + (item_total *item_quantity) ;

        //         num_ids.push(item_id);
        //     }

        //     var pixel_data = {
        //         num_items: num_items, 
        //         content_name: 'Checkout',
        //         content_ids: num_ids,
        //         content_type: 'product',
        //         value: num_total,
        //         currency: web_currency 
        //     }

        //     console.log("Pixel Data ===> ", pixel_data, num_total, num_ids);
        //     fbq('track', 'InitiateCheckout', pixel_data);  
        
        // }

        this.props.ShowCheckout(true);
    }

    render(){
        

        var {handleFullwebsite_Cart, RemoveFromCart, cart, history, web_symbol, web_lang, web_currency, store_settings} = this.props;

        var cart_items = cart.map((item, index)=> {
            return (
                <Cart_Cell key={index} 
                           payload={item} 
                           RemoveFromCart={RemoveFromCart} 
                           index={index} 
                           history={history}
                           web_symbol={web_symbol}
                           web_currency={web_currency}
                />
            )
        });
 

        var cta_color = store_settings.extra_color || '#38964c';

        return(

            <div className='modal side active' id="cartModal">
			     <div onClick={()=>handleFullwebsite_Cart(false)} className="cart-modal-close-overlay" />
                <div className="modal-content">

                    <div className="cart-modal-header">
                        <div className="close" id="closeModal" onClick={()=>handleFullwebsite_Cart(false)}></div>
                        {cart.length == 1 ? (
                            <h1>
                                <strong>1 {getTranslatedCart('item', web_lang)}</strong> {getTranslatedCart('in_your_cart', web_lang)}
                            </h1>
                        ) : (   
                            <h1>
                                <strong>{cart.length} {getTranslatedCart('items', web_lang)}</strong> {getTranslatedCart('in_your_cart', web_lang)}
                            </h1>
                        )}
                    </div>
                    <div className="cart-modal-scroll">
                        {cart_items}

                        {/****EMPTY CART PLACEHOLDER
                        <div className="empty-cart-placeholder">
                            <img src="/assets/img/empty-store.png" />
                            <h4>Your cart is empty</h4>
                        </div>***/}
                    </div>
                    <div className="cart-modal-footer">
                        <div className="total-amount">
                        <div className="cart-total-flex">
                                <Cart_Save web_symbol={web_symbol} web_currency={web_currency} cart={cart} web_lang={web_lang} />   
                            </div>
                           <div className="cart-total-flex">
                            <span>{getTranslatedCart('total', web_lang)}</span>
                            
                                <Cart_Total web_symbol={web_symbol} web_currency={web_currency} cart={cart} />   
                            </div> 

                                       
                        </div>

                        {cart.length != 0 && (
                            <div className="cart-checkout-button" id="checkoutButton" onClick={()=>this.onInitialCheckout()} style={{background: cta_color, color: '#ffffff'}}>
                                {getTranslatedCart('proceed_to_checkout', web_lang)}
                            </div>
                        )}
                    </div>  
                </div>

            </div>

        )
    }
};


class Cart_Cell extends React.Component {

    render(){

        var {payload, RemoveFromCart, index, web_symbol} = this.props;

        var title = payload.title || '';
        var icon = payload.icon || '';
        var quantity = payload.quantity;
        var cost = payload.cost;
        var option = payload.option;
        var sale = payload.sale;

        var total_full_cost = cost*quantity;
        var total_sale_cost = sale*quantity;
        

        return (
            <a className="cart-product">
                <img src={forceSSL(icon)}/>

                <div className="cart-product-right">
                <h4>{title}<br/></h4>
                
                

                {total_sale_cost  ? (

                    <div className="cart-product-price" style={{display: 'flex'}}>
                            <i>
                                <Cart_Price prefix={web_symbol} value={total_sale_cost} /> 
                            </i>
                            <span className="cart-product-old-price">                        
                                <i>
                                    <Cart_Price prefix={web_symbol} value={total_full_cost} />
                                </i>
                            </span>
                    </div>
                   
                ) : (
                    <div className="cart-product-price">
                        <i>
                            <Cart_Price prefix={web_symbol} value={total_full_cost} />
                        </i>
                    </div>
                )}
                </div>
                <div className="cart-product-footer">
                        {option && <span>Option: {option}</span>}
                        <span>QTY: {quantity}</span>
                    </div>

                <div className="cart-product-remove" onClick={()=>RemoveFromCart(index)}><i className="fa fa-trash" /></div>

            </a>
        )
    }
}

const Cart_Total = ({cart, web_symbol, web_currency}) =>{

    if(cart.length == 0){
        return <Cart_Price prefix={web_symbol} suffix={web_currency} value={0} /> 
    }
    else{

        var total_cost = 0;
        for(var i=0; i<cart.length; i++){
            var cart_item = cart[i];
            var item_quantity = cart_item.quantity;
            var item_total = cart_item.sale ? cart_item.sale : cart_item.cost;
            total_cost = total_cost + (item_total *item_quantity) ;
        }

        return  <Cart_Price prefix={web_symbol} suffix={web_currency} value={total_cost} /> ;
    }
   
}

const Cart_Save = ({cart, web_symbol, web_currency, web_lang}) =>{
    
    if(cart.length == 0){
        return <></>
    }
    else{

        var total_cost = 0;
        for(var i=0; i<cart.length; i++){
            var cart_item = cart[i];
            var item_quantity = cart_item.quantity;
            var diff_total = cart_item.sale ? cart_item.cost - cart_item.sale : 0;
            total_cost = total_cost + (diff_total *item_quantity);
        }

        if(total_cost == 0){
            return <></>
        }

        return (
            <div className="saved-amount-row">
                   <span> {getTranslatedCart('you_save', web_lang)}  </span>
                    <strong>
                        <Cart_Price prefix={web_symbol} suffix={web_currency} value={total_cost} />
                    </strong>
            </div>
        )
        
    }
}

const Cart_Price = ({prefix, suffix, value}) => {

    return (
        <NumberFormat displayType="text" 
                      thousandSeparator={true} 
                      decimalScale={2} 
                      fixedDecimalScale={2}
                      prefix={prefix}
                      suffix={suffix ? ' '+suffix : ''}  
                      value={value} 
                      renderText={(form_value)=><>{form_value}</>}
        />
    )
}

const getTranslatedCart = (type, lang) => {
    
    var tr = '';

    if(type === 'item'){
        switch (lang) {
            case 'fr':
                tr = 'Article'
                break;
            case 'it':
                tr = 'Articolo'
                break;
            case 'es':
                tr = 'Artículo'
                break;
            default:
                tr = 'Item'
                break;
        }
    }
    else if(type === 'items'){
        switch (lang) {
            case 'fr':
                tr = 'Articles'
                break;
            case 'it':
                tr = 'Articoli'
                break;
            case 'es':
                tr = 'Artículos'
                break;
            default:
                tr = 'Items'
                break;
        }
    }
    else if(type === 'in_your_cart'){
        switch (lang) {
            case 'fr':
                tr = 'dans votre Panier'
                break;
            case 'it':
                tr = 'nel tuo Carrello'
                break;
            case 'es':
                tr = 'en tu Carrito'
                break;
            default:
                tr = 'in your Cart'
                break;
        }
    } 
    else if(type === 'proceed_to_checkout'){
        switch (lang) {
            case 'fr':
                tr = 'Passer à la Caisse'
                break;
            case 'it':
                tr = 'Procedere al Checkout'
                break;
            case 'es':
                tr = 'Finalizar el Pedido'
                break;
            default:
                tr = 'Proceed to Checkout'
                break;
        }
    } 
    else if(type === 'total'){
        switch (lang) {
            case 'fr':
                tr = 'Totale:'
                break;
            case 'it':
                tr = 'Totale:'
                break;
            case 'es':
                tr = 'Total:'
                break;
            default:
                tr = 'Total:'
                break;
        }
    } 
    else if(type === 'you_save'){
        switch (lang) {
            case 'fr':
                tr = 'Vous économisez : '
                break;
            case 'it':
                tr = 'Risparmi : '
                break;
            case 'es':
                tr = 'Ahorras : '
                break;
            default:
                tr = 'You Save : '
                break;
        }
    } 

    return tr

}


const mapStateToProps = ({ mode_preview }) => {

    return {
        cart : mode_preview.cart,
        web_pixel: mode_preview.basic_info.facebook_pixel,
        web_symbol: mode_preview.store.symbol,
        web_currency: mode_preview.store.currency,
        store_settings: mode_preview.style.store || {}
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        RemoveFromCart,
        handleFullwebsite_Cart,
        ShowCheckout
  
    }, dispatch)
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Checkout_Cart)


