import React from 'react'

class Cookies_Alert extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            isVisible: false 
        }

        this.onCloseCookies = this.onCloseCookies.bind(this);
        this.onAcceptCookies = this.onAcceptCookies.bind(this);
        this.onLearnMore = this.onLearnMore.bind(this);
    }

    componentDidMount(){
        const web_cookies = localStorage.getItem('web_cookies');
        if(!web_cookies){
            this.setState({isVisible: true});
        }
    }

    onAcceptCookies(){
        localStorage.setItem('web_cookies', true);
        this.setState({isVisible: false});
    }

    onLearnMore(){
        this.props.HandleBodyLegal('cookies');
    }

    onCloseCookies(){
        this.setState({isVisible: false});
    }

    render(){

        var {isVisible} = this.state;
        var {web_lang} = this.props;

        if(!isVisible){
            return <></>
        }


        return (
            <div className="clickai-cookies-wrapper">
                <div className="clickai-cookies">
                <div className="close-cookies" onClick={()=>this.onCloseCookies()}>
                    <i className="fa fa-times" />
                </div>
                <div className="cookies-message">
                    <h4>{getTranslatedCookies('title', web_lang)}</h4>
                    <p>{getTranslatedCookies('description', web_lang)}</p>
                </div>
                <div className="right">
                    <div className="cookie-btn" onClick={()=>this.onAcceptCookies()}>
                        {getTranslatedCookies('accept', web_lang)}
                    </div>
                    <div className="cookie-btn empty" onClick={()=>this.onLearnMore()}>
                        {getTranslatedCookies('learn_more', web_lang)}
                    </div>
                </div>
                </div>
            </div>
        )
    }
}

const getTranslatedCookies = (type, lang) => {
    
    var tr = '';

    if(type === 'title'){
        switch (lang) {
            case 'fr':
                tr = 'Ce site utilise des cookies'
                break;
            case 'it':
                tr = 'Questo sito utilizza i cookies'
                break;
            case 'es':
                tr = 'Este sitio web utiliza cookies'
                break;
            default:
                tr = 'This website is using cookies'
                break;
        }
    }
    else if(type === 'description'){
        switch (lang) {
            case 'fr':
                tr = 'Nous utilisons des cookies pour rendre le site Web fonctionnel et optimiser votre expérience. Certains cookies sont nécessaires. Avec votre consentement, nous utilisons également des cookies ou des pixels espions à des fins de marketing.'
                break;
            case 'it':
                tr = 'Utilizziamo i cookies per rendere funzionale il sito web e ottimizzare la tua esperienza. Alcuni cookie sono obbligatori. Con il tuo consenso, utilizziamo cookie o pixel di tracciamento anche per scopi di marketing.'
                break;
            case 'es':
                tr = 'Usamos cookies para hacer que el sitio web sea funcional y optimizar su experiencia. Se requieren algunas cookies. Con su consentimiento, también utilizamos cookies o píxeles de seguimiento con fines de marketing.'
                break;
            default:
                tr = 'We uses cookies to make the website functional and optimize your experience. Some cookies are required. With your consent, we also use cookies or tracking pixels for marketing purposes.'
                break;
        }
    }
    else if(type === 'accept'){
        switch (lang) {
            case 'fr':
                tr = 'Accepter les cookies'
                break;
            case 'it':
                tr = 'Accetta i cookies'
                break;
            case 'es':
                tr = 'Acepte las cookies'
                break;
            default:
                tr = 'Accept Cookies'
                break;
        }
    }
    else if(type === 'learn_more'){
        switch (lang) {
            case 'fr':
                tr = 'Voir plus'
                break;
            case 'it':
                tr = 'Scopri di piú'
                break;
            case 'es':
                tr = 'Aprende más'
                break;
            default:
                tr = 'Learn more'
                break;
        }
    }
 

    return tr

}

export default Cookies_Alert;