import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {pickTextColorBasedOnBgColorAdvanced} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Background from '../general/block_background';
import Block_Button from '../general/block_button';
import Block_Settings from '../general/block_settings';

import {Video_Frame} from '../general/page_media';

class Block_Video extends React.Component {

    constructor(props) {
        super(props);   
    }

    componentDidMount(){

    }

    render(){

        var {currentblock} = this.props;
        var {title, description, title_html, description_html, layout,bg_color, video, bg_image} = currentblock;
        
        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');

        var block_btn = currentblock.block_btn || {}; 
        var block_class = Block_Settings(currentblock);

        var block_settings = currentblock.settings || {};

        return(


                <div className={block_class} style={{background: bg_color, color: text_color}}>

                    <div className={"design-block-text-video " + layout}>
                       
                        <div className="design-block-video">
                            <Video_Frame video_url={video} settings={block_settings} />
                        </div>

                        <div className="design-block-video-text">
                                <Block_Textbox type="block_title" content={title} content_html={title_html} />  
                                <Block_Textbox type="block_description" content={description} content_html={description_html} />
                                <Block_Button type='block' block_btn={block_btn} /> 
                        </div>
                    </div>  
                    {bg_image && <Block_Background bg_image={bg_image} />}
                    
                </div>
               

        )
    }
};

const mapStateToProps = ({ mode_preview }) => {

  return {
    
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Block_Video)
