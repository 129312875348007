import React from 'react';
import axios from 'axios';

import {editorStateFromRaw} from "megadraft";
import {stateToHTML} from 'draft-js-export-html';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {forceSSL, pickTextColorBasedOnBgColorAdvanced, createMarkup, validateEmail} from '../../../../../helpers/GeneralHelper';

import { Image_Frame } from '../general/page_media';

import ClickShareButton from '../../../General/Picker_Share';

import swal from 'sweetalert';

class Layout_Article extends React.Component {

 
    constructor(props) {
        super(props);   

        this.state = {
            data: {},
            isLoadingArticle: true,
            isNotFound: false
        }

        this.onFetchArticle = this.onFetchArticle.bind(this);

    }

     
    //LifeCycle
    componentDidMount(){
        this.props.HandleBodyClasses(this.props.currentpage);
        this.onFetchArticle();
    }

 
    //Functions
    onFetchArticle(){

        var {match, web_title, web_id} = this.props;
        var route_uid = match ? match.params.subpath : ''
       
        var req_url = '/api/v1/builder/websites/articles/single/live';
        var req_form = {
           id: web_id,
           a_id: route_uid
        }

        axios.post(req_url, req_form).then(res => {

            console.log("ARTICLE IS ====> ", res.data);

            var data = res.data || {};
            var error = data.error;

            if(error){
                this.setState({isLoadingArticle: false, isNotFound: true});
            }
            else{
                var article_title = data.title;
                document.title = web_title + ' | '+ article_title;
                this.setState({isLoadingArticle: false, isNotFound: false, data: data});
            }
           

        })
        .catch((error) => {
            // handle error
            console.log(error);
            this.setState({data_loading: false});
        });

    };

  
    render(){

        var CurrentArticle = this.state.data || {};
        var {isLoadingArticle, isNotFound} = this.state;

        var { 
            title,  
            content_raw,
            layout,
            bg_color,
            banner,
        } = CurrentArticle;
       

        //Description
        var article_content_raw = JSON.parse(content_raw ? content_raw : null);    
        var article_description = getFormattedHtml(article_content_raw)
        
        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');
    
        if(isLoadingArticle){
           return (

                <div className="website-loader-wrapper">
                    <div className="branded-loading">
                        {/*<h5>Loading</h5>*/}
                       <i className="fa fa-spinner fa-spin" /> 
                    </div>
                </div>
                
           )
        }

        if(isNotFound){
            return (
                <div className="website-loader-wrapper">
                    <h1>Article not found</h1>      
                    <div className="explore-btn" style={{background: 'rgb(0, 0, 0)', color: 'rgb(255, 255, 255)'}} onClick={()=> this.props.history.push('/')}>
                        <i className="fa fa-plus-circle" /> Back Home
                    </div>      
                </div>
            )
        }


        //Store Settings

        var {web_lang, web_id} = this.props;
        var blog_settings = this.props.blog_settings || {};
        var block_title_size = blog_settings.title_size || 50;
        var block_title_color = blog_settings.title_color || '#000000';
     
       

        return(


                <div className={"flex-container "+(layout)+" "+(text_color==='#000000' ? 'text-black' : 'text-white')} id="blogPost" style={{background: bg_color}}>
                
                        <div className="blog-post-hero">
                            <div className="blog-post-title">
                                <h1 style={{fontWeight: 'bold', fontSize: block_title_size+'px', color: block_title_color}}>{title}</h1>
                                
                                <div className="blog-post-author" style={{justifyContent: 'space-between'}}> 
                                    <div className="publish-share-icons">
                                        <ClickShareButton type="facebook_share" payload={{url: window.location.href}} />
                                        <ClickShareButton type="twitter_share" payload={{url: window.location.href}} />
                                        <ClickShareButton type="whatsapp_share" payload={{url: window.location.href}}  />
                                        <ClickShareButton type="linkedin_share" payload={{url: window.location.href}} />
                                        <ClickShareButton type="email_share" payload={{url: window.location.href}} />
                                    </div>
                                </div>

                            </div>
                            
                            <div className="blog-post-hero-image">
                                <Image_Frame image_url={forceSSL(banner)} settings={blog_settings} className="blog-hero-img" />
                            </div>
                            
                        </div>

                        <div className="blog-post-content">

                            <div dangerouslySetInnerHTML={createMarkup(article_description)} />
                            
                            <SubscribeBlogForm blog_settings={blog_settings} web_lang={web_lang} web_id={web_id} />
                
                        </div>
                </div>
                    
        )
    
    }


};

class SubscribeBlogForm extends React.Component {
 
    constructor(props) {
        super(props);   

        this.state = {
            contact_name:'',
            contact_email:'',
            isSending: false
        } 

        this.onSendForm = this.onSendForm.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onSendForm(){
    
        var {web_id, web_lang} = this.props;
        var {contact_name, contact_email} = this.state;

        //Checking Fields
        if(contact_name.length == 0){
    
            swal(
                getTranslatedFormAlerts('missing_fields', web_lang), 
                getTranslatedFormAlerts('missing_name', web_lang), 
                "warning"
            );

            return
        }
        if(!validateEmail(contact_email)){
            
            swal(
                getTranslatedFormAlerts('missing_fields', web_lang), 
                getTranslatedFormAlerts('missing_email', web_lang), 
                "warning"
            );
         
            return
        }

        //Generate Form
        var form = {
            id:web_id,
            contact_name: contact_name,
            contact_email: contact_email,
            type: 'blog'
        }

        //Checking Form Submit type
        var button_success = getTranslatedFormAlerts('form_success', web_lang);

        return this.onFormSubmit(form, button_success);
    
    }

  
    onFormSubmit(form, message_success){

        var {web_lang} = this.props;

		const url = '/api/v1/audience/subscription_form/send';
		const jwt = localStorage.getItem('jwt_token');

        this.setState({ isSending:true});

		axios.post(url , form ,{ headers: {authorization: jwt}  })
			.then(response => {

            this.setState({
                contact_name:'',
                contact_email:'',
                isSending: false
            });

            var data = response.data || {};
            var error = data.error; 

            if(error){
                return swal(
                    getTranslatedFormAlerts('form_already', web_lang), 
                    '',
                    "warning"
                );
            }

            return swal(
                message_success,
                '',
                'success'
            );
               
          

		})
		.catch(error => {
            console.log(error);
            
            this.setState({
                contact_name:'',
                contact_email:'',
                isSending: false
            });

		});
    }
   

    render(){
        
       
        var {web_lang} = this.props;
        var {isSending, contact_email, contact_name} = this.state;

        var blog_settings = this.props.blog_settings || {};
        
        var block_description_size = blog_settings.description_size || 35;
        var block_subtitle_size = blog_settings.subtitle_size || 20;
        var block_extra_size = blog_settings.extra_size || 14;
    
        var block_description_color = blog_settings.description_color || '#000000';
        var block_subtitle_color = blog_settings.subtitle_color || '#000000';
        var block_extra_color = blog_settings.extra_color || '#333';

        var block_description_content = blog_settings.description_content || getTranslatedPage('subscribe_blog', web_lang);
        var block_subtitle_content = blog_settings.subtitle_content || getTranslatedPage('subscribe_news', web_lang);
        var block_extra_content = blog_settings.extra_content || getTranslatedPage('subscribe', web_lang);

        var not_allowed_btn = { cursor: 'not-allowed',  background: 'linear-gradient(-135deg, #ccc 0%, #ccc 100%)'}

        return (
            <div className="subscribe-to-content">
                <h1 style={{fontSize: block_description_size+'px', color: block_description_color, fontWeight: 'bold'}}>
                     {block_description_content}
                </h1>
                <p style={{fontSize: block_subtitle_size+'px', color: block_subtitle_color}}>
                    {block_subtitle_content}
                </p>
                <div className="subscribe-to-content-form">
                    <input placeholder={getTranslatedPage('name', web_lang)} type="text"  value={contact_name} onChange={(e)=>this.setState({contact_name: e.target.value})}/>
                    <input placeholder={getTranslatedPage('email', web_lang)} type="email"  value={contact_email} onChange={(e)=>this.setState({contact_email: e.target.value})}/>
                    
                    {isSending ? (
                        <button style={{ color: '#ffffff', ...not_allowed_btn}}>
                            <i className="fa fa-spinner fa-spin" style={{marginRight: '10px', display: 'inherit'}}/>
                        </button>
                    ) : (
                        <button style={{fontSize: block_extra_size+'px', background: block_extra_color, color: '#ffffff'}} onClick={()=>this.onSendForm()}>
                            {block_extra_content}
                        </button>
                    )}
                    
                
                </div>

            </div>
        )
        
    }
}


const getFormattedHtml = (content_raw) => {

    var editorState = editorStateFromRaw(content_raw);
    var options = {

        blockRenderers: {
            atomic: (block) => {
                var blockType = block.get('type').toLowerCase();
                if (blockType === 'atomic') {
                    
                    var data = block.getData();
                    var blogSubType = data.get("type");
                    var blogDisplay = data.get("display");

                    if(blogSubType === 'click_imagepicker'){
                        
                        var image_size_type = blogDisplay || 'medium';
                        var image_class_name = 'blog_image_'+image_size_type;
                
                        return '<img width="420" height="315" src="'+data.get("src")+'" class='+image_class_name+'></img>';
                        
                    }
                    else if(blogSubType === 'click_videopicker'){
                        
                        var video_size_type = blogDisplay || 'medium';
                        var video_class_name = 'blog_video_'+video_size_type;
                
                        return '<iframe width="420" height="315" class="'+video_class_name+'" src="https://www.youtube.com/embed/'+data.get('src')+'"></iframe>';
                        
                    }
                    else if(blogSubType === 'click_mappicker'){
                        
                        var map_size_type = blogDisplay || 'medium';
                        var map_class_name = 'blog_map_'+map_size_type;
                
                        var mapAddress = data.get('src').replace(' ', '+');
                        console.log("SOURCE = ", data.get('src'));
                        return '<iframe width="420" height="315" class="'+map_class_name+'" src="https://maps.google.com/maps?q='+mapAddress+'k&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>';
                        
                    }
                    else if(blogSubType === 'click_musicpicker'){
                        
                        var music_type = data.get("music_type");
                        var music_size_type = blogDisplay || 'medium';
                        var music_class_name = 'blog_music_'+music_size_type;
                        
                        var iframe_link = '';

                        if(music_type === 'spotify'){
                            
                            iframe_link = data.get("src");
                        }
                        else if(music_type === 'soundcloud'){

                            iframe_link = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/"+data.get("src")+"&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                        }
                        
                
                        return '<iframe width="420" height="315" class="'+music_class_name+'" src="'+iframe_link+'"></iframe>';
                        
                    }
                }
            },
        },
        entityStyleFn: (entity) => {
            const entityType = entity.get('type').toLowerCase();
            
            console.log("ENTYT = ", entityType);

            if (entityType === 'link') {
                
                const data = entity.getData();
                
                return {
                element: 'a',
                attributes: {
                    href: data.href,
                    title: data.title
                    
                },
                style: {
                    color: '#3192E7'
                }
                };
            }
            },
    }
    return stateToHTML(editorState.getCurrentContent(), options);
    
}


const getTranslatedPage = (type, lang) => {
    
    var tr = '';

    if(type === 'subscribe'){
        switch (lang) {
            case 'fr':
                tr = 'S\'abonner'
                break;
            case 'it':
                tr = 'Registrati'
                break;
            case 'es':
                tr = 'Abonarse'
                break;
            default:
                tr = 'Subscribe'
                break;
        }
    }
    else if(type === 'subscribe_blog'){
        switch (lang) {
            case 'fr':
                tr = 'Abonnez-vous au Blog'
                break;
            case 'it':
                tr = 'Iscriviti al Blog'
                break;
            case 'es':
                tr = 'Suscríbete al Blog'
                break;
            default:
                tr = 'Subscribe to Blog'
                break;
        }
    } 
    else if(type === 'subscribe_news'){
        switch (lang) {
            case 'fr':
                tr = 'Recevez les dernières actualités, articles, commentaires, parcourez.'
                break;
            case 'it':
                tr = 'Ricevi le ultime notizie, articoli, commenti, sfoglia.'
                break;
            case 'es':
                tr = 'Reciba las últimas noticias, artículos, comentarios, navegue.'
                break;
            default:
                tr = 'Receive the latest news, articles, comment, browse.'
                break;
        }
    } 
    else if(type === 'name'){
        switch (lang) {
            case 'fr':
                tr = 'Prénom'
                break;
            case 'it':
                tr = 'Nome'
                break;
            case 'es':
                tr = 'Nombre'
                break;
            default:
                tr = 'Name'
                break;
        }
    } 
    else if(type === 'email'){
        switch (lang) {
            case 'fr':
                tr = 'Adresse Email'
                break;
            case 'it':
                tr = 'Indirizzo Email'
                break;
            case 'es':
                tr = 'Correo Electronico'
                break;
            default:
                tr = 'Email'
                break;
        }
    } 

    return tr

}

const getTranslatedFormAlerts = (type, lang) => {
    
    var tr = '';

    if(type === 'missing_fields'){
        switch (lang) {
            case 'fr':
                tr = 'Champs Manquants'
                break;
            case 'it':
                tr = 'Campi Mancanti'
                break;
            case 'es':
                tr = 'Campos Faltantes'
                break;
            default:
                tr = 'Missing Fields'
                break;
        }
    }
    else if(type === 'missing_name'){
        switch (lang) {
            case 'fr':
                tr = 'Vous devez ajouter votre nom pour s\'inscrire au Blog'
                break;
            case 'it':
                tr = 'È necessario aggiungere il tuo nome per registrarti al Blog'
                break;
            case 'es':
                tr = 'Debe agregar su nombre para registrarse al Blog'
                break;
            default:
                tr = 'You need to add your name to subscribe to the Blog'
                break;
        }
    }
    else if(type === 'missing_email'){
        switch (lang) {
            case 'fr':
                tr = 'Vous devez ajouter une adresse e-mail valide pour s\'inscrire au Blog'
                break;
            case 'it':
                tr = 'È necessario aggiungere il tuo indirizzo email per registrarti al Blog'
                break;
            case 'es':
                tr = 'Debe agregar una dirección de correo electrónico válida para registrarse al Blog'
                break;
            default:
                tr = 'You need to add a valid email address to subscribe to the Blog'
                break;
        }
    }
    else if(type === 'form_success'){
        switch (lang) {
            case 'fr':
                tr = 'Vous vous êtes inscrit avec succès à notre Blog'
                break;
            case 'it':
                tr = 'Si é registrato con successo al nostro Blog'
                break;
            case 'es':
                tr = 'Se registró correctamente en nuestro Blog'
                break;
            default:
                tr = 'You signed up successfully to our Blog'
                break;
        }
    }
    else if(type === 'form_already'){
        switch (lang) {
            case 'fr':
                tr = 'Vous vous êtes déjà inscrit à notre Blog'
                break;
            case 'it':
                tr = 'Si é già iscritto al nostro Blog'
                break;
            case 'es':
                tr = 'Ya te registraste en nuestro Blog'
                break;
            default:
                tr = 'You signed up successfully to our Blog'
                break;
        }
    }
    

    return tr

}


const mapStateToProps = ({ mode_preview }) => {
  
    return {
      web_title: mode_preview.basic_info.title,
      web_id: mode_preview.basic_info.id,
      web_lang: mode_preview.basic_info.language,
      blog_settings: mode_preview.style.blog || {}
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
  
    }, dispatch)
  }

export default connect(mapStateToProps, mapDispatchToProps)(Layout_Article)

