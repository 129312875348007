import React from 'react';
import { Link } from 'react-router-dom';
import Iframe from 'react-iframe'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions_PreviewMode from '../../../../../actions/mode_preview';

import Block_Textbox from '../general/block_textbox';
import Block_Button from '../general/block_button';

class Layout_Events extends React.Component {

    constructor(props) {
            super(props);

    }

    componentDidMount(){

        this.props.HandleBodyClasses(this.props.currentpage);
    }

    render(){

        var { 
            title, 
            description, 
            title_html, 
            description_html, 
            cover_icon,
            page_action_button,
            slider,
            page_content_url,
            page_layout
            
          } = this.props.currentpage;


          var { currentblocks} = this.props;
       
        return(

            <>
                <div className="flex-container" id="eventsContent">

                    <div className="events-image">
                        <Iframe url={"https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d6880287.958735748!2d-121.63074037586564!3d32.645720803762224!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ssk!2sus!4v1542238625188"}
                                                id="map_frame"
                                                width="600" height="450" frameborder="0" style="border:0" allowfullscreen/>
                    </div>

                    <div className="events-content col-50">

                        <div className="events-title">
                             <Block_Textbox type="page_title" content={title} content_html={title_html} />  
                             <Block_Textbox type="page_description" content={description} content_html={description_html} />  
                        </div>


                        <div className="event-boxes">
                            
                            <Event_Item />
                            <Event_Item />
                            <Event_Item />
                            <Event_Item />
                            <Event_Item />
                            <Event_Item />
                            
                        </div>

                    </div>

                </div>

                {currentblocks}
            
            </>
        )
    }
};

const Event_Item = (props)=>{
    
    return (

        <a className="event-box">
            <div className="event-date"><span>04</span>April</div>
            <div className="event-box-image">
                <img src="/assets/img/placeholders/event.jpg" />
            </div>
            <div className="event-description">
                <h4>Event name Winterzirkus</h4>
                <span className="location">O2 Arena, London, UK</span>
            </div>
            <div className="event-price">
                €7.00
                <div className="book-now">Book now</div>
            </div>
        </a>

    )
 
}



function createMarkup(html) {
    return {__html: html};
}

const mapStateToProps = ({ mode_preview }) => {

    return {
        basic_info: mode_preview.basic_info,
        style: mode_preview.style,
        navigation: mode_preview.navigation,
        socialmedia: mode_preview.socialmedia,
        contact: mode_preview.contact
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        ...Actions_PreviewMode
  
    }, dispatch)
  }
export default connect(mapStateToProps, mapDispatchToProps)(Layout_Events)
