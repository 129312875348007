import React from 'react';
import Iframe from 'react-iframe'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions_PreviewMode from '../../../../../actions/mode_preview';

import {pickTextColorBasedOnBgColorAdvanced} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Background from '../general/block_background';
import Block_Button from '../general/block_button';
import Block_Settings from '../general/block_settings';

class Block_Map extends React.Component {

    constructor(props) {
        super(props);   
    }

    componentDidMount(){

    }

    render(){

        var {currentblock} = this.props;
        var {title, description, title_html, description_html, layout,bg_color, map, bg_image} = currentblock;
        
        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');

        var block_btn = currentblock.block_btn || {}; 
        var block_class = Block_Settings(currentblock);

        return(

                <div className={block_class} style={{background: bg_color, color: text_color}}>

                    <div className={"design-block-text-map " + layout}>
                       
                        <div className="design-block-map">
                          <Iframe url={"https://maps.google.com/maps?q="+map+"&t=&z=13&ie=UTF8&iwloc=&output=embed"}
                                            width="600"
                                            height="450"
                                            id="map_frame"
                                            className="" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"/>
                    
                        </div>

                        <div className="design-block-map-text">
                                <Block_Textbox type="block_title" content={title} content_html={title_html} />  
                                <Block_Textbox type="block_description" content={description} content_html={description_html} />
                                <Block_Button type='block' block_btn={block_btn} /> 
                        </div>
                    </div>  

                    {bg_image && <Block_Background bg_image={bg_image} />}
                </div>
               

        )
    }
};

function createMarkup(html) {
    return {__html: html};
}

const mapStateToProps = ({ mode_preview }) => {

  return {
    
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      ...Actions_PreviewMode

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Block_Map)
