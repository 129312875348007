import React from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {validateEmail, pickTextColorBasedOnBgColorAdvanced, isURL, getFormSearchParams} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Background from '../general/block_background';
import Block_Button from '../general/block_button';
import Block_Settings from '../general/block_settings';

import {Image_Frame, Map_Frame} from '../general/page_media';

class Block_Contact extends React.Component {

    constructor(props) {
        super(props);   

        this.state = {
            contact_name:'',
            contact_email:'',
            contact_message:'',
            isSending: false
        } 
    
        this.onSendForm = this.onSendForm.bind(this);
        this.onFormRedirect = this.onFormRedirect.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
       
    }


    onSendForm(){
    
        var {web_id, web_lang, currentpage} = this.props;
        var {contact_name, contact_email, contact_message} = this.state;

        //Checking Fields
        if(contact_name.length == 0){
    
            swal(
                getTranslatedFormAlerts('missing_fields', web_lang), 
                getTranslatedFormAlerts('missing_name', web_lang), 
                "warning"
            );

            return
        }
        if(!validateEmail(contact_email)){
            
            swal(
                getTranslatedFormAlerts('missing_fields', web_lang), 
                getTranslatedFormAlerts('missing_email', web_lang), 
                "warning"
            );
        
            return
        }
       
        if(contact_message.length == 0){
            
            swal(
                getTranslatedFormAlerts('missing_fields', web_lang), 
                getTranslatedFormAlerts('missing_message', web_lang), 
                "warning"
            );
        
            return
        }

        //Generate Form
        var form = {
            id:web_id,
            contact_name: contact_name,
            contact_email: contact_email,
            contact_message: contact_message
        }

        //Checking Form Submit type
        var block_btn = currentpage.page_action_button || {}; 
        var button_link = block_btn.button_action || '';
        var button_submit = block_btn.button_submit || 'submit';
        var button_success = block_btn.button_success || getTranslatedFormAlerts('form_success', web_lang);
        var button_search = block_btn.button_search || false;

        if(button_submit === 'submit'){
            return this.onFormSubmit(form, button_success);
        }
        else if(button_submit === 'redirect'){
            return this.onFormRedirect(form, button_link, button_search);
        }
        else if(button_submit === 'submit_redirect'){
            return this.onFormSubmit(form, button_success, button_link, true, button_search);
        }
    
    }

    //Actions
    onFormRedirect(form, link, isSearch){

        if(!form) return;
        if(!link) return;

        //Get Search Params
        var form_redirect_search = getFormSearchParams({name: form.contact_name, email: form.contact_email, message: form.contact_message}); 

        var isExternalUrl = isURL(link);
        if(isExternalUrl){
            if(isSearch){
                window.location.href = link + '?' + form_redirect_search;
            }
            else{
                window.location.href = link;
            }
        }
        else{

            if(isSearch){
                this.props.history.push(link + '?' + form_redirect_search);
            }
            else{
                this.props.history.push(link)
            }

        }

    }

    onFormSubmit(form, message_success, link, isRedirect, isSearch){

        var {web_lang} = this.props;

		const url = '/api/v1/audience/contact_form/send';
		const jwt = localStorage.getItem('jwt_token');

        this.setState({ isSending:true});

		axios.post(url , form ,{ headers: {authorization: jwt}  })
			.then(response => {

            this.setState({
                contact_name:'',
                contact_email:'',
                contact_message: '',
                isSending: false
            });

            var data = response.data || {};
            var error = data.error; 

            if(error){
                return swal(
                    getTranslatedFormAlerts('form_error', web_lang), 
                    '',
                    "warning"
                );
            }

            if(isRedirect){
                return this.onFormRedirect(form, link, isSearch);
            }
            else{

                return swal(
                    message_success,
                    '',
                    'success'
                );
               
            }

		})
		.catch(error => {
            console.log(error);
            
            this.setState({
                contact_name:'',
                contact_email:'',
                contact_message: '',
                isSending: false
            });

		});
    }
   
    render(){

        var {contact_name, contact_email, contact_message, isSending} = this.state;
        var {currentblock} = this.props;
       
        var {title, description, title_html, description_html, layout, bg_color, bg_image, map, image} = currentblock;
        var {contact, socialmedia, web_lang} = this.props;

        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');
        
        //Block Settings
        var block_btn = currentblock.block_btn || {}; 
        var block_class = Block_Settings(currentblock);

        //Social / Contact
        var block_settings = currentblock.settings || {};
        var block_icons = block_settings.icons ? true : false;
        var block_phone = block_settings.phone ? true : false;
        var block_email = block_settings.email ? true : false;
        var block_location = block_settings.location ? true : false;
    
        return(

            <div className={block_class} style={{background: bg_color, color: text_color }}>
                   
                    <div className={"design-block-contact "+layout}>
                        <div className="design-block-contact-image">
                        
                            {image && (
                                <Image_Frame image_url={image} settings={block_settings} />
                            )}


                            {map && (
                                <div id="about_map">
                                    <Map_Frame map_url={map} settings={block_settings} />
                                </div>
                            )}
                        
                        </div>
                        

                        <div className="design-block-contact-form">

                            <div className="design-block-contact-form-info">
                                
                                <Block_Textbox type="block_title" content={title} content_html={title_html} />  
                                <Block_Textbox type="block_description" content={description} content_html={description_html} /> 
                                
                                {block_icons && (
                                    <SocialMedia_Info socialmedia={socialmedia || []} text_color={text_color} />
                                    
                                )}
                                
                                {(block_phone || block_email || block_location) && (
                                    <div className="contact-points">
                                        {block_phone && (
                                            <Contact_Info contact={contact || {}} type='phone'/>
                                        )}

                                        {block_email && (
                                            <Contact_Info contact={contact || {}} type='email'/>
                                        )}

                                        {block_location && (
                                            <Contact_Info contact={contact || {}} type='location'/>
                                        )}
                                    </div>
                                )}
                            
                            </div>

                            <form>
                                <div className="input-wrapper">
                                    <input placeholder={getTranslatedFormFields("full_name", web_lang)} type="text"  value={contact_name} onChange={(e)=>this.setState({contact_name: e.target.value})} />
                                </div>
                                <div className="input-wrapper">
                                    <input placeholder={getTranslatedFormFields("email", web_lang)} type="email"  value={contact_email} onChange={(e)=>this.setState({contact_email: e.target.value})}/>
                                </div>
                                <div className="input-wrapper">
                                    <textarea placeholder={getTranslatedFormFields("message", web_lang)}  value={contact_message} onChange={(e)=>this.setState({contact_message: e.target.value})}></textarea> 
                                </div>
                                
                                <Block_Button type='block' subtype='contact' block_btn={block_btn} isSending={isSending} onSendForm={this.onSendForm}/>
                            </form>
                        </div>

                    </div>  

                    {bg_image && <Block_Background bg_image={bg_image} />}
                    
                </div>

        )
    }
};


const Contact_Info = ({contact, type}) => {

    if(type === 'phone' && contact.phone){
        return (
            <div>
                <a>
                    <i className="fa fa-mobile" /> {contact.phone}
                </a>
            </div> 
        )
    }

    if(type === 'email' && contact.email){
        return (
            <div>
                <a>
                    <i className="fa fa-envelope" /> {contact.email}
                </a>
            </div> 
        )
    }

    if(type === 'location' && contact.location){
        return (
            <div>
                <a>
                    <i className="fa fa-map-marker" /> {contact.location}
                </a>
            </div> 
        )
    }

    return null

}

const SocialMedia_Info = ({socialmedia, text_color}) =>{

    var socialmedia_array = socialmedia ? getSocialMediaArray(socialmedia) : [];
    var socialmedia_items = socialmedia_array.map((d,index) => {
        return (
            <a href={d.url} key={index} style={{color: text_color}}>
                <i className={"socicon socicon-"+d.type}></i>
            </a>
        )
    });

    var inline_style = {
        marginTop: '20px',
        marginBottom: '40px'
    }

    return (
        <div className="social-contacts" style={inline_style}>
            {socialmedia_items}
        </div>
    )
}

//Functions
const getSocialMediaArray = (socialmedia) =>{

    var contact_types = Object.keys(socialmedia);

    var socialmedia_array = [];

    for(var i= 0; i<contact_types.length; i++){

        var contact_type = contact_types[i];
        var contact_value = socialmedia[contact_type];

        if(contact_value.length>0){

            if(contact_type === 'whatsapp'){

                var phone_number = contact_value.replace(/\D/g,'');
                socialmedia_array.push({
                    type: contact_type,
                    url:'https://wa.me/'+phone_number
                });
            }
            else{
                socialmedia_array.push({
                    type: contact_type,
                    url: contact_value
                });
            }
           
        }

        if(i==contact_types.length-1){
            return socialmedia_array;
        }

    }

}


const getTranslatedFormFields = (type, lang) => {

    if(lang === 'fr'){
        if(type === 'full_name'){
            return 'Prénom'
        }
        else if(type === 'email'){
            return 'Email'
        }
        else if(type === 'message'){
            return 'Écrivez votre message ici...'
        }
        
    }
    else if(lang === 'it'){
        if(type === 'full_name'){
            return 'Nome'
        }
        else if(type === 'email'){
            return 'Email'
        }
        else if(type === 'message'){
            return 'Scrivi il tuo messaggio qui...'
        }
        
    }
    else if(lang === 'es'){
        if(type === 'full_name'){
            return 'Nombre'
        }
        else if(type === 'email'){
            return 'Email'
        }
        else if(type === 'message'){
            return 'Escriba su mensaje aquí...'
        }
        
    }
    else {
        if(type === 'full_name'){
            return 'First Name'
        }
        else if(type === 'email'){
            return 'Email'
        }
        else if(type === 'message'){
            return 'Write your message here...'
        }
    }
}

const getTranslatedFormAlerts = (type, lang) => {
    
    var tr = '';

    if(type === 'missing_fields'){
        switch (lang) {
            case 'fr':
                tr = 'Champs Manquants'
                break;
            case 'it':
                tr = 'Campi Mancanti'
                break;
            case 'es':
                tr = 'Campos Faltantes'
                break;
            default:
                tr = 'Missing Fields'
                break;
        }
    }
    else if(type === 'missing_name'){
        switch (lang) {
            case 'fr':
                tr = 'Vous devez ajouter votre nom pour soumettre le formulaire de contact'
                break;
            case 'it':
                tr = 'È necessario aggiungere il tuo nome per inviare il modulo di contatto'
                break;
            case 'es':
                tr = 'Debe agregar su nombre para enviar el formulario de contacto'
                break;
            default:
                tr = 'You need to add your name to submit the contact form'
                break;
        }
    }
    else if(type === 'missing_email'){
        switch (lang) {
            case 'fr':
                tr = 'Vous devez ajouter une adresse e-mail valide pour soumettre le formulaire de contact'
                break;
            case 'it':
                tr = 'È necessario aggiungere il tuo indirizzo email per inviare il modulo di contatto'
                break;
            case 'es':
                tr = 'Debe agregar una dirección de correo electrónico válida para enviar el formulario de registro'
                break;
            default:
                tr = 'You need to add a valid email address to submit the signup form'
                break;
        }
    }
    else if(type === 'missing_message'){
        switch (lang) {
            case 'fr':
                tr = 'Vous devez ajouter un message pour soumettre le formulaire de contact'
                break;
            case 'it':
                tr = 'È necessario aggiungere un messaggio per inviare il modulo di contatto'
                break;
            case 'es':
                tr = 'Debe agregar un mensaje para enviar el formulario de contacto'
                break;
            default:
                tr = 'You need to add a message to submit the contact form'
                break;
        }
    }
    else if(type === 'form_success'){
        switch (lang) {
            case 'fr':
                tr = 'Votre message a été envoyé avec succès'
                break;
            case 'it':
                tr = 'Il tuo messaggio è stato inviato con successo'
                break;
            case 'es':
                tr = 'Tu mensaje ha sido enviado exitosamente'
                break;
            default:
                tr = 'Your message has been sent successfully'
                break;
        }
    }
    else if(type === 'form_error'){
        switch (lang) {
            case 'fr':
                tr = 'Votre message n\'a pas été envoyé. Veuillez réessayer plus tard'
                break;
            case 'it':
                tr = 'Il tuo messaggio non è stato inviato. Per favore riprova più tardi'
                break;
            case 'es':
                tr = 'Su mensaje no ha sido enviado. Por favor, inténtelo de nuevo más tarde'
                break;
            default:
                tr = 'Your message hasn\'t been sent. Please try again later'
                break;
        }
    }
    
    

    return tr

}



const mapStateToProps = ({ mode_preview }) => {

    return {
        web_id: mode_preview.basic_info.id,
        web_lang: mode_preview.basic_info.language,
        socialmedia: mode_preview.socialmedia,
        contact: mode_preview.contact
    }
  }
  
const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Block_Contact)

