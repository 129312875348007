import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {pickTextColorBasedOnBgColorAdvanced, forceSSL, createMarkup} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Background from '../general/block_background';
import Block_Settings from '../general/block_settings';

import Moment from 'react-moment'


class Block_BlogPosts extends React.Component {

    constructor(props) {
        super(props);  

        this.state ={
            data_index: 0,
            data_items: [],
            data_loading: true,
            data_total: 0,
            isAddingArticles: false,
         
        }
        
        this.onFetchArticles = this.onFetchArticles.bind(this);
     
    }

    componentDidMount(){
        this.onFetchArticles();
    }

    onFetchArticles(){
        
        var {web_id, currentblock} = this.props;
        var blogposts_array = currentblock.slider || [];

        var nextUrl = '/api/v1/builder/websites/articles/blocks';

        this.setState({data_loading: true});

        var form = {
            id: web_id,
            ids: blogposts_array
        }

        axios.post(nextUrl, form).then(res => {

            var post_array = res.data.array || [];
            this.setState({ 
                data_loading: false, 
                data_items: post_array
            });
        
        })
        .catch((error) => {
            // handle error
            console.log(error);
            this.setState({data_loading: false})
        });
    };


    render(){
        
        var { currentblock} = this.props;
        var {title, description, title_html, description_html, layout, bg_color, bg_image} = currentblock;
        
        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');

        //Block Settings
        var block_class = Block_Settings(currentblock);

        var block_settings = currentblock.settings || {};

        
        //Articles

        var {data_items, data_loading} = this.state;
      
        var blogposts_items = data_items ? data_items.map((item, index)=> {
    
            var blog_id = item.id;
            var blog_title = item.title;
            var blog_description = item.description;
            var blog_banner = item.thumbnail;
            var blog_date = item.date;

            var block_title_size = block_settings.title_size || 23;
            var block_description_size = block_settings.description_size || 15;
            var block_extra_size = block_settings.extra_size || 13;
          
            var block_title_color = block_settings.title_color || '#000000';
            var block_description_color = block_settings.description_color || '#000000';
            var block_extra_color = block_settings.extra_color || '#000000';

            //Metadata
            var block_format = block_settings.format || 'simple';
            if(block_format === 'metadata'){
                var blog_metadata = item.metadata || {};
                if(blog_metadata.title){
                    blog_title = blog_metadata.title;
                }
                if(blog_metadata.description){
                    blog_description = blog_metadata.description;
                }
            }
            
			return (

                <Link to={'/blog/'+blog_id} className="design-block-blog-card" key={index}>
                        <div className="design-block-blog-card-image">
                            <img src={forceSSL(blog_banner)} />
                        </div>
                        <div className="design-block-blog-card-description">
                            <span style={{fontSize: block_extra_size+'px', color: block_extra_color}}>
                                <Moment date={blog_date} format="DD MMMM YYYY" />
                            </span>
                            <h4 style={{fontSize: block_title_size+'px', color: block_title_color, fontWeight: 'bold'}}>
                                {blog_title}
                            </h4>
                            <p style={{fontSize: block_description_size+'px', color: block_description_color}}>
                                {blog_description}
                            </p>
                        </div>
                </Link>
                

                
			);
        }) : [];


        return(

            <>

                <div className={block_class} style={{background: bg_color, color: text_color}}>
                    
                    <div className={"design-block-blog "+layout}>

                        <div className="title-set">
                            <Block_Textbox type="block_title" content={title} content_html={title_html} />  
                            <Block_Textbox type="block_description" content={description} content_html={description_html} />
                        </div>

                        {data_loading ? (
                            <div className="generic-loading-spin">
                                <i className="fa fa-spinner fa-spin" /> 
                            </div>
                        ) : (

                            <div className="blog-cards-container">
                                {blogposts_items.length == 0 ? (
                                    <Blog_Placeholder onOpenBlog={this.onOpenBlog} />
                                ) : (
                                    blogposts_items
                                )}
                            </div>
                            
                        )}

                    </div>
                    
                    {bg_image && <Block_Background bg_image={bg_image} />}

                </div>
                
            </>

        )
    }
};


const Blog_Placeholder = () =>{

    return (
        <div className="empty-store-product-picker">
            <img src="/assets/img/placeholders/empty-store.png" />
            <h4 style={{marginTop: '20px'}}>No Articles</h4>
        </div>
    )
}



const mapStateToProps = ({ mode_preview }) => {
    return {
        web_id : mode_preview.basic_info.id,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(Block_BlogPosts)

