import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { pickTextColorBasedOnBgColorAdvanced } from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Background from '../general/block_background';
import Block_Settings from '../general/block_settings';

class Block_Reviews extends React.Component {

    constructor(props) {
        super(props); 
       

    }

    render(){

        var {currentblock} = this.props;
        var {layout, bg_color, bg_image, slider, title, title_html, description, description_html} = currentblock;
        
        var text_color = pickTextColorBasedOnBgColorAdvanced(bg_color, '#FFFFFF', '#000000');
        var block_class = Block_Settings(currentblock);

        var block_settings = currentblock.settings || {};

        var reviews_items = slider.map((item, index)=>  {
            return (
                <Card_Review key={index} payload={item} index={index} block_settings={block_settings} />
            )
        });

        return( 

                <div className={block_class+' '+layout} style={{background: bg_color, color: text_color}}>
                    
                    <div className="title-set">
                        <Block_Textbox type="block_title" content={title} content_html={title_html} />  
                        <Block_Textbox type="block_description" content={description} content_html={description_html} />
                    </div>

                    <div id="reviewsBlock">
                    
                        {reviews_items.length >1 && (

                            <div className="slider-buttons">
                                <button onClick={()=>this.minusDivs()}>&#10094;</button>
                                <button onClick={()=>this.plusDivs()}>&#10095;</button>
                            </div>

                        )}
                            
                        {reviews_items.length == 0 ? (

                            <div className="review-placeholder">
                                    <strong></strong>
                                    <div className="rating star-5">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />  
                                    </div> 
                                    <p></p>
                            </div>

                        ) : (
                            reviews_items
                        )}  
                            
                        
                    
                    </div>

                    {bg_image && <Block_Background bg_image={bg_image} />}

                </div>

        )
    }
};


const Card_Review = ({payload, block_settings, onEditItem, index}) =>{

    var {img,title,description, stars} = payload;

    var block_title_size = block_settings.title_size || 30;
    var block_description_size = block_settings.description_size || 16;
    var block_title_color = block_settings.title_color || '#000000';
    var block_description_color = block_settings.description_color || '#000000';

    var block_stars = block_settings.stars ? true : false;
    var block_icon = block_settings.icon ? true : false

    return (

        <div className="review mySlides">
        
            {!block_icon && (
                <img className="review-profile" src={img || '/assets/img/place_holder.jpg'} />
            )}

            <h4 style={{fontSize: block_title_size+'px', color: block_title_color}}>{title}</h4>
          
            {!block_stars && (
                <div className={"rating star-"+stars}>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" /> 
                </div> 
            )}

            <p  style={{fontSize: block_description_size+'px', color: block_description_color, whiteSpace: 'pre-wrap'}}>
                {description}
            </p>
        
        </div>
    )
}

const mapStateToProps = ({ mode_edit }) => {
  
  return {
  
  }
}
  
const mapDispatchToProps = dispatch => {
  return bindActionCreators({


  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Block_Reviews)

