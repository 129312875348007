import React from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import swal from 'sweetalert';

import {getFullWebsiteLive, SetupCurrentCart} from '../../../../actions/mode_preview';

import Header from './bj_header';
import Footer from './bj_footer';
import Navbar from './bj_navbar';
import Pages from './bj_pages';

import Checkout_Cart from './checkout/bj_cart';
import Checkout_Page from './checkout/bj_checkout';

import Legal_Alert from './general/block_legal';
import Cookies_Alert from './general/block_cookies';

import Modal_Video from './modals/modal_videos';
import Modal_Gallery from './modals/modal_gallery';


class Live_Mode extends React.Component {

  constructor(props) {
    super(props);

      this.state = {
        isLoading: true,
        isPromo: true,
        isFbPixel: false,
        isLegal: null
      };

      this.HandleBodyClasses = this.HandleBodyClasses.bind(this);
      this.HandleBodyLegal = this.HandleBodyLegal.bind(this);

  }


  componentDidMount() {

      var {initialData} = this.props;
      this.props.getFullWebsiteLive(initialData, 'initial');
     
      var route_params = getQueryStringParams(this.props.location.search);
      var route_message = route_params.purchase || '';

      if(route_message === 'success'){
        swal('Purchase Success', 'Your order has been purchased successfully, you will receive a confirmation email shortly', 'success');
      }
      else if(route_message === 'error'){
        swal('Purchase Error', 'Your order has not been purchased, please try again', 'error');
      }

      //set cart
      const serializedState = localStorage.getItem('cart') || [];
      var cart_array = serializedState.length != 0 ? JSON.parse(serializedState) : [];
      if(cart_array.length != 0){
        this.props.SetupCurrentCart(cart_array)
      }

  }

  componentDidUpdate(newProps){

      var current_google_analytics = this.props.google_analytics;

      var isPro = newProps.basic_info.isPro;
      var new_google_analytics = newProps.basic_info.google_analytics;
      var old_google_analytics = this.props.basic_info.google_analytics;
      var new_facebook_pixel = newProps.basic_info.facebook_pixel;
      var old_facebook_pixel = this.props.basic_info.facebook_pixel;

      // if(isPro && old_google_analytics){
      //     if(current_google_analytics !== old_google_analytics){
      //       this.props.HandleChangeGA(new_google_analytics);
      //     }
      // }

      // if(isPro && old_facebook_pixel){
      //     if(!this.state.isFbPixel){
      //       this.setState({isFbPixel: true});
      //       console.log("NEW FACEBOOK PIXEL ===> BABY ===> ", new_facebook_pixel);
      //       ReactPixel.init(new_facebook_pixel, {}, { debug: false, autoConfig: false });
      //       ReactPixel.pageView();
      //       ReactPixel.fbq('track', 'PageView');
      //     }
      // }
    
  }

  componentWillUnmount(){

    console.log("UNMUNTING WEBSITE LIVE");
    
    document.body.className = '';
    this.props.getFullWebsiteLive();

  }

  HandleBodyClasses(currentpage){

      var {header, footer, style} = this.props;
      var { page_layout, page_font} = currentpage;
    
            
      var BodyClass = [
        header.header_layout,
        footer.footer_layout,

        style.font_title,
        style.font_header,
        style.font_footer,
        style.font_body
      
      ]  

      header.header_size &&  BodyClass.push('nav-bar-'+header.header_size);
      header.header_fixed &&  BodyClass.push('nav-bar-fixed-top');
      
      document.body.classList = BodyClass.join(' ');  
      document.body.classList.add(page_layout,page_font);
      
      const element = document.body;
      element.scrollIntoView({ block: 'center',  behavior: 'smooth', inline: 'center' });

  }

  HandleBodyLegal(type){
    this.setState({isLegal: type});
  }

  render() {

      var {isPro, isCookies, isMaintenance, isCartOpen, isCheckout, isModalGallery, isModalVideos, initialData, pages} = this.props;
      
      var pages_array = this.props.pages || [];  
      var route_page = getCurrentPage(this.props.match, pages_array) || {};

      var currentpage = route_page.currentpage || {};
      var currentindex = route_page.currentindex || 0;
      var currenttype = route_page.currenttype;

      //Sharing Main
      var sharedProps = {
          history: this.props.history,
          match: this.props.match,
          location: this.props.location,
      }

      var sharedPage = {
          currentpage, 
          currentindex,
          currenttype
      }

      var sharedActionsPages = {
        HandleBodyClasses: this.HandleBodyClasses
      }
     

      // if(isMaintenance){
      //   return <Error_Maintenance />
      // }

      if(!initialData){
        return (
            <div className="master-container"  id="website-container">
              <div className="flex-container" id="loadingFlex">
                <i className="fa fa-spinner fa-spin" />
              </div>
            </div>

        )
      }

      if(!pages_array || pages_array.length == 0){
        return (
              <div className="master-container"  id="website-container">
                <div className="flex-container" id="loadingFlex">
                  <i className="fa fa-spinner fa-spin" />
                </div>
              </div>
          )
      }

      //Cookies
      var {isLegal} = this.state;
      var {web_id, web_lang} = this.props;

      return (

        <>
          <Header {...sharedProps} {...sharedPage}/>
          <div className="master-container"  id="website-container">
                <Navbar {...sharedProps} {...sharedPage} />
                  <Pages {...sharedProps} {...sharedPage} {...sharedActionsPages} />
                <Footer {...sharedProps} {...sharedPage} isPro={isPro} HandleBodyLegal={this.HandleBodyLegal} />
          </div>

            
          {isCartOpen && <Checkout_Cart web_id={web_id} web_lang={web_lang} />}
          {isCheckout && <Checkout_Page web_id={web_id} web_lang={web_lang} HandleBodyLegal={this.HandleBodyLegal}/>}

          {isModalGallery && <Modal_Gallery web_id={web_id} web_lang={web_lang} {...sharedProps} {...sharedPage}/>}
          {isModalVideos && <Modal_Video web_id={web_id} web_lang={web_lang} {...sharedProps} {...sharedPage}/>}

          {isCookies && <Cookies_Alert web_id={web_id} web_lang={web_lang} HandleBodyLegal={this.HandleBodyLegal} />}
          {isLegal && ( <Legal_Alert web_id={web_id} type={isLegal} HandleBodyLegal={this.HandleBodyLegal}/>)}

        </>
      )
      
    }

}

// const Promo_Card = () => {

//   return (
//     <>
//       {!isPro && (   

//         <>
//           {isPromo && (
//             <div className="click-ai-floater">
//                   <a href="https://www.clickai.co">Create website with  <img src="/assets/img/place_holder_logo.png" /></a>
//                   <span onClick={()=>this.setState({isPromo: false})}>+</span>
//                   <a href="https://www.clickai.co" className="click-ai-floater-message">
//                       This website was built with ClickAi. Create your <strong>FREE</strong> website now!
//                   </a>
//             </div>
//           )}

//         </>

//       )}

//       {isModalGallery &&( <Modal_Gallery currentpage={currentpage} {...this.props} /> )} 

//     </>
//   )
// }

//Website Architecture
const getCurrentPage = (match, pages_array) => { 

    var link_path = match.params.id || '';
    var link_subpath = match.params.subpath || '';

    if(link_path === 'blog' && link_subpath){
      return {
          currenttype: 'article'
      }
    }

    if(link_path === 'store' && link_subpath){
      return {
          currenttype: 'product'
      }
    }


    var route_uid = match.url;
    var currentindex = (route_uid === '/') ?  pages_array.findIndex(page => page.route === route_uid) : pages_array.findIndex(page => '/'+page.route === route_uid);
    var currentpage = currentindex != -1 ? pages_array[currentindex] : null;  
    var currenttype = currentpage ? currentpage.type : null;           
        
    return {
        currentpage,
        currentindex,
        currenttype
    }

}

const getQueryStringParams = (query) => {
  return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split('&')
          .reduce((params, param) => {
                  let [key, value] = param.split('=');
                  params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                  return params;
              }, {}
          )
      : {}
};


const mapStateToProps = ({ mode_preview }) => {

  return {
      web_id: mode_preview.basic_info.id,
      web_lang: mode_preview.basic_info.language,

      basic_info: mode_preview.basic_info,
      style: mode_preview.style,
      pages: mode_preview.pages,
      header: mode_preview.header,
      footer: mode_preview.footer,

      isModalGallery: mode_preview.isModalGallery,
      isModalVideos: mode_preview.isModalVideos,

      isPro: mode_preview.basic_info.isPro,
      isCookies: mode_preview.basic_info.isCookies,

      isMaintenance: mode_preview.isMaintenance,
      isCartOpen: mode_preview.isCartOpen,
      isCheckout: mode_preview.isCheckout,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getFullWebsiteLive,
    SetupCurrentCart
  }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(Live_Mode)
