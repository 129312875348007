import React, { useState } from 'react';
import Iframe from 'react-iframe'
import ReactPlayer from 'react-player'
import swal from 'sweetalert';

export const Image_Frame = ({image_url, settings, className}) => {
 
    var block_alt = settings.alt || '';
    var block_focus = settings.focus || {x: 0.5, y: 0.5};
    var position_obx = block_focus.x*100+'% '+block_focus.y*100+'%';

    return (
        <img className={className} 
             src={image_url} 
             alt={block_alt} 
             style={{ objectPosition: position_obx }}  
             data-focus-x={block_focus.x} 
             data-focus-y={block_focus.y}
        />
    )
}

export const Video_Frame = ({video_url, settings, className}) => {


    var [playing, setPlaying] = useState(false);
    var [volume, setVolume] = useState(0);

    if(video_url.includes('twitch')){
        var block_autoplay = settings.autoplay ? true : false;
        var block_mute = settings.mute ? true : false;

        return (
            <ReactPlayer
                width='100%'
                height='100%'
                url={video_url}
                playing={block_autoplay}
                muted={block_mute}
                volume={1}

            />
        )
    }
    else if(video_url.includes('vimeo')){
        var block_autoplay = settings.autoplay ? true : false;
        var block_loop = settings.loop ? true : false;
        var block_controls = settings.controls ? true : false;
        var block_mute = settings.mute ? true : false;

        const onReadyVimeo = () =>{
           setTimeout(
                function() {
                   setPlaying(true);
                }
                .bind(this),
                300
            );
        }

        const onPlayVimeo = () =>{
            setTimeout(
                 function() {
                    setVolume(1);
                 }
                 .bind(this),
                 300
             );
        }

       
        if(!block_mute && block_autoplay){

            return (
                <>
                    <ReactPlayer
                        width='100%'
                        height='100%'
                        url={video_url}
                        onReady={()=>onReadyVimeo()}
                        onPlay={()=>onPlayVimeo()}
                        playsinline={true}
                        pip={true}
                        playing={playing}
                        volume={volume}
                        config={{
                            vimeo: {
                                playerOptions: { 
                                    loop: block_loop
                                }
                            },
                            
                        }}
                    />

                    {volume ? (
                        <div className="floating-dropdown-flex" onClick={()=>setVolume(0)}>
                            <div className="floating-dropdown">
                                <div className="floating-dropdown-trigger" style={{width: '40px', height: '40px', background: '#333333'}}>
                                    <img src="/assets/img/mute.png" style={{width: '100%', height: '100%'}} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="floating-dropdown-flex" onClick={()=>setVolume(1)}>
                            <div className="floating-dropdown">
                                <div className="floating-dropdown-trigger" style={{width: '40px', height: '40px', background: '#333333'}}>
                                    <img src="/assets/img/unmute.png" style={{width: '100%', height: '100%'}}/>
                                </div>
                            </div>
                        </div>
                    )}
                    
                </>
            )
        }

        return (
            <ReactPlayer
                width='100%'
                height='100%'
                url={video_url}
                playsinline={true}
                config={{
                    vimeo: {
                        playerOptions: { 
                            autoplay: block_autoplay,
                            loop: block_loop,
                            muted: block_mute,
                            background: block_controls 
                        }
                    },
                    
                }}
            />
        )
    }
    else{
        var block_autoplay = settings.autoplay ? 1 : 0;
        var block_fullscreen = settings.fullscreen ? 1 : 0;
        var block_loop = settings.loop ? 1 : 0;
        var block_controls = settings.controls ? 2 : 0;
        var block_mute = settings.mute ? 1 : 0;
        var block_video = 'https://www.youtube.com/embed/'+video_url+'?autoplay='+block_autoplay+'&fs='+block_fullscreen+'&loop='+block_loop+'&controls='+block_controls+'&mute='+block_mute+'&modestbranding=1';
        return (
            <Iframe url={block_video} className={className} frameborder="no" allowFullScreen/>
        )
    }



}

export const Music_Frame = ({music_url, settings, className}) => {

    var block_autoplay = settings.autoplay ? 1 : 0;
    var block_mute = settings.mute ? 1 : 0;

    if(isNaN(music_url)){
        return (
            <Iframe url={music_url} className={className} frameborder="no" allow="autoplay"/>
        )
    }
    else{

        var block_music = 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/'+music_url+'&color=%23ff5500&auto_play='+block_autoplay+'&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=true'

        return (
            <Iframe url={block_music} className={className} frameborder="no" allow="autoplay"/>
        )

    }
 

}

export const Map_Frame = ({map_url, settings, className}) => {

    var block_texture = settings.texture || '';
    var block_map = 'https://maps.google.com/maps?q='+map_url+'&t='+block_texture+'&z=13&ie=UTF8&iwloc=&output=embed';

    return (
        <Iframe url={block_map} className={className} frameborder="0" marginheight="0" marginwidth="0"/>
    )

}



