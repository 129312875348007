import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {pickTextColorBasedOnBgColorAdvanced} from '../../../../../helpers/GeneralHelper';

import Block_Textbox from '../general/block_textbox';
import Block_Button from '../general/block_button';
import Page_Settings from '../general/page_settings';

import {Video_Frame, Image_Frame, Music_Frame, Map_Frame} from '../general/page_media';

class Layout_About extends React.Component {

    constructor(props) {
        super(props);   
    }

    componentDidMount(){
        this.props.HandleBodyClasses(this.props.currentpage);
    }

    componentDidUpdate(newProps){

        var newPage = newProps.currentpage.route;
        var oldPage = this.props.currentpage.route;

        if(newPage !== oldPage){
            console.log("MENU CHANGED URL --->");
            this.props.HandleBodyClasses(this.props.currentpage);
        }
    }

    render(){
        

            var {currentpage, currentblocks} = this.props;
            var {title, description, title_html, description_html, page_action_button, page_content_url, page_content_type, page_bj_color} = currentpage;
            
            //Page Settings
            var block_btn = page_action_button || {}; 
            var block_class = Page_Settings(currentpage);

            //New Edits
            var background_color = page_bj_color || '#ffffff';
            var text_color = pickTextColorBasedOnBgColorAdvanced(page_bj_color, '#FFFFFF', '#000000');
            var general_styles = {
                color: text_color,
                background: background_color
            }
    
            var isVideo = page_content_type === 'video';
            var isImage = page_content_type === 'image';
            var isMap = page_content_type === 'map';
            var isMusic = page_content_type === 'music';
    
            var page_settings = currentpage.settings || {};

        return(

            <>
        
                <div className={block_class} id="articleContent" style={general_styles}>

                        <div className={isVideo ? 'article-image video col-60' : 'article-image col-50'}>

                            {isImage && (
                                <Image_Frame image_url={page_content_url} settings={page_settings} className="mySlides" />
                            )}

                            {isVideo && (
                                <div id="about_video">
                                    <Video_Frame video_url={page_content_url} settings={page_settings} />
                                </div>
                            )}

                            {isMap && (
                                <div id="about_map">
                                    <Map_Frame map_url={page_content_url} settings={page_settings} />
                                </div>
                            )}

                            {isMusic && (
                                <div id="about_music">
                                    <Music_Frame music_url={page_content_url} settings={page_settings} />
                                </div>
                            )}

                        </div>
                        
                        <div className="article-content with-description col-50">
                            
                             <Block_Textbox type="page_title" content={title} content_html={title_html} />  
                             <Block_Textbox type="page_description" content={description} content_html={description_html} />  
                            
                             <Block_Button block_btn={block_btn} />

                        </div>

                </div>

                {currentblocks}
                
            </>
        )
    }
};



const mapStateToProps = ({ }) => {
    return {
        
    }
}
  
const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Layout_About)
