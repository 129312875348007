import React from 'react';
import {forceSSL} from '../../../../../helpers/GeneralHelper';

class Block_Background extends React.Component {

    constructor(props) {
        super(props);   
    }

    componentDidMount(){

     
       
    }

    render(){

        var background_filter = {
            content: " ",
            width: '100%',
            height: '100%',
            opacity: '0.3',
            position: 'absolute',
            left: '0',
            top: '0',
            background: '#000',
            zIndex: '2'
        }

        var {block_type, bg_image} = this.props;

        if(block_type === 'block_signup'){
            return (
                <>

                    <div style={background_filter}></div>
                   <img src={forceSSL(bg_image)} className="signup-bg-image" />

                </>
            )
        }
        else{

            return (
                <>

                    <div style={background_filter}></div>
                    <img className="design-block-background-image" src={forceSSL(bg_image)} />

                </>
            )
        }

    }
    
};



export default Block_Background;

