import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'

import NumberFormat from 'react-number-format';
import InfiniteScroll from 'react-infinite-scroller';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { pickTextColorBasedOnBgColorAdvanced } from '../../../../../helpers/GeneralHelper';

import { Image_Frame } from '../general/page_media';

import Block_Textbox from '../general/block_textbox';
import Page_Settings from '../general/page_settings';

  
class Layout_Store extends React.Component {
 
    constructor(props) {
        super(props);   

        this.state={
            selected_type: 'all',
            data_index: 0,
            data_items: [],
            data_loading: true,
            data_total: 0
        }

    }

    //Lifecycle
    componentDidMount(){
        var {currentpage} = this.props;
        this.props.HandleBodyClasses(currentpage);

        setTimeout(() => {
			this.onFetchProducts();
          }, 300);
    }

    componentDidUpdate(newProps){

        var newPage = newProps.currentpage.route;
        var oldPage = this.props.currentpage.route;

        if(newPage !== oldPage){
             this.props.HandleBodyClasses(this.props.currentpage);  
        }
    }

    //Functions
    onFetchProducts(){
        
        var {web_id} = this.props;
        var {selected_type, data_index, data_items} = this.state;
        

        var jwt = localStorage.getItem('jwt_token');
        var req_url = '/api/v1/builder/websites/products/categories/live';
        var req_form = {
           id: web_id,
           cat: selected_type,
           query: data_index
        }

        axios.post(req_url, req_form, {headers: {authorization: jwt}}).then(res => {

            console.log('FETCHED PRODUCTS ==== ', res.data);

            var new_index = res.data.next_page || '';
            var new_items = res.data.array || [];
            var total_count = res.data.total || 0;

            this.setState({
                data_index: new_index,
                data_items: [...data_items, ...new_items],
                data_loading: false,
                data_total: total_count
            });

        })
        .catch((error) => {
            // handle error
            console.log(error);
            this.setState({data_loading: false});
        });

    };
 
    onSortProducts(type){
        this.setState({
            selected_type: type,
            data_index: 0,
            data_items: [],
            data_loading: true,
            data_total: 0
        });
        setTimeout(() => {
          this.onFetchProducts(type);
        }, 300);
    }

    
    render(){


        var {selected_type, data_items, data_total, data_loading} = this.state;

        var { 
              
            title, 
            description, 
            title_html, 
            description_html,
            page_content_url,
            page_bj_color,
            isBanner

        } = this.props.currentpage;

        var {currentpage, currentblocks, web_symbol, web_categories, web_lang} = this.props;
    

        //Page Settings
        var block_class = Page_Settings(currentpage);
        var page_settings = currentpage.settings || {};

        //New Edits
        var background_color = page_bj_color || '#ffffff';
        var text_color = pickTextColorBasedOnBgColorAdvanced(page_bj_color, '#FFFFFF', '#000000');
        var general_styles = {
            color: text_color,
            background: background_color
        }


         //Page Products
         var products_items = data_items.map((d, index)=> {
            return ( 
                <Product_Card key={index} payload={d} 
                              web_symbol={web_symbol} 
                              web_lang={web_lang}
                              index={index} 
                              page_settings={page_settings}
                /> 
            )
        });    
    
        //Page Categories
        var store_categories_items = web_categories.map((item)=>{
            var title = item.title;
            return (
                <div className={selected_type === item.id ? 'active' : ''} onClick={()=>this.onSortProducts(item.id)} key={item.id}>
                    {title}
                </div>
            )
        });

        return(
 
            <>  
                <div className={block_class} id="storeProducts" style={general_styles} >
                    
                        {/* Store Top Section */}

                        <div className={"gallery-title-wrapper "+(!isBanner ? 'no-image' : '')}>
                            {page_content_url && ( 
                                <Image_Frame image_url={page_content_url} settings={page_settings} className="store-hero-image" />
                            )}
                            <div className="gallery-title">  
                                <Block_Textbox type="page_title" content={title} content_html={title_html} />  
                                <Block_Textbox type="page_description" content={description} content_html={description_html} />  
                            </div>
                        </div>
                            
                        {/* Products Categories */}

                        {store_categories_items.length != 0 && (
                            <div className="cat-filter-nav">
                                {store_categories_items.length != 0 ? <div className={selected_type === 'all' ? 'active' : ''} onClick={()=>this.onSortProducts('all')}>All</div> : null}
                                {store_categories_items}                            
                            </div>
                        )}
                            
                        {data_loading ? (
                                /* Loading Products */
                
                                <div className="product-grid">
                                    <div className="generic-loading-spin">
                                        <i className="fa fa-spinner fa-spin" /> 
                                    </div>
                                </div>
 
                        ) : (
                                /* Products Container */
                               
                               <>
                                    {products_items.length == 0 ? (
                                        <Product_Placeholder />
                                    ): (

                                        <InfiniteScroll
                                            className="product-grid"
                                            pageStart={0}
                                            loadMore={this.onFetchProducts}
                                            hasMore={(products_items.length >= data_total) ? false : true}
                                            loader={
                                                <div className="generic-loading-spin" key={1000}>
                                                    <i className="fa fa-spinner fa-spin" /> 
                                                </div>
                                            }
                                        >
                                            {products_items}
                                        </InfiniteScroll>
                                       
                                    )}

                                </>

                        )}

                    </div>

                {currentblocks}
              
            </>

        )
    }
};


const Product_Card = ({payload, web_symbol, web_lang, page_settings}) =>{
 
    var {id,title,thumbnail,options} = payload;
        
    var price_tag = Product_Prices_Options(options);
    var price_offer = price_tag.sale ? ((price_tag.price - price_tag.sale) / price_tag.price)*100 : '';
    
    //Pricing
    var prefix = web_symbol;

    var block_title_size = page_settings.title_size || 23;
    var block_description_size = page_settings.description_size || 18;
    var block_subtitle_size = page_settings.subtitle_size || 18;
    var block_extra_size = page_settings.extra_size || 12;

    var block_title_color = page_settings.title_color || '#000000';
    var block_description_color = page_settings.description_color || '#000000';
    var block_subtitle_color = page_settings.subtitle_color || '#ff0000';
    var block_extra_color = page_settings.extra_color || '#38964c';

    //Format
    var block_format = page_settings.format || 'simple';

    if(options.length == 1){
        block_format = 'simple';
    }

    return (

        <Link className="product-item" to={'/store/'+id}>
            <div className="cart-item-trigger"></div>

            <div className="product-image">
                
                {price_offer && (
                    <div className="special-offer-label" style={{fontSize: block_extra_size+'px', background: block_extra_color, color: '#ffffff'}}>
                        {parseInt(price_offer)}% OFF
                    </div>
                )}

                <img src={thumbnail || ''} alt={title} />
            </div>

            <div className="product-item-info">
                <h4 style={{fontSize: block_title_size+'px', color: block_title_color, fontWeight: 'bold'}}>{title}</h4>

                {price_offer ? (
                  
                    <div className="grid-price" style={{fontSize: block_description_size+'px', color: block_description_color}}>
                        <Product_Price prefix={prefix} value={price_tag.sale} />
                        <span className="old-price" style={{fontSize: block_subtitle_size+'px', color: block_subtitle_color}}>
                            <Product_Price prefix={prefix}  value={price_tag.price} />
                        </span>
                    </div>
                        
                ) : (
                   <>
                        {block_format === 'simple' && (
                            <div className="grid-price" style={{fontSize: block_description_size+'px', color: block_description_color}}>
                                <Product_Price prefix={prefix} value={price_tag.price} />
                            </div>
                        )}

                        {block_format === 'range' && (
                            <div className="grid-price" style={{fontSize: block_description_size+'px', color: block_description_color}}>
                                {price_tag.max ? (
                                    <>
                                        <Product_Price prefix={prefix} value={price_tag.price} />
                                        {'  -  '}
                                        <Product_Price prefix={prefix} value={price_tag.max} />
                                    </>
                                ) : (
                                    <Product_Price prefix={prefix} value={price_tag.price} />
                                )}
                               
                            </div>
                        )}

                        {block_format === 'minimum' && (
                            <div className="grid-price" style={{fontSize: block_description_size+'px', color: block_description_color}}>
                                <span style={{marginRight: '5px', fontWeight: '300'}}>{getTranslatedPage('from', web_lang)}</span>
                                <Product_Price prefix={prefix} value={price_tag.price} />
                            </div>
                        )}
                    </>
                )}
               
            </div>
        </Link>	
    )
}

const Product_Price = ({prefix, value}) => {

    return (
        <NumberFormat displayType="text" 
                      thousandSeparator={true} 
                      decimalScale={2} 
                      fixedDecimalScale={2}
                      prefix={prefix}  
                      value={value} />
    )
}

const Product_Prices_Options = (options) =>{

    var price_tag = {
        price: 0,
        sale: 0
    }


    var sorted_options = (options && options.length>0) ? options.sort((a, b) => parseFloat(a.price) - parseFloat(b.price)) : [];

    if(sorted_options.length >0){
        var list_price = sorted_options[0].price;
        var sale_price = sorted_options[0].sale;
        
        price_tag = {...price_tag, price: parseFloat(list_price), sale: parseFloat(sale_price)};

        //Get Max
        var max_price = sorted_options[sorted_options.length - 1].price;

        if(max_price != list_price){
            price_tag = {...price_tag, max: parseFloat(max_price)}
        }
    }

    return price_tag;
}

const Product_Placeholder = () =>{

    return (
        <div className="empty-store-product-picker">
            <img src="/assets/img/placeholders/empty-store.png" />
            <h4 style={{marginTop: '20px'}}>Store is empty</h4>
        </div>
    )
}

const getTranslatedPage = (type, lang) => {
    
    var tr = '';

    if(type === 'from'){
        switch (lang) {
            case 'fr':
                tr = 'à partir de'
                break;
            case 'it':
                tr = 'da'
                break;
            case 'es':
                tr = 'desde'
                break;
            default:
                tr = 'from'
                break;
        }
    }

    return tr

}


const mapStateToProps = ({ mode_preview }) => {
  
    return {
      web_lang : mode_preview.basic_info.language,
      web_id : mode_preview.basic_info.id,
      web_symbol: mode_preview.store.symbol,
      web_categories: mode_preview.store.categories || []
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
      
    }, dispatch)
  }

export default connect(mapStateToProps, mapDispatchToProps)(Layout_Store)

